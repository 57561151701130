export const setFilterShop = (items, filter) => {
  if (items.length) {
    switch (filter) {
      case 'sale':
        return items
          .filter(el => el.old_price)
          .sort(() => Math.random() > 0.5 ? 1 : -1)
      case 'Аксессуары':
        return items
          .filter(el => el.category.title === filter)
          .sort(() => Math.random() > 0.5 ? 1 : -1)
      case 'hats':
        return items
          .filter(el => el.group === filter)
          .sort(() => Math.random() > 0.5 ? 1 : -1)
      case 'male':
        return items
          .filter(el => el.gender?.trim() === 'male' || el.gender?.trim() === 'unisex')
          .sort(() => Math.random() > 0.5 ? 1 : -1)
      case 'female':
        return items
          .filter(el => el.gender?.trim() === 'female' || el.gender?.trim() === 'unisex')
          .sort(() => Math.random() > 0.5 ? 1 : -1)
      default:
        return items
          .sort(() => Math.random() > 0.5 ? 1 : -1)
    }
  } else return []
}

export const setFilterSubgroups = (items, filter) => {
  if (items.length && filter) {
    if (filter === `all`) return items
    else return items.filter((el) => el.subgroup === filter)
  }
  else return []
}

export const setCollectionShop = (items, collection) => {

  if (items.length) {
    switch (collection) {
      case 'sale':
        return {
          title: collection,
          goods: items.filter(el => el.old_price)
        }
      case 'Аксессуары':
        return {
          title: collection,
          goods: items.filter(el => el?.category?.title === collection)
        }
      case 'male':
        return {
          title: collection,
          goods: items.filter(el => el.gender === 'male')
        }
      case 'female':
        return {
          title: collection,
          goods: items.filter(el => el.gender === 'female')
        }
      case 'shop':
        return {
          title: collection,
          goods: items.sort(() => Math.random() > 0.5 ? 1 : -1).slice(0, 16)
        }
      default:
        return {
          title: 'All',
          goods: items.filter(el => el.gender === 'unisex')
        }
    }
  } else return []
}
