import styles from '../Personal.module.scss';
import stylesInside from './Activity.module.scss';
import { ReactComponent as ReactActivityIcon } from 'images/profile/activity-icon.svg';
import { getDate } from 'utils/date';
import { randomCity, randomCountry } from 'utils/activity';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { Operation as ProfileOperation } from 'reducer/profile/profile';
import { getActivity } from 'reducer/profile/selectors';
import { useDispatch, useSelector } from 'react-redux';

const Activity = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const activityArray = useSelector(state => getActivity(state)) ?? [];

  useEffect(() => {
    // изначальная загрузка активности - геолокации и браузеров, из которых  юзер заходил
    dispatch(ProfileOperation.loadActivity());
  }, [dispatch]);

  return (
    <>
      <div className={stylesInside.titleWrap}>
        <h2 className={styles.title}>{t('lk.activityPage.title')}</h2>
        <span className={styles.subtitle}>{t('lk.activityPage.subtitile')}</span>
      </div>

      <ul className={stylesInside.sessionList}>
        {activityArray.length !== 0 ?
          activityArray.map((el, i) => (
            <li key={i + el.method} className={stylesInside.session}>
              <ReactActivityIcon />
              <div className={stylesInside.wrap}>
                <b className={stylesInside.city}>{`${el.city || randomCity()}, ${el.country || randomCountry()}`}</b>
                <ul className={stylesInside.pointList}>
                  <li
                    className={stylesInside.point}
                    data-state={i === 0 ? 'active' : ''}
                  >
                    {i === 0 ? `Сессия активна` : `${getDate(el.temp).date} ${getDate(el.temp).month} ${getDate(el.temp).year}`}
                  </li>
                  {el.os && <li className={stylesInside.point}>{el.os}</li>}
                  <li className={stylesInside.point}>{t('lk.activityPage.from')} {el.browser}</li>
                </ul>
              </div>
            </li>
          )) :
          <p>{t('lk.activityPage.noData')}.</p>
        }
      </ul>
    </>
  );
};

export default Activity;
