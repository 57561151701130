import { returnAuthConfig } from "utils/auth";

const initialState = {
  orders: [],
  shops: [],
  isLoadOrders: false,
};

export const ActionType = {
  LOAD_ORDERS: `LOAD_ORDERS`,
  SET_SHOPS: `SET_SHOPS`,
};

export const ActionCreator = {
  loadOrders: (orders) => ({
    type: ActionType.LOAD_ORDERS,
    payload: orders,
  }),

  setShop: (shops) => ({
    type: ActionType.SET_SHOPS,
    payload: shops,
  }),
};

export const Operation = {
  loadOrders: () => async (dispatch, getState, api) => {
    const config = returnAuthConfig();
    const response = await api.get(`/api/user/shop/orders`, config);

    if (response.status === 200) {
      dispatch(ActionCreator.loadOrders(response.data.values));
    }
  },

  setShops: (order_id) => async (dispatch, _, api) => {
    const config = returnAuthConfig();
    const response = await api.get(
      `/api/user/shop/soldgood/${order_id}`,
      config
    );

    if (response.status === 200) {
      dispatch(ActionCreator.setShop(response.data.values));
    } /*  else if (response.status >= 400) console.log(response.data.message) */
  },

  /* returnOrder: (order) => async (dispatch, _, api) => {
    const config = returnAuthConfig();
    const response = await api.post(``, order, config);
    
    if (response.status === 200) {

    } else if (response.status >= 400) 
  } */
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.LOAD_ORDERS:
      return { ...state, orders: action.payload, isLoadOrders: true };
    case ActionType.SET_SHOPS:
      return { ...state, shops: action.payload };
    default:
      return state;
  }
};
