import { forwardRef, useCallback, useEffect, useState } from "react";
import Message from "components/Auth/Registration/Message/Message";
import defaultStyles from "./Input.module.scss";
import { stylesMerge, getClasses } from "utils/styles";
import { MESSAGES } from "const/messages";
import { ReactComponent as DefaultLogo } from "images/svg/defaultLogo.svg";

const Input = forwardRef(
  (
    {
      disabled = false,
      error = ``,
      handleFieldChange = () => { },
      handleFocus = () => { },
      Logo = DefaultLogo,
      label = ``,
      name = ``,
      id = Date.now(),
      defaultValue = "",
      value = undefined,
      valid = ``,
      outsideStyle = ``,
      type = `text`,
      firstLetterUpperCase,
      ...rest
    },
    ref
  ) => {
    const [styles, setStyles] = useState(
      stylesMerge(defaultStyles, outsideStyle)
    );
    const [classes, setClasses] = useState({});

    const generateValidClass = useCallback(
      (valid) => {
        switch (valid) {
          case MESSAGES.EMAIL_NOT_CONFIRM:
            return styles.inputGroupNotConfirmValid;
          case MESSAGES.EMAIL_CONFIRM:
            return styles.inputGroupConfirmValid;
          default:
            return ``;
        }
      },
      [styles.inputGroupConfirmValid, styles.inputGroupNotConfirmValid]
    );

    useEffect(() => {
      setStyles(stylesMerge(defaultStyles, outsideStyle));
    }, [outsideStyle]);

    useEffect(() => {
      setClasses({
        defaultClass: styles.inputGroup,
        errorClass: error ? styles.inputGroupError : ``,
        validClass: valid ? generateValidClass(valid) : ``,
        disabledClass: disabled ? styles.inputGroupDisabled : ``,
      });
    }, [
      disabled,
      error,
      generateValidClass,
      styles.inputGroup,
      styles.inputGroupDisabled,
      styles.inputGroupError,
      valid,
    ]);

    return (
      <div className={getClasses(classes).join(` `)}>
        {label !== `` && (
          <label className={styles.label} htmlFor={id}>
            {label}
          </label>
        )}
        <div className={styles.inputWrap}>
          <Logo className={styles.icon} />
          <input
            {...rest}
            className={`${styles.input} ${firstLetterUpperCase ? styles.firstLetterUpperCase : ""
              }`}
            autoComplete="off"
            type={type}
            id={id}
            name={name}
            ref={ref}
            onChange={handleFieldChange}
            onFocus={handleFocus}
            {...(value !== undefined ? { value } : { defaultValue })}
          />
        </div>
        {error.length ? <Message styles={styles} message={error} /> : ``}
      </div>
    );
  }
);

export default Input;
