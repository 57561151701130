import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useValidationPhone } from "customHooks/useValidationPhone";
import { resetSelect } from "customHooks/resetSelect";
import { getCodeError, getTelError } from "reducer/validation/selectors";
import { ReactComponent as ReactLogoTel } from "images/svg/form-tel.svg";
import { getSendingForm, getStatusBlockSms } from "reducer/user/selectors";
import InputPhone from "components/Forms/Input/InputPhone";
import Input from "components/Forms/Input/Input";
import { ActionCreator as UserActionCreator } from "reducer/user/user";
import { ActionCreator } from "reducer/validation/validation";
import { useValidationCode } from "customHooks/useValidationCode";
import { Operation } from "reducer/user/user";

const AuthSms = ({ styles }) => {
  const [phone, isValidTel, handlePhoneChange, handleFocus] =
    useValidationPhone(``);
  const [code, isValidCode, handleCodeChange] = useValidationCode(``);
  const dispatch = useDispatch();
  const phoneRef = useRef();
  const codeRef = useRef();
  const phoneError = useSelector((state) => getTelError(state));
  const isBlockSms = useSelector((state) => getStatusBlockSms(state));
  const isSendingForm = useSelector((state) => getSendingForm(state));
  const codeError = useSelector((state) => getCodeError(state));

  useEffect(() => {
    return () => {
      dispatch(UserActionCreator.setBlockSendingSms(false));
      dispatch(ActionCreator.setTelError(``));
    };
  }, [dispatch]);

  // ф-ция для получения кода при авторизации (`/api/user/code`)
  const handleSubmitForCode = (evt) => {
    evt.preventDefault();
    if (isValidTel) {
      dispatch(UserActionCreator.setSendingForm(true));
      dispatch(
        Operation.getRegistrationSmsCode({
          phone: phone.slice(1),
          registration: false,
        })
      );
    } else {
      resetSelect(phoneRef.current);
    }
  };

  // ф-ция для отправки кода и телефона по роуту (`/api/auth/phone`) - UserActionCreator.phoneAuth(data)
  const handleSubmit = (evt) => {
    evt.preventDefault();

    if (isValidCode.current) {
      const data = {

        phone_code: code.current,
        phone: phone.slice(1),
      };
      dispatch(UserActionCreator.setSendingForm(true));
      dispatch(Operation.phoneAuth(data));
    } else {
      resetSelect(codeRef.current);
    }
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <fieldset className={styles.fieldset}>
        <legend className="visually-hidden">Форма входа</legend>

        <InputPhone
          error={phoneError}
          Logo={ReactLogoTel}
          international={true}
          country="RU"
          withCountryCallingCode={true}
          id="auth-sms"
          label="Введите ваш телефон:"
          type="tel"
          name="phone"
          ref={phoneRef}
          value={phone}
          onFocus={handleFocus}
          onChange={handlePhoneChange}
        />

        {isBlockSms && (
          <Input
            error={codeError}
            handleFieldChange={handleCodeChange}
            handleFocus={() => { }}
            Logo={ReactLogoTel}
            label={`Введите код из СМС`}
            name={`auth-code`}
            id={`auth-code`}
            defaultValue={``}
            ref={codeRef}
          />
        )}
      </fieldset>
      <button
        onClick={isBlockSms ? handleSubmit : handleSubmitForCode}
        className={styles.btn}
        disabled={isSendingForm}
        type="submit"
      >
        <span>{isBlockSms ? `Войти` : `Получить код`}</span>
      </button>
    </form>
  );
};

export default AuthSms;
