import { ReactComponent as vkLogo } from 'images/svg/VK-Logo.svg';
import { ReactComponent as ytLogo } from 'images/svg/youtube-soc.svg';
import vkLogoSrc from 'images/svg/VK-Logo.svg';
import ytLogoSrc from 'images/svg/youtube-soc.svg';
// reg Social
import googleRegLogo from 'images/svg/google-soc.svg';
// import fbRegLogo from 'images/svg/fb-reg-soc.svg';
import vkRegLogo from 'images/svg/VK-Logo-White.svg';
// profileAuth
import { ReactComponent as VkSocialIcon } from 'images/profile/social/vk-setting-icon.svg';
import { ReactComponent as GoogleSocialIcon } from 'images/profile/social/google-setting-icon.svg';
// import { ReactComponent as FbSocialIcon } from 'images/profile/social/fb-setting-icon.svg';

// mocks data from Ad
import cardPsb from 'images/card@1x-min.jpg';
import cardBack from 'images/psb-back@1x-min.jpg';
import runLogo from 'images/svg/race-hash.svg';
import runBack from 'images/advert/ad1@2x.png';
import gpbBanner from 'images/advert/gpb2x.png';
import gpbMobile from 'images/advert/gpbMobile1x.png';
import gpbWebpMobile from 'images/advert/gpbMobile1x.png';
import rChamp from 'images/advert/rchamp.png';
// import rChampWebp from 'images/advert/rchamp.webp';
import rChampMob from 'images/advert/rchamp-mob.png';
import rChampMobWebp from 'images/advert/rchamp-mob.webp';
import letapeBanner from 'images/advert/letape.jpg';
import letapeBannerMob from 'images/advert/letape2.jpg';
import letapeBannerMobWebp from 'images/advert/letape2.webp';
// import letapeBannerWebp from 'images/advert/letape.webp';
import gazpromBanner from 'images/advert/gazprom.png';
import gazpromBannerMob from 'images/advert/gazpromMobile.png';

import worldclassBanner from 'images/advert/worldclass_big.jpg';
import worldclassBannerMob from 'images/advert/worldclass_sm.jpg';
import worldclassBannerMobWebp from 'images/advert/worldclass_sm.webp';

// Истории Лиги Героев
import LogoStories1 from 'images/story/1_gpb.jpg';
// import LogoStories2 from 'images/story/2_letape.jpg';
// import LogoStories3 from 'images/story/3_gonka.jpg';
// import LogoStories8 from 'images/story/8_letape.jpg';
// import ShopStoriesOne from 'images/story/shop_1.jpg';
// import ShopStoriesTwo from 'images/story/shop_2.jpg';

// Проекты Лиги Героев
import projLogo from 'images/svg/proj-logo-letape.svg';
import projLogo2 from 'images/svg/proj-logo-run.svg';
// import projLogo3 from 'images/svg/proj-logo-racehero.svg';
import projLogo4 from 'images/svg/proj-logo-arena.svg';
import projLogo6 from 'images/svg/proj-logo-racehero.svg';
import projLogo10 from 'images/svg/proj-logo-herogames.png';
// import mockProjectBack from 'images/projects/proj1@1x-min.jpg';
// import mockProjectBack2 from 'images/projects/proj2@1x-min.jpg';
// import mockProjectBack3 from 'images/projects/proj3@1x-min.jpg';
// import mockProjectBack4 from 'images/projects/proj4@1x-min.jpg';

// Heroleague training / camp
import mockBackTrain from 'images/team1@1x-min.jpg';
import mockBackTrain2 from 'images/phototravel.jpg';
import LogoTrain from 'images/svg/hero-train-logo.svg';
import LigaP from 'images/svg/logotravel.svg';

// import LogoTape from 'images/loop.png'; // Tape

// parthners background
import ggMobile from 'images/partners/ggMobile2x.jpg';
import ggWebpMobile from 'images/partners/ggMobile2x.jpg';
import urbanMobile from 'images/partners/urbanMobile2x.png';
import urbanWebpMobile from 'images/partners/urbanMobile2x.webp';
import ggPBack from 'images/partners/gg-p2x.jpg';
import urbanPBack from 'images/partners/urban-p2x.png';

import SlideImage from 'images/promocode1@1x-min.jpg'; // Special
import PartnerLogo from 'images/svg/psb-logo.svg'; // Partners

import staff1 from 'images/staff/staff1.png';
import staff2 from 'images/staff/staff2.png';

import service1 from 'images/service/service1.svg';
import service2 from 'images/service/service2.svg';
import service3 from 'images/service/service3.svg';
import service4 from 'images/service/service4.svg';
import service5 from 'images/service/service5.svg';
import service6 from 'images/service/service6.svg';
import service7 from 'images/service/service7.svg';
import service8 from 'images/service/service8.svg';
import service9 from 'images/service/service9.svg';

import gosPartnersLogo1 from 'images/partnersLogo/gosPartnersLogo1.png';
import gosPartnersLogo2 from 'images/partnersLogo/gosPartnersLogo2.png';
import gosPartnersLogo3 from 'images/partnersLogo/gosPartnersLogo3.png';
import gosPartnersLogo4 from 'images/partnersLogo/gosPartnersLogo4.png';
import gosPartnersLogo5 from 'images/partnersLogo/gosPartnersLogo5.png';

// import option1 from 'images/svg/Options/option1.svg';
// import option2 from 'images/svg/Options/option2.svg';
// import option3 from 'images/svg/Options/option3.svg';
// import option4 from 'images/svg/Options/option4.svg';
// import option5 from 'images/svg/Options/option5.svg';
// import option6 from 'images/svg/Options/option6.svg';
// import option7 from 'images/svg/Options/option7.svg';
// import option8 from 'images/svg/Options/option8.svg';
// import option9 from 'images/svg/Options/option9.svg';
// import option10 from 'images/svg/Options/option10.svg';

import emotionMember2 from 'images/emotionsMembers/emotion-member2@1x-min.jpg';
import emotionMember3 from 'images/emotionsMembers/emotion-member3@1x-min.jpg';
import emotionMember4 from 'images/emotionsMembers/emotion-member4@1x-min.jpg';
import emotionMember5 from 'images/emotionsMembers/emotion-member5@1x-min.jpg';
import coverCardTicket from 'images/event/eventsCard/card-cover6@1x-min.jpg';

import ingradLogo from 'images/partnersLogo/ingrad.svg';
import pochtaLogo from 'images/partnersLogo/pochta.svg';
import rzdLogo from 'images/partnersLogo/rzd.svg';
import senegskayaLogo from 'images/partnersLogo/logo-senegskaya.png';
import nrjLogo from 'images/partnersLogo/nrj.svg';
import severstalLogo from 'images/partnersLogo/severstal.svg';
import uralhimLogo from 'images/partnersLogo/uralhim.svg';
import marvelLogo from 'images/partnersLogo/marvel.svg';
import accesstyleLogo from 'images/partnersLogo/accesstyle.svg';
import abdLogo from 'images/partnersLogo/abd.svg';
import ammoniyLogo from 'images/partnersLogo/ammoniy.svg';
// import magni2019 from 'images/partnersLogo/magni2019.svg';
import gaspromLogo from 'images/partnersLogo/gazprom_partner.png';
import hhLogo from 'images/partnersLogo/hh.svg';
import maerLogo from 'images/partnersLogo/maerWhite.svg';
import worldClass from 'images/partnersLogo/world_class_cmyk.svg';
import gazprombankLogo from 'images/partnersLogo/gazprombank.svg';

// БанерЫ! (реклама Ad)
export const adArray = [
  {
    id: 0,
    hashtag: `#Нативная реклама`,
    title: `«Твой кэшбек»`,
    text: `Центр столицы, тысячи зрителей, участники со всего мира. Каждый участвует под пристальным вниманием инструкторов с невероятным количеством поддержки болельщиков.`,
    link: ``,
    textLink: `Узнать подробнее`,
    backgroundColor: `#7469A3`,
    background: cardBack,
    image: cardPsb,
    btnExist: true,
  },
  {
    id: 1,
    hashtag: `#ЗабегРФ`,
    title: `Забег 2021 ждет тебя!`,
    text: `Крупнейший полумарафон в мире, объединяющий 85 регионов России`,
    link: `https://xn--80acghh.xn--p1ai/`,
    textLink: `Подробнее`,
    backgroundColor: `#7469A3`,
    background: runBack,
    image: runLogo,
    btnExist: true,
  },
  {
    id: 2,
    background: gpbBanner,
    backgroundMobile: gpbMobile,
    bgMobileWebp: gpbWebpMobile,
    link: `https://ad.adriver.ru/cgi-bin/click.cgi?sid=1&bt=21&ad=743750&pid=3475400&bid=8295070&bn=8295070&exss=&erir=%5Berir_token%5D&rnd=1917144986`,
  },
  // {
  //   id: 3,
  //   background: psbBanner,
  //   backgroundMobile: psbMobile,
  //   bgMobileWebp: psbWebpMobile,
  //   link: `https://www.psbank.ru/Personal/DebetCards/YourCashback`,
  // },
  {
    id: 4,
    background: rChamp,
    backgroundMobile: rChampMob,
    bgMobileWebp: rChampMobWebp,
    link: `https://ocrcup.world/`,
  },
  {
    id: 5,
    background: letapeBanner,
    backgroundMobile: letapeBannerMob,
    bgMobileWebp: letapeBannerMobWebp,
    link: `https://www.eurosport.ru/watch/cycling/?utm_source=website&utm_medium=banner1190x230&utm_campaign=letape`,
  },
  {
    id: 6,
    background: gazpromBanner,
    backgroundMobile: gazpromBannerMob,
    bgMobileWebp: gazpromBannerMob,
    link: ``,
  },
  {
    id: 7,
    background: worldclassBanner,
    backgroundMobile: worldclassBannerMob,
    bgMobileWebp: worldclassBannerMobWebp,
    link: `https://sport.worldclass.ru/`,
  },
];

// Heroleague training / camp
export const teamBuildingArray = [
  {
    id: 0,
    background: mockBackTrain,
    title: `Heroleague trainings`,
    text: `Тебя ждет лучшая система тренировок! Делаешь первые шаги или ищешь чего-то большего? Разнообразие направлений, профессиональные тренеры, в зале или на открытом воздухе — выбирай свою тренировку. Первое занятие бесплатно.`,
    link: `https://vk.com/heroleaguetraining`,
    linkText: `Узнать подробнее`,
    logo: LogoTrain,
  },
  {
    id: 1,
    background: mockBackTrain2,
    title: `Лига Путешествий`,
    text: `Открой мир в движении! Мы создаем путешествия в 2-х форматах: <br />
    Туры для души - путешествия в самые интересные и запоминающиеся уголки нашей планеты, где вся программа продумана до мелочей. <br />
    Тренировочные туры - это прекрасная возможность потренироваться, получить множество ценных советов от наших тренеров, а также исследовать этот удивительный мир в компании единомышленников.`,
    link: `https://xn--80aebjaofik7byajdj1f.xn--p1ai`,
    linkText: `Узнать подробнее`,
    logo: LigaP,
  },
];

// Спец предложения (Special)
export const specialSlidesArray = [
  {
    id: 0,
    title: `Твой промокод со скидкой 20%`,
    text: `Наши партнеры из GANZALIS финтнес клуб, готовы поделитьсяс Вами промокодом`,
    link: ``,
    linkText: `Подробнее`,
    image: SlideImage,
  },
  {
    id: 1,
    title: `Твой промокод со скидкой 20%`,
    text: `Наши партнеры из GANZALIS финтнес клуб, готовы поделитьсяс Вами промокодом`,
    link: ``,
    linkText: `Подробнее`,
    image: SlideImage,
  },
  {
    id: 2,
    title: `Твой промокод со скидкой 20%`,
    text: `Наши партнеры из GANZALIS финтнес клуб, готовы поделитьсяс Вами промокодом`,
    link: ``,
    linkText: `Подробнее`,
    image: SlideImage,
  },
];

// Партнеры (Partners)
export const partnersArray = [
  // {
  //   id: 0,
  //   title: `Забег.рф`,
  //   background: runPBack,
  //   backgroundMobile: runPBackMobile,
  //   bgWebpMobile: runWebpMobile,
  //   partner: {
  //     title: `Генеральный партнер`,
  //     text: `Сегодня, являясь опорным банком оборонно-промышленного комплекса России, мы осознаем масштаб возложенной на нас миссии и понимаем, что для решения поставленных задач, мы должны быть сильными как никогда.`,
  //     linkText: `Перейти на сайт партнера`,
  //     link: `https://www.psbank.ru/`,
  //     image: PartnerLogo,
  //     name: `psbank.ru`,
  //   },
  // },
  {
    id: 1,
    title: `Гонка Героев`,
    background: ggPBack,
    backgroundMobile: ggMobile,
    bgWebpMobile: ggWebpMobile,
    partner: {
      title: `Генеральный партнер 2`,
      text: `Сегодня, являясь опорным банком оборонно-промышленного комплекса России, мы осознаем масштаб возложенной на нас миссии и понимаем, что для решения поставленных задач, мы должны быть сильными как никогда.`,
      linkText: `Перейти на сайт партнера 2`,
      link: `https://ad.adriver.ru/cgi-bin/click.cgi?sid=1&bt=21&ad=743750&pid=3475401&bid=8295071&bn=8295071&exss=&erir=%5Berir_token%5D&rnd=689113199`,
      image: PartnerLogo,
      name: `gazprombank.ru`,
    },
  },
  {
    id: 2,
    title: `Гонка Героев Urban`,
    background: urbanPBack,
    backgroundMobile: urbanMobile,
    bgWebpMobile: urbanWebpMobile,
    partner: {
      title: `Генеральный партнер 3`,
      text: `Сегодня, являясь опорным банком оборонно-промышленного комплекса России, мы осознаем масштаб возложенной на нас миссии и понимаем, что для решения поставленных задач, мы должны быть сильными как никогда.`,
      linkText: `Перейти на сайт партнера 3`,
      link: `https://www.ingrad.ru/?utm_source=heroleagueurban&utm_medium=cpm&utm_campaign=ingrad_heroleague_media_all_object_thematic&utm_content=banner`,
      image: PartnerLogo,
      name: `ingrad.ru`,
    },
  },
];

// Соцсети
export const socialNets = [
  // {
  //   id: 0,
  //   logo: instaLogo,
  //   logoSrc: instaLogoSrc,
  //   altText: `instagram`,
  //   href: `https://www.instagram.com/heroleague/`,
  // },
  // {
  //   id: 1,
  //   logo: fbLogo,
  //   logoSrc: fbLogoSrc,
  //   altText: `facebook`,
  //   href: `https://www.facebook.com/heroleaguerussia`,
  // },
  {
    id: 2,
    logo: vkLogo,
    logoSrc: vkLogoSrc,
    altText: `vkontakte`,
    href: `https://vk.com/heroleague`,
  },
  {
    id: 3,
    logo: ytLogo,
    logoSrc: ytLogoSrc,
    altText: `youtube`,
    href: `https://www.youtube.com/channel/UCN8vRARh9HeGBJryD41kCwA`,
  },
];

export const socialNetsReg = [
  {
    id: 0,
    logo: vkRegLogo,
    logoProfile: VkSocialIcon,
    title: `Вконтакте`,
    link: `http://api.vk.com/oauth/authorize?client_id=7607679&redirect_uri=${window.location.origin}/user/auth/vk&v=5.60&response_type=code&scope=email`,
    linkPersonal: `http://api.vk.com/oauth/authorize?client_id=7607679&redirect_uri=${window.location.origin}/user/add/auth/vk&v=5.60&response_type=code&scope=email`,
    name: `vk`,
  },
  /* {
    id: 1,
    logo: fbRegLogo,
    logoProfile: FbSocialIcon,
    title: `Facebook`,
    link: `https://www.facebook.com/v10.0/dialog/oauth?client_id=952633598901518&redirect_uri=${window.location.origin}/user/auth/facebook&v=5.60&response_type=code&scope=email`,
    linkPersonal: `https://www.facebook.com/v10.0/dialog/oauth?client_id=952633598901518&redirect_uri=${window.location.origin}/user/add/auth/facebook&v=5.60&response_type=code&scope=email`,
    name: `facebook`,
  }, */
  {
    id: 2,
    logo: googleRegLogo,
    logoProfile: GoogleSocialIcon,
    title: `Google`,
    link: `http://accounts.google.com/o/oauth2/auth/authorize?client_id=756880280809-0c954bhe08kvre2am1jr2un0nu22onc4.apps.googleusercontent.com&redirect_uri=${window.location.origin}/user/auth/google&v=5.60&response_type=code&scope=email`,
    linkPersonal: `http://accounts.google.com/o/oauth2/auth/authorize?client_id=756880280809-0c954bhe08kvre2am1jr2un0nu22onc4.apps.googleusercontent.com&redirect_uri=${window.location.origin}/user/add/auth/google&v=5.60&response_type=code&scope=email`,
    name: `google`,
  },
];

// Проекты на странице "О нас"
export const aboutProjectsArray = [
  { id: 0, image: projLogo6, widthImage: 111 },
  { id: 1, image: projLogo4, widthImage: 77 },
  { id: 2, image: projLogo10, widthImage: 176 },
  { id: 3, image: projLogo, widthImage: 105 },
  { id: 4, image: projLogo2, widthImage: 121 },
];

// Сотрудники на странице "О нас"
export const staffArray = [
  {
    id: 0,
    image: staff1,
    name: 'about.staff.item1.name',
    position: 'about.staff.item1.position',
  },
  {
    id: 1,
    image: staff2,
    name: 'about.staff.item2.name',
    position: 'about.staff.item2.position',
  },
];

// Проекты на странице "Партнеры"
export const partnersProjectsArray = [
  {
    id: 0,
    image: projLogo2,
    widthImage: 107,
    text: 'partners.projects.item1',
    link: 'https://xn--80acghh.xn--p1ai/',
  },
  {
    id: 1,
    image: projLogo6,
    widthImage: 98,
    text: 'partners.projects.item2',
    link: 'https://race.heroleague.ru/',
  },
  /* {
    id: 2,
    image: projLogo4,
    widthImage: 103,
    text: 'partners.projects.item3',
    link: 'https://arena.heroleague.ru/',
  }, */
  {
    id: 3,
    image: projLogo10,
    widthImage: 137,
    text: 'partners.projects.item4',
    link: 'https://games.heroleague.ru/',
  },
];

// Сервис на странице "Партнеры"
export const serviceArray = [
  {
    id: 0,
    image: service1,
    widthImage: 38,
    title: '',
    text: 'partners.service.itemText1',
  },
  {
    id: 1,
    image: service2,
    widthImage: 32,
    title: 'partners.service.itemTitle2',
    text: 'partners.service.itemText2',
  },
  {
    id: 2,
    image: service2,
    widthImage: 32,
    title: 'partners.service.itemTitle3',
    text: 'partners.service.itemText3',
  },
  {
    id: 3,
    image: service3,
    widthImage: 27,
    title: 'partners.service.itemTitle4',
    text: 'partners.service.itemText4',
  },
  {
    id: 4,
    image: service4,
    widthImage: 27,
    title: 'partners.service.itemTitle5',
    text: 'partners.service.itemText5',
  },
  {
    id: 5,
    image: service5,
    widthImage: 34,
    title: 'partners.service.itemTitle6',
    text: 'partners.service.itemText6',
  },
  {
    id: 6,
    image: service6,
    widthImage: 46,
    title: 'partners.service.itemTitle7',
    text: 'partners.service.itemText7',
  },
  {
    id: 7,
    image: service7,
    widthImage: 24,
    title: 'partners.service.itemTitle8',
    text: 'partners.service.itemText8',
  },
  {
    id: 8,
    image: service8,
    widthImage: 24,
    title: 'partners.service.itemTitle9',
    text: 'partners.service.itemText9',
  },
  {
    id: 9,
    image: service9,
    widthImage: 27,
    title: 'partners.service.itemTitle10',
    text: 'partners.service.itemText10',
  },
];

// Логотипы партнеров на странице "Партнеры"
export const gosPartnersArray = [
  { id: 0, image: gosPartnersLogo1, widthImage: 139 },
  { id: 1, image: gosPartnersLogo2, widthImage: 95 },
  { id: 2, image: gosPartnersLogo3, widthImage: 124 },
  { id: 3, image: gosPartnersLogo4, widthImage: 144 },
  { id: 4, image: gosPartnersLogo5, widthImage: 173 },
];

export const emotionsMembersArray = [
  { id: 1, img: emotionMember2 },
  { id: 2, img: emotionMember5 },
  { id: 3, img: emotionMember4 },
  { id: 5, img: emotionMember3 },
];

// Партнёры на странице партнёрства
export const partPartnersArray = [
  { id: 0, logo: gaspromLogo, width: `231`, link: `` },
  {
    id: 1,
    logo: ingradLogo,
    width: `109`,
    link: `https://www.ingrad.ru/?utm_source=heroleaguepartners&utm_medium=cpm&utm_campaign=ingrad_heroleague_media_all_object_thematic&utm_content=logo`,
  },
  {
    id: 2,
    logo: gazprombankLogo,
    width: `181`,
    link: `https://ad.adriver.ru/cgi-bin/click.cgi?sid=1&bt=21&ad=743750&pid=3475401&bid=8295071&bn=8295071&exss=&erir=%5Berir_token%5D&rnd=689113199`,
  },
  { id: 3, logo: pochtaLogo, width: `151`, link: `` },
  { id: 4, logo: rzdLogo, width: `151`, link: `https://www.rzd.ru/` },
  {
    id: 5,
    logo: senegskayaLogo,
    width: `190`,
    link: `http://www.senegskaya.ru/`,
  },
  { id: 6, logo: nrjLogo, width: `76`, link: `` },
  { id: 7, logo: severstalLogo, width: `181`, link: `` },
  // { id: 8, logo: magni2019, width: `181`, link: ``, },
  { id: 8, logo: hhLogo, width: `109`, link: `` },
  { id: 9, logo: maerLogo, width: `181`, link: `` },
  { id: 10, logo: worldClass, width: `181`, link: `` },
  // { id: 10, logo: huaweiLogo, width: `93`, link: `` },
  { id: 11, logo: uralhimLogo, width: `181`, link: `` },
  { id: 12, logo: marvelLogo, width: `181`, link: `` },
  { id: 13, logo: accesstyleLogo, width: `181`, link: `` },
  { id: 14, logo: abdLogo, width: `151`, link: `` },
  { id: 15, logo: ammoniyLogo, width: `181`, link: `` },

  // { id: 8, logo: gaspromLogo, width: `151`, },

  // { id: 0, logo: psbLogo, width: `139`, },
  // { id: 1, logo: gazprombankLogo, width: `181`, },
  // { id: 3, logo: pochtaLogo, width: `151`, },
  // { id: 4, logo: severstalLogo, width: `181`, },
  // { id: 5, logo: rzdLogo, width: `154`, },
  // { id: 6, logo: ingradLogo, width: `109`, },
  // { id: 7, logo: huaweiLogo, width: `93`, },
  // { id: 8, logo: nrjLogo, width: `76`, },
];

export const mainPartnerArray = [
  // { id: 0, logo: psbLogo, width: `139`, link: `https://www.psbank.ru/`, },
];

export const bigTicketArray = [
  {
    id: 0,
    event: {
      banners: {
        home_main: `http://localhost:8080/files/nizhniy800_home_main.png`,
        page_main: `http://localhost:8080/files/nizhniy800_home_main.png`,
      },
    },
    event_format: {
      description: `Масштабное беговое событие, объединяющее города и страны. Выбери одну из 4-х дистанций и стань частью «ЗаБега». Помоги своему городу стать беговой столицей России – все зависит только от тебя!`,
      title: `Заголовок`,
      requirements: [
        `Участие с 12 лет.`,
        `Требуется медсправка.`,
        `Для участников до 18 лет нужен оригинал разрешения от родителей.`,
      ],
      start_time: 162354324,
    },
    logo: projLogo2,
    format: 'Новичок - 2 КМ',
    city: {
      name_ru: 'Санкт-Петербург',
    },
    ticketNumber: '01547889011АZ',
    backgroundImage: coverCardTicket,
    backgroundGradient:
      'linear-gradient(90deg, #9F76F7 -0.27%, rgba(53, 154, 210, 0) 100%, rgba(64, 218, 126, 0) 100%), linear-gradient(267.53deg, rgba(30, 218, 230, 0.4) -0.27%, rgba(53, 154, 210, 0.4) 100%), #0C0C0C',
  },
];
