import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styles from "./Breadcrumbs.module.scss";
import BreadcrumbsItem from "./BreadcrumbsItem";

const Breadcrumbs = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.main} aria-label="breadcrumb">
      <ul className={styles.breadlist}>
        <li className={styles.breadcrumb}>
          <Link to={"/"}>{t("breadcrumbs.main")}</Link>
        </li>
        <BreadcrumbsItem />
      </ul>
    </div>
  );
};

export default Breadcrumbs;
