import { TOUCH_EVENTS_DESKTOP, TOUCH_EVENTS_TOUCH } from "../const";
import { getDevice, getSupport } from "../utils";

export const events = ({ carouselElRef, carousel, params }) => {
  const support = getSupport(), device = getDevice({ userAgent: null })
  const dummyEventListener = () => { }
  let dummyEventAttached = false

  const events = (method) => {
    const capture = !!params.nested;
    const domMethod = method === 'on' ? 'addEventListener' : 'removeEventListener';

    if (!support.touch) {
      carouselElRef.current?.[domMethod](TOUCH_EVENTS_DESKTOP.start, carousel.current.onTouchStart, false);
      document[domMethod](TOUCH_EVENTS_DESKTOP.move, carousel.current.onTouchMove, capture);
      document[domMethod](TOUCH_EVENTS_DESKTOP.end, carousel.current.onTouchEnd, false);
    } else {
      const passiveListener = TOUCH_EVENTS_TOUCH.start === 'touchstart' && support.passiveListener && params.passiveListeners ? {
        passive: true,
        capture: false
      } : false;
      carouselElRef.current?.[domMethod](TOUCH_EVENTS_TOUCH.start, carousel.current.onTouchStart, passiveListener);
      carouselElRef.current?.[domMethod](TOUCH_EVENTS_TOUCH.move, carousel.current.onTouchMove, support.passiveListener ? {
        passive: false,
        capture
      } : capture);
      carouselElRef.current[domMethod](TOUCH_EVENTS_TOUCH.end, carousel.current.onTouchEnd, passiveListener);

      if (TOUCH_EVENTS_TOUCH.cancel) {
        carouselElRef.current?.[domMethod](TOUCH_EVENTS_TOUCH.cancel, carousel.current.onTouchEnd, passiveListener);
      }
    }

    if (params.preventClicks || params.preventClicksPropagation) {
      carouselElRef.current?.[domMethod]('click', carousel.current.onClick, true);
    }

    if (method === 'on') {
      if (params.updateOnWindowResize) {
        carousel.current.on(device.ios || device.android ? 'resize orientationchange observerUpdate' : 'resize observerUpdate', carousel.current.onResize, true);
      } else {
        carousel.current.on('observerUpdate', carousel.current.onResize, true);
      }
    } else if (method === 'off') {
      if (params.updateOnWindowResize) {
        carousel.current.off(device.ios || device.android ? 'resize orientationchange observerUpdate' : 'resize observerUpdate', carousel.current.onResize, true);
      } else {
        carousel.current.off('observerUpdate', carousel.current.onResize, true);
      }
    }

  };
  const attachEvents = () => {
    if (support.touch && !dummyEventAttached) {
      document.addEventListener('touchstart', dummyEventListener);
      dummyEventAttached = true;
    }
    events('on');
  }
  const detachEvents = () => {
    events('off');
  }
  return { attachEvents, detachEvents }
}
