const city = [`Неизвестный хутор`];
const country = [`Далекое Забугорье`];


export const randomCity = () => {
  return city[0];
}

export const randomCountry = () => {
  return country[0];
}
