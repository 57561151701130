import NameSpace from '../name-space';

const NAME_SPACE = NameSpace.TICKETS;

export const getAllTickets = (state) => state[NAME_SPACE].tickets;
export const getTransferTicket = (state) => state[NAME_SPACE].transferTicket;
export const getPastTicket = (state) => state[NAME_SPACE].pastTicket;

export const getTicketsByStatus = (state, status) => {
  return state[NAME_SPACE].tickets.filter(
    (ticket) => ticket.registered === status
  );
};

export const getTicketById = (state, id) => {
  return state[NAME_SPACE].tickets?.find((t) => t.public_id === id);
};

export const getLoadTicketsState = (state) => state[NAME_SPACE].isLoadTickets;

export const getCancelRegTicketPopup = (state) =>
  state[NAME_SPACE].isCancelReg.popup;
export const getCancelRegMessage = (state) =>
  state[NAME_SPACE].isCancelReg.message;
export const getRegMessage = (state) => state[NAME_SPACE].regMessage;

export const getCancelBuyPopup = (state) => state[NAME_SPACE].isCancelBuy.popup;
export const getLoadResultMessage = (state) =>
  state[NAME_SPACE].loadResultMessage;
export const getTransferTicketMessage = (state) =>
  state[NAME_SPACE].transferTicketMessage;
export const getReturnTicketMessage = (state) =>
  state[NAME_SPACE].isCancelBuy.message;
export const getReturnTransferTicketMessage = (state) =>
  state[NAME_SPACE].returnTransferTicketMessage;
export const getSum = (state) => state[NAME_SPACE].isCancelBuy.sum;
export const getCancelBuyTicket = (state) =>
  state[NAME_SPACE].isCancelBuy.isTicketReturned;
export const getOnlineTicketInfo = (state) =>
  state[NAME_SPACE].onlineTicketInfo;
export const getUserResult = (state) => state[NAME_SPACE].result;
