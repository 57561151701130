import HiddenScroll from 'components/System/HiddenScroll/HiddenScroll';
import styles from './Popup.module.scss';

const Popup = ({outsideStyles, children}) => {
  return (
  <>
    <HiddenScroll/>
    <div className={`${styles.popup} ${outsideStyles?.popup ? outsideStyles.popup : ``}`}>{children}</div>
  </>
  )
}

export default Popup;
