import * as yup from 'yup';

import { VALIDATION_ERROR_MESSAGES } from './consts';

export function initYupMessages(){
  yup.setLocale({
    mixed: { required: VALIDATION_ERROR_MESSAGES.isRequired },
    number: {
      positive: VALIDATION_ERROR_MESSAGES.numberMustBePositive,
      integer: VALIDATION_ERROR_MESSAGES.numberMustBeInteger,
    },
  });
}