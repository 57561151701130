import React from "react";
import { elementStyle } from "../utils";
import { autoplay } from "./autoplay";
import { observer } from "./observer";
import { resize } from "./resize";
import { loop } from "./loop";
import { images } from "./images";
import { emitters } from "./emitters";
import { events } from "./events";
import { translate } from "./translate";
import { slides } from "./slides";
import { transition } from "./transition";
import { anyActions } from "./anyActions";
import { actions } from "./actions";
import { updates } from "./updates";

export const core = (props) => {
  const { carousel, carouselElRef, params, wrapperElRef } = props

  const { loopFix, calcLoopedSlides } = loop(props)
  const { preloadImages } = images(props)
  const { on, off, emit } = emitters(props)
  const { attachEvents, detachEvents } = events(props)
  const { getCarouselTranslate, setTranslate } = translate(props)
  const { slideTo, slideNext, slidePrev, slideToLoop, } = slides(props)
  const { setTransition, transitionStart, transitionEnd } = transition(props)
  const { handlePrevClick, handleNextClick, handlePaginationClick, handleThumbClick } = anyActions(props)
  const { onClick, onResize, onTouchEnd, onTouchMove, onTouchStart, setGrabCursor } = actions(props)
  const { update, updateActiveIndex, updateProgress, updateSize, updateSlides, addClasses, removeClasses, updateSlidesClasses } = updates(props)

  const setBreakpoints = () => {
    if (!carousel.current.breakpoints) return;

    const keys = Object.keys(carousel.current.breakpoints)
    const minKey = Math.min(...keys);

    const points = [{ point: window.matchMedia(`(min-width: 0px)`), breakpoint: carousel.current.breakpoints[minKey] }]

    keys.forEach((el) => {
      points.push({ point: window.matchMedia(`(min-width: ${el}px)`), breakpoint: carousel.current.breakpoints[el] })
    })
    points.forEach(({ point, breakpoint }) => {
      if (point.matches) {
        params.slidesPerView = breakpoint.slidesPerView || params.slidesPerView
        params.slidesPerGroup = breakpoint.slidesPerGroup || params.slidesPerGroup
      }
      point.onchange = () => {
        params.slidesPerView = breakpoint.slidesPerView || params.slidesPerView
        params.slidesPerGroup = breakpoint.slidesPerGroup || params.slidesPerGroup
      }
      updateSize();
      updateSlides();
      updateSlidesClasses();
    })
  }
  const mount = () => {
    if (carousel.current.mounted) return true;

    if (!carouselElRef.current) {
      return false;
    }

    carousel.current.mounted = true

    carousel.current.rtlTranslate =
      params && params.direction === 'horizontal' &&
      (carouselElRef.current.dir.toLowerCase() === 'rtl' || elementStyle(carouselElRef.current, 'direction') === 'rtl')
    if (carousel.current?.current) {
      carousel.current.wrongRTL = elementStyle(wrapperElRef.current, 'display') === '-webkit-box'
    }

    return true;
  }
  const init = () => {

    if (carousel.current.initialized) return;
    const mounted = mount();
    if (mounted === false) return;

    carousel.current.slideTo = slideTo
    carousel.current.on = on
    carousel.current.emit = emit
    carousel.current.slideNext = slideNext
    carousel.current.onTouchEnd = onTouchEnd
    carousel.current.onTouchMove = onTouchMove
    carousel.current.onTouchStart = onTouchStart
    carousel.current.onResize = onResize
    carousel.current.onClick = onClick
    carousel.current.off = off
    carousel.current.getCarouselTranslate = getCarouselTranslate
    carousel.current.loopFix = loopFix
    carousel.current.updateSize = updateSize
    carousel.current.setTransition = setTransition
    carousel.current.updateProgress = updateProgress
    carousel.current.setTranslate = setTranslate
    carousel.current.updateSlides = updateSlides
    carousel.current.updateSlidesClasses = updateSlidesClasses
    carousel.current.update = update
    carousel.current.transitionStart = transitionStart
    carousel.current.updateActiveIndex = updateActiveIndex
    carousel.current.transitionEnd = transitionEnd
    carousel.current.attachEvents = attachEvents
    carousel.current.detachEvents = detachEvents
    carousel.current.setGrabCursor = setGrabCursor
    carousel.current.preloadImages = preloadImages
    carousel.current.addClasses = addClasses
    carousel.current.removeClasses = removeClasses
    carousel.current.calcLoopedSlides = calcLoopedSlides
    carousel.current.slidePrev = slidePrev
    carousel.current.slideToLoop = slideToLoop
    carousel.current.handlePrevClick = handlePrevClick
    carousel.current.handleNextClick = handleNextClick
    carousel.current.handlePaginationClick = handlePaginationClick
    carousel.current.handleThumbClick = handleThumbClick
    carousel.current.setBreakpoints = setBreakpoints

    observer({ params, wrapperElRef, carouselElRef, carousel })
    autoplay({ params, carousel, carouselElRef, wrapperElRef })
    resize({ carousel, params, carouselElRef })

    emit('beforeInit');

    addClasses();

    updateSize();

    updateSlides();

    if (params.grabCursor && carousel.current.enabled) {
      setGrabCursor();
    }

    if (params.preloadImages) {
      preloadImages();
    }

    if (params.loop) {
      slideTo(params.initialSlide + carousel.current.loopedSlides, 0, params.runCallbacksOnInit, false, true);
    } else {
      slideTo(params.initialSlide, 0, params.runCallbacksOnInit, false, true);
    }

    attachEvents();
    setBreakpoints()

    carousel.current.initialized = true;

    emit('init');
    emit('afterInit');
    return;
  }
  const destroy = (deleteInstance, cleanStyles) => {
    if (carousel.current.destroyed) return;

    if (deleteInstance === void 0) {
      deleteInstance = true;
    }

    if (cleanStyles === void 0) {
      cleanStyles = true;
    }

    if (typeof params === 'undefined' || carousel.current.destroyed) {
      return null;
    }

    emit('beforeDestroy');

    carousel.current.initialized = false;

    detachEvents();

    if (cleanStyles) {
      removeClasses();
      carouselElRef.current.removeAttribute('style');
      wrapperElRef.current.removeAttribute('style');

      if (carousel.current.slides && carousel.current.slides.length) {
        carousel.current.slides.removeClass([carouselElRef.current.slideVisibleClass, carouselElRef.current.slideActiveClass,
        carouselElRef.current.slideNextClass, carouselElRef.current.slidePrevClass].join(' ')).removeAttr('style').removeAttr('data-carousel-slide-index');
      }
    }

    emit('destroy');

    Object.keys(carousel.current.eventsListeners).forEach(eventName => {
      off(eventName);
    });

    return null;
  }
  const renderLoop = (children) => {
    const modifiedSlides = React.Children.toArray(children).map((child, index) => {
      return React.cloneElement(child, {
        'data-carousel-slide-index': index
      });
    });

    function duplicateSlide(child, index, position) {
      return React.cloneElement(child, {
        key: `${child.key}-duplicate-${index}-${position}`,
        className: `${child.props.className || ''} ${params.slideDuplicateClass}`
      });
    }

    if (params.slidesPerView === 'auto' && !params.loopedSlides) {
      params.loopedSlides = modifiedSlides.length;
    }

    const loopedSlidesCurrent = calcLoopedSlides(modifiedSlides, params);

    const prependSlides = [];
    const appendSlides = [];
    modifiedSlides.forEach((child, index) => {
      if (index < loopedSlidesCurrent) {
        appendSlides.push(duplicateSlide(child, index, 'prepend'));
      }

      if (index < modifiedSlides.length && index >= modifiedSlides.length - loopedSlidesCurrent) {
        prependSlides.push(duplicateSlide(child, index, 'append'));
      }
    });

    carousel.current.loopedSlides = loopedSlidesCurrent;
    return [...prependSlides, ...modifiedSlides, ...appendSlides];
  }
  return { init, destroy, renderLoop }
}
