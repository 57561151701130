import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Operation } from 'reducer/profile/profile';

// #REFACT
const Gender = ({styles}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  
  const [userObj, setUserObj] = useState(JSON.parse(localStorage.userObj));
  const [gender, setGender] = useState(userObj.gender || `none`);

  useEffect(() => {
    setUserObj(JSON.parse(localStorage.userObj));
  }, []);

  const handleRadioChange = (evt) => {
    const { value } = evt.target;
    setGender(value);
    dispatch(Operation.setNewData({gender: value}));
  }

  return (
    <div className={styles.formsWrap}>
      <b className={styles.genderTitle}>{t('lk.gender.title')}:</b>
      <ul className={styles.genderList}>
        <li className="visually-hidden">
          <input
            onChange={handleRadioChange}
            value="none"
            checked={gender === `none`}
            className="visually-hidden"
            type="radio"
            name="gender-radio"
            id="gender-none" />
          <label className={styles.label} htmlFor="gender-male">{t('lk.gender.nothing')}</label>
        </li>
        <li className={styles.genderItem}>
          <input
            onChange={handleRadioChange}
            value="male"
            checked={gender === `male`}
            className="visually-hidden"
            type="radio"
            name="gender-radio"
            id="gender-male" />
          <label className={styles.label} htmlFor="gender-male">{t('lk.gender.male')}</label>
        </li>
        <li className={styles.genderItem}>
          <input
            onChange={handleRadioChange}
            value="female"
            checked={gender === `female`}
            className="visually-hidden"
            type="radio"
            name="gender-radio"
            id="gender-female" />
          <label className={styles.label} htmlFor="gender-female">{t('lk.gender.female')}</label>
        </li>
      </ul>
    </div>
  );
}

export default Gender;
