import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useValidationMail } from 'customHooks/useValidationMail';
import { getLoginError } from 'reducer/validation/selectors';
import { ActionCreator, Operation } from 'reducer/user/user';
import { ActionCreator as ValidActCreator } from 'reducer/validation/validation';
import { ReactComponent as loginLogo } from 'images/svg/login-icon-auth.svg';
import { ReactComponent as ReactLogoKey } from 'images/svg/form-key.svg';
import Input from 'components/Forms/Input/Input';
import InputPass from 'components/Forms/Input/InputPass';
import { resetSelect } from 'customHooks/resetSelect';
import { getSendingForm, getSendMailPopup, getViewConfirmBtn } from 'reducer/user/selectors';
import { useValidationPassword } from 'customHooks/useValidationPassword';
import CheckMailPopup from 'components/Popups/CheckMail/CheckMailPopup';


const AuthMail = ({ styles }) => {
  const [email, isValidMail, handleInputChange, handleFocus] = useValidationMail(``);
  const [password, isValidPass, handlePasswordChange] = useValidationPassword(``);
  const dispatch = useDispatch();
  const loginRef = useRef();
  const passwordRef = useRef();
  const loginError = useSelector((state) => getLoginError(state));
  const isSendingForm = useSelector((state) => getSendingForm(state));
  const viewConfirmBtn = useSelector(state => getViewConfirmBtn(state));
  const isSendMailPopup = useSelector(state => getSendMailPopup(state));

  useEffect(() => {
    return () => {
      dispatch(ValidActCreator.setLoginError(``));
      dispatch(ActionCreator.viewConfirmBtn(false));
    }
  }, [dispatch]);

  // ф-ция для авторизации по мейлу и паролю (`/api/auth`)
  const handleSubmit = (evt) => {
    if (evt) evt.preventDefault();

    if (isValidMail && isValidPass) {
      dispatch(ActionCreator.setSendingForm(true));
      dispatch(Operation.login({ email, password }));
    } else {
      if (!isValidMail) resetSelect(loginRef.current);
      if (!isValidPass) resetSelect(passwordRef.current);
    }
  }

  // повторная отправка письма подтверждения на почту (`/api/user/confirm/email`)
  const handleConfirmEmail = () => {
    if (isValidMail) {
      // отправка на почту письма - удаление ошибки и показ попапа
      dispatch(Operation.repeatConfirmEmail({ email }));
    } else {
      resetSelect(loginRef.current);
    }
  }

  const customHandleFocus = (evt) => {
    handleFocus(evt);
    dispatch(ActionCreator.viewConfirmBtn(false));
  }

  return (
    <>
      <form className={styles.form} onSubmit={handleSubmit}>
        <fieldset className={styles.fieldset}>
          <legend className="visually-hidden">Форма входа</legend>
          <Input
            error={loginError}
            handleFieldChange={handleInputChange}
            handleFocus={customHandleFocus}
            Logo={loginLogo}
            label={`Введите ваш Еmail:`}
            name={`email`}
            id={`reg-email`}
            ref={loginRef}
          />
          <InputPass
            error={``}
            handleFieldChange={handlePasswordChange}
            handleFocus={handleFocus}
            Logo={ReactLogoKey}
            label={`Введите пароль:`}
            name={`password`}
            id={`reg-pass`}
            ref={passwordRef}
          />
        </fieldset>
        <div className={styles.btnWrapper}>
          {!viewConfirmBtn ?
            <button
              className={styles.btn}
              type="submit"
              disabled={isSendingForm}
            >
              <span>Войти</span>
            </button> :
            <button
              type="button"
              className={styles.confirmBtn}
              onClick={handleConfirmEmail}
            >
              Подтвердить почту
            </button>
          }
        </div>
      </form>

      {isSendMailPopup &&
        <CheckMailPopup
          closePopupHandler={() => dispatch(ActionCreator.setSendMailPopup(false))} />}
    </>
  );
}

export default AuthMail;
