export const emitters = ({ carousel }) => {
  let eventsAnyListeners = []

  const on = (events, handler, priority) => {
    if (typeof handler !== 'function') return;
    const method = priority ? 'unshift' : 'push';
    events.split(' ').forEach(event => {
      if (!carousel.current.eventsListeners[event]) carousel.current.eventsListeners[event] = [];
      carousel.current.eventsListeners[event][method](handler);
    });
    return;
  }
  const off = (events, handler) => {
    if (!carousel.current.eventsListeners) return;
    events.split(' ').forEach(event => {
      if (typeof handler === 'undefined') {
        carousel.current.eventsListeners[event] = [];
      } else if (carousel.current.eventsListeners[event]) {
        carousel.current.eventsListeners[event].forEach((eventHandler, index) => {
          if (eventHandler === handler || eventHandler.__emitterProxy && eventHandler.__emitterProxy === handler) {
            carousel.current.eventsListeners[event].splice(index, 1);
          }
        });
      }
    });
    return;
  }
  const emit = (...args) => {
    if (!carousel.current.eventsListeners || carousel.current.destroyed) return;
    if (!carousel.current.eventsListeners) return;
    let events;
    let data;
    let context;
    if (typeof args[0] === 'string' || Array.isArray(args[0])) {
      events = args[0];
      data = args.slice(1, args.length);
      context = this;
    } else {
      events = args[0].events;
      data = args[0].data;
      context = args[0].context || this;
    }
    data.unshift(context);
    const eventsArray = Array.isArray(events) ? events : events.split(' ');

    eventsArray.forEach((event) => {
      if (eventsAnyListeners && eventsAnyListeners.length) {
        eventsAnyListeners.forEach((eventHandler) => {
          eventHandler.apply(context, [event, ...data]);
        });
      }
      if (carousel.current.eventsListeners && carousel.current.eventsListeners[event]) {
        carousel.current.eventsListeners[event].forEach((eventHandler) => {
          eventHandler.apply(context, data);
        });
      }
    });
    return;
  }
  return { on, off, emit }
}
