import { forwardRef, useCallback, useEffect, useState } from 'react';
import Message from 'components/Auth/Registration/Message/Message';
import PhoneInput from 'react-phone-number-input/input';
import defaultStyles from './Input.module.scss';
import { MESSAGES } from 'const/messages';
import { ReactComponent as DefaultTelLogo } from 'images/svg/form-tel.svg';
import { getClasses, stylesMerge } from 'utils/styles';


const InputPhone = forwardRef(
  ({
    className = ``,
    name = ``,
    onFocus = () => { },
    onChange = () => { },
    error = ``,
    id = Date.now(),
    label = `Введите номер телефона`,
    Logo = DefaultTelLogo,
    disabled = false,
    valid = ``,
    outsideStyle = ``,
    ...rest }, ref) => {

    const [styles, setStyles] = useState(stylesMerge(defaultStyles, outsideStyle));
    const [classes, setClasses] = useState({});

    const generateValidClass = useCallback((valid) => {
      switch (valid) {
        case MESSAGES.PHONE_NOT_CONFIRM:
          return styles.inputGroupNotConfirmValid;
        case MESSAGES.PHONE_CONFIRM:
          return styles.inputGroupConfirmValid;
        default:
          return ``;
      }
    }, [styles.inputGroupConfirmValid, styles.inputGroupNotConfirmValid]);

    // вынести в хук - Input, и InputPhone
    useEffect(() => {
      setStyles(stylesMerge(defaultStyles, outsideStyle));
    }, [outsideStyle]);

    useEffect(() => {
      setClasses({
        defaultClass: styles.inputGroup,
        errorClass: error ? styles.inputGroupError : ``,
        validClass: valid ? generateValidClass(valid) : ``,
        disabledClass: disabled ? styles.inputGroupDisabled : ``,
      });
    }, [disabled, error, generateValidClass, styles.inputGroup, styles.inputGroupDisabled, styles.inputGroupError, valid]);

    return (
      <div className={getClasses(classes).join(` `)}>
        {label !== `` &&
          <label className={`${styles.label} ${label === 'Введите корректный номер телефона' ? styles.telError : ''}`} htmlFor={id}>
            {label}
          </label>}

        <div className={styles.inputWrap}>
          <Logo className={styles.icon} />
          <PhoneInput
            {...rest}
            className={styles.input}
            id={id}
            ref={ref}
            name={name}
            onFocus={onFocus}
            onChange={onChange}
          />
        </div>

        {error.length ? <Message styles={styles} message={error} /> : ``}
      </div>
    );
  });

export default InputPhone;