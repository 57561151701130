import { useDispatch } from 'react-redux';
import styles from './RecoveryPopup.module.scss';
import Popup from '../Popup/Popup';
import { ReactComponent as ReactLogoKey } from 'images/svg/form-key.svg';
import FormRecovery from 'components/Forms/Form/FormRecovery';
import { ActionCreator } from 'reducer/user/user';
import { ActionCreator as ValidateActCreator } from 'reducer/validation/validation';
import { useEffect } from 'react';


const RecoveryPopup = ({statePopupHandler, isRecoveryMailPopup, setSwitchAuth}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(ValidateActCreator.setRecLoginError(``));
    }
  })

  const closePopup = () => {
    statePopupHandler(false);
    dispatch(ActionCreator.setRecoveryMailPopup(false));
  };

  return <Popup>
    <div className={styles.wrapper}>
      <div className={styles.titleWrap}>
        <ReactLogoKey className={styles.logoTitle} />
        <h3 className={styles.title}>Восстановление пароля</h3>
      </div>

      {!isRecoveryMailPopup ?
        <>
          <p className={styles.text}>Для восстановление пароля введите ваш электронный адрес, на вашу почту прийдет инструкция по восстановлению пароля </p>

          <FormRecovery styles={styles} closePopup={closePopup} setSwitchAuth={setSwitchAuth} />

        </> :
        <>
          <p>Проверьте почту, на ваш email отправлено письмо для сброса пароля</p>
        </>
      }
      <button
        onClick={closePopup}
        className={styles.closeBtn}
        type="button"
        aria-label="Закрыть попап"
      ></button>
    </div>
  </Popup>;
}

export default RecoveryPopup;