import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  Operation,
  ActionCreator as UserActionCreator,
} from "reducer/user/user";
import { getSendingForm, getSendMailPopup, getStatusBlockSms } from "reducer/user/selectors";
import { ActionCreator } from "reducer/validation/validation";
import {
  getCodeError,
  getLoginError,
  getPasswordError,
  getTelError,
} from "reducer/validation/selectors";

import styles from "./Registration.module.scss";
import Header from "components/Header/Header";
// import Social from 'components/Social/Social';
import CheckMailPopup from "components/Popups/CheckMail/CheckMailPopup";
import Wrapper from "components/App/Wrapper/Wrapper";
import SocRegList from "./SocRegList/SocRegList";
import Input from "components/Forms/Input/Input";
import InputPass from "components/Forms/Input/InputPass";
import InputPhone from "components/Forms/Input/InputPhone";

import { ReactComponent as ReactLogoMail } from "images/svg/form-mail.svg";
import { ReactComponent as ReactLogoPass } from "images/svg/form-key.svg";
import { ReactComponent as ReactArrowSvg } from "images/svg/arrow-reg-form.svg";
import { ReactComponent as ReactLogoSms } from "images/svg/form-sms.svg";
import { REGEX_UP_REG, REGEX_NUM } from "const/const";
import { routes } from "const/routes";
import { resetSelect } from "customHooks/resetSelect";
import { useValidationCode } from "customHooks/useValidationCode";
import { useValidationPhone } from "customHooks/useValidationPhone";
import { useValidationMail } from "customHooks/useValidationMail";

const Registration = () => {

  const [userObj, setUserObject] = useState({
    // mailing: false,
  });
  const [phone, isValidTel, handlePhoneChange, handlePhoneFocus] =
    useValidationPhone(``);
  const [code, isValidCode, handleCodeChange, handleCodeFocus] =
    useValidationCode(``);
  const [email, isValidMail, handleMailChange, handleMailFocus] =
    useValidationMail(``);
  const [isValidPass, setValidPass] = useState(false);
  const [isCheckPolitics, setCheckPolitics] = useState(false);
  const [isValidForm, setValidForm] = useState(false);
  const [methodReg, setMethodReg] = useState(`Email`);

  const isSendingForm = useSelector((state) => getSendingForm(state));
  const isSendMailPopup = useSelector((state) => getSendMailPopup(state));
  const loginError = useSelector((state) => getLoginError(state));
  const passwordError = useSelector((state) => getPasswordError(state));
  const telError = useSelector((state) => getTelError(state));
  const changeSmsBtnStatus = useSelector((state) => getStatusBlockSms(state));
  const codeError = useSelector((state) => getCodeError(state));

  const mailRef = useRef();
  const passwordRef = useRef();
  const checkPoliticsRef = useRef();
  const codeRef = useRef();
  const phoneRef = useRef();
  const dispatch = useDispatch();
  const navigation = useNavigate();

  useEffect(() => {
    setValidForm(isValidMail && isValidPass && isCheckPolitics);

    return () => {
      dispatch(ActionCreator.setLoginError(``));
      dispatch(ActionCreator.setPassError(``));
      dispatch(ActionCreator.setTelError(``));
      dispatch(ActionCreator.setCodeError(``));
      dispatch(UserActionCreator.setSendMailPopup(false));
    };
  }, [isValidMail, isValidPass, isCheckPolitics, dispatch]);

  // обработчик формы + чекбоксов
  const handleChange = (evt) => {
    const target = evt.target;
    const { name, value, checked } = target;

    if (evt.target.type === "checkbox") {
      setUserObject({ ...userObj, [name]: checked });
    } else {
      setUserObject({ ...userObj, [name]: value });
    }
  };

  // обработчик пароля и валидация
  const handlePassChange = (evt) => {
    const password = evt.target.value;
    handleChange(evt);
    setValidPass(false);

    if (password.length === 0) {
      dispatch(ActionCreator.setPassError(``));
    } else if (password.length < 4) {
      dispatch(
        ActionCreator.setPassError(
          `Пароль должен состоять минимум из 4 символов`
        )
      );
    } else if (!REGEX_UP_REG.test(password)) {
      dispatch(
        ActionCreator.setPassError(`В пароле должна быть заглавная буква`)
      );
    } else if (!REGEX_NUM.test(password)) {
      dispatch(ActionCreator.setPassError(`В пароле должна быть цифра`));
    } else {
      setValidPass(true);
      dispatch(ActionCreator.setPassError(``));
    }
  };

  // обработчик для политики конф-ти - чекбокс принятия условия
  const handleCheckChange = (evt) => {
    let check = evt.target.checked;
    if (check) evt.target.classList.remove("error-reg");
    setCheckPolitics(check);
  };

  const handleFocus = (evt) => evt.target.classList.remove("error-reg");

  // Регистрация (`/api/user`) - смотреть Operation.registration
  const handleSubmit = (evt) => {
    evt.preventDefault();

    if (isValidForm) {
      dispatch(UserActionCreator.setSendingForm(true));
      dispatch(Operation.registration({ ...userObj, email }));
    } else {
      if (!isValidMail) resetSelect(mailRef.current);
      if (!isValidPass) resetSelect(passwordRef.current);
      if (!isCheckPolitics) resetSelect(checkPoliticsRef.current);
    }
  };

  //////// phone validation \\\\\\\\\\\\\\
  // получение кода на телефон для последующей отправки его для рег-ции
  const getTelCode = (evt) => {
    evt.preventDefault();

    if (isValidTel) {
      dispatch(
        Operation.getRegistrationSmsCode({
          phone: phone.slice(1),
          registration: true,
        })
      );
    } else {
      dispatch(ActionCreator.setTelError(`Введите корректный номер телефона`));
    }
  };

  // Регистрация по телефону в случае валиднцых полей
  const handleTelSubmit = (evt) => {
    evt.preventDefault();

    if (isValidTel && isValidCode.current && isCheckPolitics) {
      const sendObj = { phone_code: code.current, phone: phone.slice(1) };
      dispatch(UserActionCreator.setSendingForm(true));
      dispatch(Operation.registrationSms(sendObj, navigation));
    } else {
      if (!isValidTel) resetSelect(phoneRef.current); // добавление реакции на невалидные значения
      if (!isValidCode.current) resetSelect(codeRef.current);
      if (!isCheckPolitics) resetSelect(checkPoliticsRef.current);
    }
  };

  //\\\\\\\\\\\\\\\ end tel valid / submit registration //////////////

  // Смена экрана регистрации телефона/мейла и обработчиков для этих экранов
  const handleChangeMethod = () => {
    setMethodReg((prevState) => {
      if (prevState === `телефон`) return `Email`;
      else if (prevState === `Email`) return `телефон`;
    });
  };

  // переключатель для формы email / phone
  const getCurrentFormState = (state) => {
    switch (state) {
      case `Email`:
        return (
          <fieldset className={styles.fieldset}>
            <Input
              error={loginError}
              handleFieldChange={handleMailChange}
              handleFocus={handleMailFocus}
              Logo={ReactLogoMail}
              label={`Ваш email`}
              name={`email`}
              id={"reg-email"}
              ref={mailRef}
              defaultValue={email}
            />
            <InputPass
              error={passwordError}
              handleFieldChange={handlePassChange}
              handleFocus={handleFocus}
              Logo={ReactLogoPass}
              label={`Придумайте пароль`}
              name={`password`}
              id={"reg-pass"}
              ref={passwordRef}
              defaultValue={userObj.password}
            />
          </fieldset>
        );
      default:
        return (
          <fieldset className={styles.fieldset}>
            <div className={styles.phoneWrap}>
              <InputPhone
                error={telError}
                international={true}
                country="RU"
                withCountryCallingCode={true}
                id="reg-sms"
                type="tel"
                name="phone"
                ref={phoneRef}
                value={phone}
                onChange={handlePhoneChange}
                onFocus={handlePhoneFocus}
              />
              {/* {changeSmsBtnStatus && <span className={styles.message}>*Отправка СМС возможна через {120} сек.</span>} */}
              <div className={styles.senBtnWrap}>
                <button
                  onClick={getTelCode}
                  className={styles.sendSmsBtn}
                  type="button"
                >
                  {!changeSmsBtnStatus
                    ? `Отправить СМС`
                    : `Отправить СМС повторно`}
                </button>
                {changeSmsBtnStatus && (
                  <span className={styles.notification}>
                    Смс с кодом отправлена на ваш номер телефона
                  </span>
                )}
              </div>
            </div>

            <Input
              error={codeError}
              handleFieldChange={handleCodeChange}
              handleFocus={handleCodeFocus}
              Logo={ReactLogoSms}
              label={`Введите код из СМС`}
              name={`phone_code`}
              id={`phone-code`}
              ref={codeRef}
              defaultValue={code.current}
            />
          </fieldset>
        );
    }
  };

  // обработчики для mail/phone
  const getCurrentSubmit = (state) => {
    switch (state) {
      case `Email`:
        return handleSubmit;
      default:
        return handleTelSubmit;
    }
  };

  return (
    <Wrapper>
      {/* <Social /> */}

      <Header />

      <section className={styles.container}>
        <h2 className={styles.title}>Регистрация героя</h2>
        {/* <p className={styles.desc}>
          Выберите удобный для вас способ регистрации на сайте heroleague.ru
        </p> */}

        <form
          action=""
          className={styles.form}
          onSubmit={getCurrentSubmit(methodReg)}
        >
          <fieldset className={styles.fieldset}>
            <Input
              error={loginError}
              handleFieldChange={handleMailChange}
              handleFocus={handleMailFocus}
              Logo={ReactLogoMail}
              label={`Ваш email`}
              name={`email`}
              id={"reg-email"}
              ref={mailRef}
              defaultValue={email}
            />
            <InputPass
              error={passwordError}
              handleFieldChange={handlePassChange}
              handleFocus={handleFocus}
              Logo={ReactLogoPass}
              label={`Придумайте пароль`}
              name={`password`}
              id={"reg-pass"}
              ref={passwordRef}
              defaultValue={userObj.password}
            />
          </fieldset>

          <div className={styles.wrapBtn}>
            {/*      <button
              type="button"
              onClick={handleChangeMethod}
              className={styles.changeMethodBtn}
            >
              Регистрация через {`${methodReg === `Email` ? `телефон` : `Email`}`}
            </button> */}

            <button
              type="button"
              onClick={() => navigation("/login")}
              className={styles.loginBtn}
            >
              Авторизация
            </button>
          </div>

          <div className={styles.btnWrap}>
            <div className={styles.firstCheckbox}>
              <input
                className={`visually-hidden`}
                id="reg-check-politics"
                type="checkbox"
                ref={checkPoliticsRef}
                onChange={handleCheckChange}
              />
              <label
                className={styles.checkboxLabel}
                htmlFor="reg-check-politics"
              >
                Я согласен на обработку{" "}
                <a href="/api/files/document/pers">персональных данных</a>
              </label>
            </div>
            <div className={styles.secondCheckbox}>
              <input
                className={`visually-hidden`}
                id="reg-check-mailing"
                name="mailing"
                type="checkbox"
                onChange={handleChange}
              />
              <label
                className={styles.checkboxLabel}
                htmlFor="reg-check-mailing"
              >
                Я хочу получать <a href="https://heroleague.ru/api/files/document/advertising">рассылку</a> новинок и акций компании
              </label>
            </div>
            <div className={styles.socialWrap}>
              <b className={styles.socialTitle}>
                Или зарегистрируйтесь с помощью:
              </b>
              <SocRegList styles={styles} />
            </div>
          </div>

          <button disabled={isSendingForm} className={styles.btn} type="submit">
            <span>Зарегистрироваться</span>
          </button>
        </form>

        <Link to={routes.main.path} className={styles.backBtn}>
          <ReactArrowSvg className={styles.btnArrow} />
          На главную
        </Link>
      </section>

      {isSendMailPopup ? (
        <CheckMailPopup
          closePopupHandler={() => {
            dispatch(UserActionCreator.setSendMailPopup(false));
          }}
        />
      ) : (
        ``
      )}
    </Wrapper>
  );
};

export default Registration;
