import { elementChildren } from "../utils";

export const slides = ({ params, carousel, wrapperElRef, paginationEl, thumb }) => {
  let clientLeft = null
  const slidesPerViewDynamic = (view, exact) => {
    if (view === void 0) {
      view = 'current';
    }

    if (exact === void 0) {
      exact = false;
    }
    let spv = 1;

    if (params.centeredSlides) {
      let slideSize = carousel.current.slides[carousel.current.activeIndex].carousel.currentSlideSize;
      let breakLoop;

      for (let i = carousel.current.activeIndex + 1; i < carousel.current.slides.length; i += 1) {
        if (carousel.current.slides[i] && !breakLoop) {
          slideSize += carousel.current.slides[i].carousel.currentSlideSize;
          spv += 1;
          if (slideSize > carousel.current.size) breakLoop = true;
        }
      }

      for (let i = carousel.current.activeIndex - 1; i >= 0; i -= 1) {
        if (carousel.current.slides[i] && !breakLoop) {
          slideSize += carousel.current.slides[i].carousel.currentSlideSize;
          spv += 1;
          if (slideSize > carousel.current.size) breakLoop = true;
        }
      }
    } else {
      if (view === 'current') {
        for (let i = carousel.current.activeIndex + 1; i < carousel.current.slides.length; i += 1) {
          const slideInView = exact ? carousel.current.slidesGrid[i] + carousel.current.slidesSizesGrid[i] - carousel.current.slidesGrid[carousel.current.activeIndex] < carousel.current.size : carousel.current.slidesGrid[i] - carousel.current.slidesGrid[carousel.current.activeIndex] < carousel.current.size;

          if (slideInView) {
            spv += 1;
          }
        }
      } else {
        for (let i = carousel.current.activeIndex - 1; i >= 0; i -= 1) {
          const slideInView = carousel.current.slidesGrid[carousel.current.activeIndex] - carousel.current.slidesGrid[i] < carousel.current.size;

          if (slideInView) {
            spv += 1;
          }
        }
      }
    }

    return spv;
  }
  const slideToLoop = (index, currentSpeed, runCallbacks, internal) => {
    if (index === void 0) {
      index = 0;
    }

    if (currentSpeed === void 0) {
      currentSpeed = params.speed;
    }

    if (runCallbacks === void 0) {
      runCallbacks = true;
    }
    let newIndex = index;

    if (params.loop) {
      newIndex += carousel.current.loopedSlides;
    }

    return slideTo(newIndex, currentSpeed, runCallbacks, internal);
  }
  const slideTo = (index, currentSpeed, runCallbacks, internal, initial) => {
    if (params.isPagination) {
      let currentIndex = index

      const bullets = elementChildren(paginationEl.current, `.${carousel.current.paginationBulletClass}`);
      if ((currentIndex - bullets.length) >= bullets.length) {
        currentIndex = bullets.length
      } else if (currentIndex < bullets.length) {
        currentIndex = currentIndex + bullets.length
      }
      bullets?.forEach((el) => {
        if (Number(el.getAttribute("data-pagination-index")) === (currentIndex - bullets.length)) {
          el.classList.add(carousel.current.paginationBulletActiveClass)
        } else {
          el.classList.remove(carousel.current.paginationBulletActiveClass)
        }
      })
    }
    if (thumb?.current) {
      const thumbs = elementChildren(thumb.current, `.${carousel.current.thumbClass}`);
      let currentIndex = index - 1
      if ((currentIndex - thumbs.length) >= thumbs.length - 1) {
        currentIndex = thumbs.length - 1
      } else if (currentIndex < thumbs.length - 1) {
        currentIndex = currentIndex + thumbs.length
      }
      thumbs?.forEach((thumb) => {
        if (Number(thumb.getAttribute("data-thumb-index")) === (currentIndex - (thumbs.length - 1))) {
          thumb.style.opacity = 1
        } else {
          thumb.style.opacity = 0.5
        }
      })
    }

    if (index === void 0) {
      index = 0;
    }

    if (currentSpeed === void 0) {
      currentSpeed = params.speed;
    }

    if (runCallbacks === void 0) {
      runCallbacks = true;
    }

    if (typeof index !== 'number' && typeof index !== 'string') {
      throw new Error(`The 'index' argument cannot have type other than 'number' or 'string'. [${typeof index}] given.`);
    }

    if (typeof index === 'string') {

      const indexAsNumber = parseInt(index, 10);

      const isValidNumber = isFinite(indexAsNumber);

      if (!isValidNumber) {
        throw new Error(`The passed-in 'index' (string) couldn't be converted to 'number'. [${index}] given.`);
      }

      index = indexAsNumber;
    }

    let slideIndex = index;
    if (slideIndex < 0) slideIndex = 0;

    if (carousel.current.animating && params.preventInteractionOnTransition || !carousel.current.enabled && !internal && !initial) {
      return false;
    }

    const skip = Math.min(params.slidesPerGroupSkip, slideIndex);
    let snapIndexCurrent = skip + Math.floor((slideIndex - skip) / params.slidesPerGroup);
    if (snapIndexCurrent >= carousel.current.snapGrid.length) snapIndexCurrent = carousel.current.snapGrid.length - 1;

    if ((carousel.current.activeIndex || params.initialSlide || 0) === (carousel.current.previousIndex || 0) && runCallbacks) {
      carousel.current.emit('beforeSlideChangeStart');
    }

    const currentTranslate = -carousel.current.snapGrid[snapIndexCurrent];

    carousel.current.updateProgress(currentTranslate);

    for (let i = 0; i < carousel.current.slidesGrid.length; i += 1) {
      const normalizedTranslate = -Math.floor(currentTranslate * 100);
      const normalizedGrid = Math.floor(carousel.current.slidesGrid[i] * 100);
      const normalizedGridNext = Math.floor(carousel.current.slidesGrid[i + 1] * 100);

      if (typeof carousel.current.slidesGrid[i + 1] !== 'undefined') {
        if (normalizedTranslate >= normalizedGrid && normalizedTranslate < normalizedGridNext - (normalizedGridNext - normalizedGrid) / 2) {
          slideIndex = i;
        } else if (normalizedTranslate >= normalizedGrid && normalizedTranslate < normalizedGridNext) {
          slideIndex = i + 1;
        }
      } else if (normalizedTranslate >= normalizedGrid) {
        slideIndex = i;
      }
    }

    if (carousel.current.initialized && slideIndex !== carousel.current.activeIndex) {
      if (!carousel.current.allowSlideNext && currentTranslate < carousel.current.translate && carousel.current.translate < -carousel.current.snapGrid[0]) {
        return false;
      }

      if (!carousel.current.allowSlidePrev && currentTranslate > carousel.current.translate && carousel.current.translate > -carousel.current.snapGrid[carousel.current.snapGrid.length - 1]) {
        if ((carousel.current.activeIndex || 0) !== slideIndex) return false;
      }
    }

    let direction;
    if (slideIndex > carousel.current.activeIndex) direction = 'next'; else if (slideIndex < carousel.current.activeIndex) direction = 'prev'; else direction = 'reset';

    if (carousel.current.rtlTranslate && -currentTranslate === carousel.current.translate || !carousel.current.rtlTranslate && currentTranslate === carousel.current.translate) {
      carousel.current.updateActiveIndex(slideIndex);

      carousel.current.updateSlidesClasses();

      if (params.effect !== 'slide') {
        carousel.current.setTranslate(currentTranslate);
      }

      if (direction !== 'reset') {
        carousel.current.transitionStart(runCallbacks, direction);
        carousel.current.transitionEnd(runCallbacks, direction);
      }

      return false;
    }

    carousel.current.setTransition(currentSpeed);
    carousel.current.setTranslate(currentTranslate);
    carousel.current.updateActiveIndex(slideIndex);
    carousel.current.updateSlidesClasses();
    carousel.current.emit('beforeTransitionStart', currentSpeed, internal);
    carousel.current.transitionStart(runCallbacks, direction);

    if (currentSpeed === 0) {
      carousel.current.transitionEnd(runCallbacks, direction);
    } else if (!carousel.current.animating) {
      carousel.current.animating = true;
      if (!carousel.current.transEnd) {
        carousel.current.transEnd = function trans(e) {
          if (!carousel.current.transEnd || carousel.current.destroyed) return;
          wrapperElRef.current.removeEventListener('transitionend', carousel.current.transEnd);
          wrapperElRef.current.removeEventListener('webkitTransitionEnd', carousel.current.transEnd);
          carousel.current.transEnd = null;
          carousel.current.transitionEnd(runCallbacks, direction);
        };
      }

      wrapperElRef.current.addEventListener('transitionend', carousel.current.transEnd);
      wrapperElRef.current.addEventListener('webkitTransitionEnd', carousel.current.transEnd);
    }

    return true;
  }
  const slidePrev = (currentSpeed, runCallbacks, internal) => {
    if (currentSpeed === void 0) {
      currentSpeed = params.speed;
    }

    if (runCallbacks === void 0) {
      runCallbacks = true;
    }
    if (!carousel.current.enabled) return;

    if (params.loop) {
      if (carousel.current.animating && params.loopPreventsSlide && !params.free) return false;
      carousel.current.loopFix();

      clientLeft = wrapperElRef.current.clientLeft;
    }

    const currentTranslate = carousel.current.rtlTranslate ? carousel.current.translate : -carousel.current.translate;

    function normalize(val) {
      if (val < 0) return -Math.floor(Math.abs(val));
      return Math.floor(val);
    }

    const normalizedTranslate = normalize(currentTranslate);
    const normalizedSnapGrid = carousel.current.snapGrid.map(val => normalize(val));
    let prevSnap = carousel.current.snapGrid[normalizedSnapGrid.indexOf(normalizedTranslate) - 1];

    let prevIndex = 0;

    if (typeof prevSnap !== 'undefined') {
      prevIndex = carousel.current.slidesGrid.indexOf(prevSnap);
      if (prevIndex < 0) prevIndex = carousel.current.activeIndex - 1;

      if (params.slidesPerView === 'auto' && params.slidesPerGroup === 1 && params.slidesPerGroupAuto) {
        prevIndex = prevIndex - slidesPerViewDynamic('previous', true) + 1;
        prevIndex = Math.max(prevIndex, 0);
      }
    }

    if (params.rewind && carousel.current.isBeginning) {
      const lastIndex = carousel.current.slides.length - 1;
      return slideTo(lastIndex, currentSpeed, runCallbacks, internal);
    }
    return slideTo(prevIndex, currentSpeed, runCallbacks, internal);
  }
  const slideNext = (currentSpeed, runCallbacks, internal) => {
    if (currentSpeed === void 0) {
      currentSpeed = params.speed;
    }

    if (runCallbacks === void 0) {
      runCallbacks = true;
    }
    if (!carousel.current.enabled) return;
    let perGroup = params.slidesPerGroup;

    if (params.slidesPerView === 'auto' && params.slidesPerGroup === 1 && params.slidesPerGroupAuto) {
      perGroup = Math.max(slidesPerViewDynamic('current', true), 1);
    }

    const increment = carousel.current.activeIndex < params.slidesPerGroupSkip ? 1 : perGroup;

    if (params.loop) {
      if (carousel.current.animating && params.loopPreventsSlide && !params.free) return false;
      carousel.current.loopFix();

      clientLeft = wrapperElRef.current.clientLeft;
    }

    if (params.rewind && carousel.current.isEnd) {
      return slideTo(0, currentSpeed, runCallbacks, internal);
    }

    return slideTo(carousel.current.activeIndex + increment, currentSpeed, runCallbacks, internal);
  }
  return { slideTo, slideNext, slidePrev, slideToLoop, }
}
