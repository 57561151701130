import { forwardRef, useEffect, useState } from 'react';
import Message from 'components/Auth/Registration/Message/Message';
import styles from './Input.module.scss';
import { ReactComponent as ReactLogoEyeHid } from 'images/svg/pass-eye-hid.svg';
import { ReactComponent as ReactLogoEyeView } from 'images/svg/pass-eye-view.svg';

const InputPass = forwardRef(({
    error,
    handleFieldChange,
    handleFocus,
    Logo,
    label,
    name,
    id,
    defaultValue
  }, ref) => {
  const [isPassView, setPassView] = useState(false);

  useEffect(() => {
    document.addEventListener('mouseup', handlerPass);
    return () => {
      document.removeEventListener('mouseup', handlerPass);
    }
  }, [isPassView]);

  const handlerPass = () => setPassView(false);

  return (
    <div className={`${styles.inputGroup} ${error.length ? styles.inputGroupError : ``}`}>
      {label !== `` && <label className={styles.label} htmlFor={id}>{label}</label>}
      <div className={styles.inputWrap}>
        <Logo className={styles.icon} />
        <input
          className={styles.input}
          autoComplete="off"
          type={isPassView ? `text`: `password`}
          id={id}
          name={name}
          ref={ref}
          onChange={handleFieldChange}
          onFocus={handleFocus}
          defaultValue={defaultValue}
        />
      </div>
      <button
        type="button"
        onMouseDown={() => setPassView(true)}
        className={styles.btnViewPass}>
        {isPassView ? <ReactLogoEyeView/> : <ReactLogoEyeHid/> }
      </button>
      {error.length ? <Message styles={styles} message={error} /> : ``}
    </div>
  );
});

export default InputPass;