export const autoplay = ({ params, carousel, carouselElRef, wrapperElRef, }) => {

  let autoplayRunning = false, autoplayPaused = false
  let timeout;
  let raf;
  let autoplayDelayTotal = params && params.autoplayDelay ? params.autoplayDelay : 3000;
  let autoplayDelayCurrent = params && params.autoplayDelay ? params.autoplayDelay : 3000;
  let autoplayTimeLeftCurrent;
  let autoplayStartTime = new Date().getTime;
  let wasPaused;
  let isTouched;
  let pausedByTouch;
  let touchStartTimeout;
  let slideChanged;
  let pausedByInteraction;

  const onTransitionEnd = (e) => {
    if (carousel.current.destroyed || !wrapperElRef.current) return;
    if (e.target !== wrapperElRef.current) return;
    wrapperElRef.current.removeEventListener('transitionend', onTransitionEnd);
    resume();
  }
  const calcTimeLeft = () => {
    if (carousel.current.destroyed || !autoplayRunning) return;
    if (autoplayPaused) {
      wasPaused = true;
    } else if (wasPaused) {
      autoplayDelayCurrent = autoplayTimeLeftCurrent;
      wasPaused = false;
    }
    const timeLeft = autoplayPaused
      ? autoplayTimeLeftCurrent
      : autoplayStartTime + autoplayDelayCurrent - new Date().getTime();
    carousel.current.emit('autoplayTimeLeft', timeLeft, timeLeft / autoplayDelayTotal);
    raf = requestAnimationFrame(() => {
      calcTimeLeft();
    });
  }
  const getSlideDelay = () => {
    let activeSlideEl = carousel.current.slides[carousel.current.activeIndex];
    if (!activeSlideEl) return undefined;
    const currentSlideDelay = activeSlideEl?.getAttribute && parseInt(activeSlideEl.getAttribute('data-carousel-autoplay'), 10);
    return currentSlideDelay;
  };
  const run = (delayForce) => {
    if (carousel.current.destroyed || !autoplayRunning) return;
    cancelAnimationFrame(raf);
    calcTimeLeft();

    let delay = typeof delayForce === 'undefined' ? params.autoplayDelay : delayForce;
    autoplayDelayTotal = params.autoplayDelay;
    autoplayDelayCurrent = params.autoplayDelay;
    const currentSlideDelay = getSlideDelay();
    if (
      !Number.isNaN(currentSlideDelay) &&
      currentSlideDelay > 0 &&
      typeof delayForce === 'undefined'
    ) {
      delay = currentSlideDelay;
      autoplayDelayTotal = currentSlideDelay;
      autoplayDelayCurrent = currentSlideDelay;
    }
    autoplayTimeLeftCurrent = delay;
    const proceed = () => {
      if (carousel.current.destroyed) return;
      if (params.autoplayReverseDirection) {
        if (!carousel.current.isBeginning || params.loop || params.rewind) {
          carousel.current.slidePrev(params.speed, true, true);
          carousel.current.emit('autoplay');
        } else if (!params.autoplayStopOnLastSlide) {
          carousel.current.slideTo(carousel.current.slides.length - 1, params.speed, true, true);
          carousel.current.emit('autoplay');
        }
      } else {
        if (!carousel.current.isEnd || params.loop || params.rewind) {
          carousel.current.slideNext(params.speed, true, true);
          carousel.current.emit('autoplay');
        } else if (!params.autoplayStopOnLastSlide) {
          carousel.current.slideTo(0, params.speed, true, true);
          carousel.current.emit('autoplay');
        }
      }
    };
    if (delay > 0) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        proceed();
      }, delay);
    } else {
      requestAnimationFrame(() => {
        proceed();
      });
    }

    return delay;
  };
  const start = () => {
    autoplayRunning = true;
    run();
    carousel.current.emit('autoplayStart');
  };
  const stop = () => {
    autoplayRunning = false;
    clearTimeout(timeout);
    cancelAnimationFrame(raf);
    carousel.current.emit('autoplayStop');
  };
  const pause = (internal, reset) => {
    if (carousel.current.destroyed || !autoplayRunning) return;

    clearTimeout(timeout);
    if (!internal) {
      pausedByInteraction = true;
    }

    const proceed = () => {
      carousel.current.emit('autoplayPause');
      if (params.autoplayWaitForTransition && wrapperElRef?.current) {
        wrapperElRef.current.addEventListener('transitionend', onTransitionEnd);
      } else {
        resume();
      }
    };

    autoplayPaused = true;
    if (reset) {
      if (slideChanged) {
        autoplayTimeLeftCurrent = params.autoplayDelay;
      }
      slideChanged = false;
      proceed();
      return;
    }
    const delay = autoplayTimeLeftCurrent || params.autoplayDelay;
    autoplayTimeLeftCurrent = delay - (new Date().getTime() - autoplayStartTime);
    if (carousel.current.isEnd && autoplayTimeLeftCurrent < 0 && !params.loop) return;
    if (autoplayTimeLeftCurrent < 0) autoplayTimeLeftCurrent = 0;
    proceed();
  };
  const resume = () => {
    if (
      (carousel.current.isEnd && autoplayTimeLeftCurrent < 0 && !params.loop) ||
      carousel.current.destroyed ||
      !autoplayRunning
    )
      return;
    autoplayStartTime = new Date().getTime();
    if (pausedByInteraction) {
      pausedByInteraction = false;
      run(autoplayTimeLeftCurrent);
    } else {
      run();
    }
    autoplayPaused = false;
    carousel.current.emit('autoplayResume');
  };
  const onVisibilityChange = () => {
    if (carousel.current.destroyed || !autoplayRunning) return;
    if (document.visibilityState === 'hidden') {
      pausedByInteraction = true;
      pause(true);
    }
    if (document.visibilityState === 'visible') {
      resume();
    }
  };
  const onPointerEnter = (e) => {
    if (e.pointerType !== 'mouse') return;
    pausedByInteraction = true;
    if (carousel.current.animating || autoplayPaused) return;
    pause(true);
  };
  const onPointerLeave = (e) => {
    if (e.pointerType !== 'mouse') return;
    if (autoplayPaused) {
      resume();
    }
  };
  const attachMouseEvents = () => {
    if (params.autoplayPauseOnMouseEnter) {
      carouselElRef.current?.addEventListener('pointerenter', onPointerEnter);
      carouselElRef.current?.addEventListener('pointerleave', onPointerLeave);
    }
  };
  const detachMouseEvents = () => {
    carouselElRef.current?.removeEventListener('pointerenter', onPointerEnter);
    carouselElRef.current?.removeEventListener('pointerleave', onPointerLeave);
  };
  const attachDocumentEvents = () => {
    document.addEventListener('visibilitychange', onVisibilityChange);
  };
  const detachDocumentEvents = () => {
    document.removeEventListener('visibilitychange', onVisibilityChange);
  };
  carousel.current.on('init', () => {
    if (params.autoplayEnabled) {
      attachMouseEvents();
      attachDocumentEvents();
      autoplayStartTime = new Date().getTime();
      start();
    }
  });
  carousel.current.on('destroy', () => {
    detachMouseEvents();
    detachDocumentEvents();
    if (autoplayRunning) {
      stop();
    }
  });
  carousel.current.on('beforeTransitionStart', (_s, speed, internal) => {
    if (carousel.current.destroyed || !autoplayRunning) return;
    if (internal || !params.autoplayDisableOnInteraction) {
      pause(true, true);
    } else {
      stop();
    }
  });
  carousel.current.on('sliderFirstMove', () => {
    if (carousel.current.destroyed || !autoplayRunning) return;

    if (params.autoplayDisableOnInteraction) {
      stop();
      return;
    }
    isTouched = true;
    pausedByTouch = false;
    pausedByInteraction = false;
    touchStartTimeout = setTimeout(() => {
      pausedByInteraction = true;
      pausedByTouch = true;
      pause(true);
    }, 200);
  });
  carousel.current.on('touchEnd', () => {
    if (carousel.current.destroyed || !autoplayRunning || !isTouched) return;
    clearTimeout(touchStartTimeout);
    clearTimeout(timeout);

    if (params.autoplayDisableOnInteraction) {
      pausedByTouch = false;
      isTouched = false;
      return;
    }

    pausedByTouch = false;
    isTouched = false;
  });
  carousel.current.on('slideChange', () => {
    if (carousel.current.destroyed || !autoplayRunning) return;
    slideChanged = true;
  });
}
