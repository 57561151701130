import styles from "./Loader.module.scss";

const Loader = ({ customStyles }) => {
  return (
    <div
      className={`${styles.loaderWrapper} ${
        customStyles && styles.customStyles
      }`}
    >
      <div className={styles.loader}></div>
    </div>
  );
};

export default Loader;
