import styles from '../Personal.module.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Operation } from 'reducer/user/user';
import { ReactComponent as ClubIcon } from "images/svg/Personal/iconClub.svg";
import { ReactComponent as TicketIcon } from "images/svg/Personal/iconTicket.svg";
import { ReactComponent as Shopcon } from "images/svg/Personal/iconShopping.svg";
import { ReactComponent as SettingIcon } from "images/svg/Personal/iconSettings.svg";
import { ReactComponent as ActivityIcon } from "images/profile/activ-icon.svg";
import { ReactComponent as InsurancesIcon } from "images/profile/insurance_icon.svg";
import { routes } from 'const/routes';
import { InsuranceSmallCard } from 'components/MyInsurances/InsuranceCard/InsuranceSmallCard';
import { useState } from 'react';
import { InsurancePopup } from 'components/Popups/InsurancePopup/InsurancePopup';

export const PersonalLinks = ({ handleStateChange }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const location = useLocation();
    const [selectedInsurance, setSelectedInsurance] = useState(null);

    const handleExitChange = () => dispatch(Operation.logout());

    const items = [
        {
            id: 0,
            title: 'Билеты',
            active: routes.myEvents.path,
            icon: <TicketIcon />
        }, {
            id: 1,
            title: 'Товары',
            active: routes.myOrders.path,
            icon: <Shopcon />
        },
        {
            id: 2,
            title: 'Страховки',
            active: routes.myInsurances.path,
            icon: <InsurancesIcon />
        },
        , {
            id: 3,
            title: 'Клубы',
            active: routes.myClubs.path,
            icon: <ClubIcon />
        },
        {
            id: 4,
            title: 'Настройки',
            active: routes.personal.path,
            icon: <SettingIcon />
        },
        {
            id: 5,
            title: 'Активность',
            active: routes.session.path,
            icon: <ActivityIcon />
        },
    ];

    return (
        <div className={styles.asideWrapper}>
            <aside className={styles.aside}>
                <ul className={styles.asideList}>

                    {items.map(el => (
                        <li
                            onClick={() => handleStateChange(el)}
                            key={el.id}
                            className={styles.asideItem}
                            data-active={el.active}
                            data-state={el.active === location?.pathname ? 'active' : 'inactive'}
                        >
                            <div style={{ display: "flex", alignItems: "flex-start", columnGap: "12px" }}>{el.icon}{t(`lk.settingPage.links.${el.id}`)}</div>
                        </li>
                    ))}

                </ul>
            </aside>

            <button
                onClick={handleExitChange}
                className={styles.btn}
            >{t('lk.exitBtn')}
            </button>

            {/*  {routes.myInsurances.path === location?.pathname &&  */}<div className={styles.insurance}><InsuranceSmallCard setSelectedInsurance={setSelectedInsurance} /></div>{/* } */}

            {/*  <Advertising
                link="https://ib.psbank.ru/"
                src={psbBannerM}
                alt="ПСБ"
                reklama={{ name: `ПАО "Промсвязьбанк"`, inn: "ИНН: 7744000912", gov_id: "ЕРИР: 2Vtzquyc9Ti" }}
                width="280px"
                height="280px"
            /> */}
            {selectedInsurance && <InsurancePopup statePopupHandler={() => setSelectedInsurance(null)} />}
        </div>
    );
};