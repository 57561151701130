import { useDispatch } from 'react-redux';
import { ActionCreator } from 'reducer/user/user';
import Popup from '../Popup/Popup';
import styles from './ResetPopup.module.scss';

const ResetPopup = () => {
  const dispatch = useDispatch();

  const closePopupHandler = () => {
    dispatch(ActionCreator.setResetPasswordPopup(false));
  }

  return <Popup>
    <div className={styles.wrapper}>
      <h2 className={styles.title}>Пароль успешно изменен, авторизируйтесь</h2>
      <button onClick={closePopupHandler}
        className={styles.btn}>
        <span className="visually-hidden">Закрыть попап</span>
      </button>
    </div>
  </Popup>;
}

export default ResetPopup;
