import { backendLogger } from "utils/logger";

const initialState = {
  status: false,
};

export const ActionType = {
  SET_NEW_STATUS: `SET_NEW_STATUS`,
};

export const ActionCreator = {
  setNewStatus: (status) => ({
    type: ActionType.SET_NEW_STATUS,
    payload: status,
  }),
};

export const Operation = {
  setNewSubscribe: (email) => async (dispatch, getState, api) => {
    const response = await api.put("/api/user/subscribe/news", email);

    if (response.status === 200) {
      dispatch(ActionCreator.setNewStatus(response.data.message));
    } /* else if (response.status >= 400) {
      backendLogger(response);
    } */
  },
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.SET_NEW_STATUS:
      return { ...state, status: action.payload };
    default:
      return state;
  }
};
