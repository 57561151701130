import { useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styles from './Header.module.scss';
import { routes } from 'const/routes';
import { headerList } from 'mocks/links';
import { getCurrentAuthStatus } from 'reducer/user/selectors';
import { getAllGoodsInBasket } from 'reducer/basket/selectors';
import { AuthorizationStatus } from 'const/const';
import { ReactComponent as Logo } from 'images/svg/logo-league.svg';
import { ReactComponent as BacketIcon } from 'images/svg/basket-icon.svg';
import { ReactComponent as AuthIcon } from 'images/svg/auth-header.svg';
import { ReactComponent as SearchIcon } from 'images/svg/icon-search.svg';
import HiddenScroll from 'components/System/HiddenScroll/HiddenScroll';
import { useMediaQuery } from 'react-responsive';


const Header = (props) => {
  const isBurger = useMediaQuery({ query: '(max-width: 1200px)' });

  const { theme } = props;
  const { i18n, t } = useTranslation();
  const [themeVariable] = useState(theme === `white`);
  const [stateMenu, setStateMenu] = useState(false);
  const [lang, setLang] = useState(`ru`);
  const currentAuthStatus = useSelector((state) => getCurrentAuthStatus(state));
  const goods = useSelector(state => getAllGoodsInBasket(state));
  const basketGoods = JSON.parse(localStorage.getItem("basketGoods")) && JSON.parse(localStorage.getItem("basketGoods"));

  useLayoutEffect(() => {
    if (localStorage.lang) setLang(localStorage.lang);
  }, [setLang]);

  const changeLang = (evt) => {
    const { value } = evt.target; // ru/en
    setLang(value);
    i18n.changeLanguage(value);
    localStorage.setItem('lang', value);
  };

  const handleMenuState = (evt) => {
    evt.preventDefault();
    setStateMenu(prev => !prev);
  };

  return (
    <header className={`${styles.header} ${stateMenu ? styles.headerOpen : ``}`}>
      {stateMenu && <HiddenScroll />}
      <Link to={routes.main.path} className={styles.linkLogo}>
        <Logo className={`${styles.logo} ${themeVariable && styles.logoWhite}`} />
      </Link>

      <div className={styles.langWrap}>
        <input className={`${styles.inputLang} visually-hidden`}
          type="radio"
          value="ru"
          name="language"
          id="rusLang"
          onChange={changeLang}
          checked={lang === `ru`}
        />
        <label htmlFor="rusLang" className={styles.headerLabel}>Рус</label>
        <input className={`${styles.inputLang} visually-hidden`}
          type="radio"
          value="en"
          name="language"
          id="engLang"
          onChange={changeLang}
          checked={lang === `en`}
        />
        <label htmlFor="engLang" className={styles.headerLabel}>Eng</label>
      </div>

      <nav className={`${styles.navigation} ${stateMenu ? styles.navigationAnimate : ``}`}>
        <p className={styles.mobSubtitle}>{t('header.desc')}:</p>
        <ul className={styles.list}>
          {isBurger ? headerList.map(item => (
            <li className={styles.item} key={item.t}>
              {item.external ?
                <a href={item.route} className={`${styles.link} ${themeVariable && styles.linkWhite}`} target="_blank" rel="noopener noreferrer">{t(`header.${item.t}`)}</a>
                :
                <Link to={routes[item.route]['path']} className={`${styles.link} ${themeVariable && styles.linkWhite} ${item.newYear && styles.newYear}`}>{t(`header.${item.t}`)}</Link>}
            </li>
          )) :
            headerList.filter((el) => !el.mobile).map((item, i) => (
              <li className={styles.item} key={`${i}${item.t}`}>
                {item.external ?
                  <a href={item.route} className={`${styles.link} ${themeVariable && styles.linkWhite}`} target="_blank" rel="noopener noreferrer">{t(`header.${item.t}`)}</a>
                  :
                  <>{item.route === "corporate" ? <span className={styles.menuWrapper}>
                    <div className={styles.menu}>
                      <div className={styles.wrapper}>
                        {item.corporateLinks.map((el, i) => (
                          <Link key={`${i}${item.t}`} to={routes[el.route]['path']} className={`${styles.link} ${themeVariable && styles.linkWhite} ${styles.hoverLink}`}>{t(`header.${el.t}`)}</Link>
                        ))}
                      </div>
                    </div>
                    <span style={{ cursor: "pointer" }} data-name={item.route} className={`${styles.link} ${themeVariable && styles.linkWhite} ${item.newYear && styles.newYear}`}>{t(`header.item12`)}</span> </span> :
                    <Link to={routes[item.route]['path']} className={`${styles.link} ${themeVariable && styles.linkWhite} ${item.newYear && styles.newYear}`}>{t(`header.${item.t}`)}</Link>}</>}
              </li>
            ))
          }
        </ul>
      </nav>

      <Link
        to={routes.search.path}
        className={`${styles.searchBtn} ${themeVariable && styles.basketBtnWhite}`}
      >
        <SearchIcon className={`${styles.basketIcon} ${themeVariable && styles.basketIconWhite}`} />
      </Link>

      <Link
        to={routes.basket.path}
        className={`${styles.basketBtn} ${themeVariable && styles.basketBtnWhite}`}
      >
        <BacketIcon className={`${styles.basketIcon} ${themeVariable && styles.basketIconWhite}`} />
        <span className={styles.spanName}>{t('header.inBasket')}:</span>&#160;<span>{basketGoods && basketGoods?.length || 0}</span>
      </Link>

      <div className={styles.btnWrap}>

        {/* <button className={ `${styles.btn} ${styles.btn_bell}` } aria-label={`Уведомления`}>
          <div className={styles.notification}></div>
        </button> */}

        <Link
          to={currentAuthStatus === AuthorizationStatus.NO_AUTH ? routes.login.path : routes.myEvents.path}
          className={`${styles.btn} ${styles.btn_auth} ${themeVariable && styles.btnWhite}`}
        >
          <AuthIcon className={`${styles.authIcon} ${themeVariable && styles.authIconWhite}`} />
          <span className="visually-hidden">{t('header.auth')}</span>
        </Link>

        <button
          onClick={handleMenuState}
          data-state={stateMenu ? `open` : `close`}
          className={`${styles.btn} ${styles.btnMenu} ${styles.burgerMenu} ${themeVariable && styles.btnWhite}`}>
          <span className={styles.spanName}>{t('header.menu')}</span>
          <span className={`${styles.burgerSpan} ${styles.burgerSpan1} ${themeVariable && styles.burgerSpanWhite}`} />
          <span className={`${styles.burgerSpan} ${styles.burgerSpan2} ${themeVariable && styles.burgerSpanWhite}`} />
          <span className={`${styles.burgerSpan} ${styles.burgerSpan3} ${themeVariable && styles.burgerSpanWhite}`} />
        </button>
      </div>
    </header>
  );
};

export default Header;
