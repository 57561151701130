import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { routes } from "const/routes";
import { ActionCreator } from "reducer/user/user";
import { ActionCreator as ValidActCreator } from "reducer/validation/validation";
import {
  getResetPopup,
  getRecoveryMailPopup,
  getVerifyPopup,
} from "reducer/user/selectors";
import styles from "./Login.module.scss";
import Header from "components/Header/Header";
import RecoveryPopup from "components/Popups/Recovery/RecoveryPopup";
import ResetPopup from "components/Popups/ResetPopup/ResetPopup";
import VerifyPopup from "components/Popups/VerifyPopup/VerifyPopup";
import SocRegList from "../Registration/SocRegList/SocRegList";
import AuthMail from "./AuthMail/AuthMail";
import AuthSms from "./AuthSms/AuthSms";
import Container from "components/App/Container/Container";
import ScrollToTop from "components/System/Scroll/ScrollToTop";
import { ReactComponent as ReactArrowSvg } from "images/svg/arrow-reg-form.svg";
// import Social from 'components/Social/Social';

const Login = () => {

  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [isRecoveryPopup, setRecoveryPopup] = useState(false);
  const [switchAuth, setSwitchAuth] = useState(true);
  const resetPopupStatus = useSelector((state) => getResetPopup(state));
  const isRecoveryMailPopup = useSelector((state) =>
    getRecoveryMailPopup(state)
  );
  const isVerifyPopup = useSelector((state) => getVerifyPopup(state));

  useEffect(() => {
    // return в useEffect - функция срабатывающая при размонтировании компонента
    // при размонтировании удаляем ошибки, убираем попапы, если они остались и тд
    return () => {
      dispatch(ValidActCreator.setLoginError(``));
      dispatch(ActionCreator.setResetPasswordPopup(false));
      dispatch(ActionCreator.setSendMailPopup(false));
      dispatch(ActionCreator.setRecoveryMailPopup(false));
      dispatch(ActionCreator.setVerifyPopup(false));
    };
  }, [dispatch]);

  const closeVerifyPopup = useCallback(() => {
    dispatch(ActionCreator.setVerifyPopup(false));
  }, [dispatch]);

  return (
    <div className="wrapper">
      {/* <Social /> */}

      <Header />

      <Container>
        <ScrollToTop />
        <section className={styles.container}>
          <h1 className={styles.title}>Авторизация</h1>

          {/* Переключение вьюх для емейла и смс */}
          {switchAuth ? (
            <AuthMail styles={styles} />
          ) : (
            <AuthSms styles={styles} />
          )}

          <div className={styles.links}>
            <button
              className={styles.link}
              onClick={() => setSwitchAuth((prev) => !prev)}
            >
              {switchAuth ? `Авторизация по смс` : `Авторизация по email`}
            </button>

            <Link className={styles.link} to={routes.registration.path}>
              Регистрация
            </Link>

            <button
              onClick={() => {
                dispatch(ValidActCreator.setLoginError(``));
                setRecoveryPopup(true);
              }}
              className={styles.link}
              type="button"
              disabled={!switchAuth}
            >
              Забыли пароль?
            </button>
          </div>

          <div className={styles.socialWrap}>
            <b className={styles.socialTitle}>Войти через соц. сети:</b>
            <SocRegList styles={styles} />
          </div>

          <button
            type="button"
            className={styles.backBtn}
            onClick={() => navigation(-1)}
          >
            <ReactArrowSvg className={styles.btnArrow} />
            Назад
          </button>
        </section>
      </Container>

      {isRecoveryPopup && (
        <RecoveryPopup
          setSwitchAuth={setSwitchAuth}
          statePopupHandler={setRecoveryPopup}
          isRecoveryMailPopup={isRecoveryMailPopup}
        />
      )}

      {resetPopupStatus && <ResetPopup />}

      {isVerifyPopup && <VerifyPopup closePopupHandler={closeVerifyPopup} />}
    </div>
  );
};

export default Login;
