import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getThemeMemReg } from 'reducer/theme/selectors';
import { ActionCreator } from '../../../reducer/theme/theme';


const WhiteTheme = () => {
  const theme = useSelector(state => getThemeMemReg(state));
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem('themeMemReg') === 'light') {
      document.body.classList.add('white');
      dispatch(ActionCreator.setThemeMemReg(true));
    } else if (localStorage.getItem('themeMemReg') === 'dark') {
      document.body.classList.remove('white');
      dispatch(ActionCreator.setThemeMemReg(false));
    }

    // if (theme) {
    //   document.body.classList.add('white');
    // }
    // return () => document.body.classList.remove('white');
  }, [theme]);

  return null;
};

export default WhiteTheme;
