import NameSpace from '../name-space';

const NAME_SPACE = NameSpace.PROFILE;

export const getActivity = (state) => state[NAME_SPACE].activity.reverse();
export const getDataStatus = (state) => state[NAME_SPACE].status;
export const getDataMessage = (state) => state[NAME_SPACE].dataMessage;
export const getPasswordMessage = (state) => state[NAME_SPACE].passwordMessage;
export const getExtraContactStatus = (state) => state[NAME_SPACE].extraContactStatus;
export const getViewCodeParam = (state) => state[NAME_SPACE].viewCodeField;
export const getChangePhoneStatus = (state) => state[NAME_SPACE].changePhoneStatus;
export const getChangeEmailStatus = (state) => state[NAME_SPACE].changeEmailStatus;
