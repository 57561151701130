import { getBrowser, nextTick } from "../utils";

export const actions = ({ carousel, params, carouselElRef, wrapperElRef }) => {
  const browser = getBrowser();
  let startX = 0,
    startY = 0,
    currentX = 0,
    currentY = 0,
    diffCurrent = 0;
  let isMoved,
    allowTouchCallbacks,
    touchStartTime,
    isScrolling,
    currentTranslate,
    startTranslate,
    allowThresholdMove,
    isTouchEvent,
    startMoving,
    swipeDirection,
    isTouched;
  let allowClick = true,
    allowTouchMove = true;
  let focusableElements =
      "input, select, option, textarea, button, video, label",
    lastClickTime = Date.now();

  const setGrabCursor = (moving) => {
    const el =
      params.touchEventsTarget === "container"
        ? carouselElRef.current
        : wrapperElRef.current;
    el.style.cursor = "move";
    el.style.cursor = moving ? "-webkit-grabbing" : "-webkit-grab";
    el.style.cursor = moving ? "-moz-grabbin" : "-moz-grab";
    el.style.cursor = moving ? "grabbing" : "grab";
  };
  const closestElement = (selector, base = this) => {
    function __closestFrom(el) {
      if (!el || el === document || el === window) return null;
      if (el.assignedSlot) el = el.assignedSlot;
      const found = el.closest(selector);
      if (!found && !el.getRootNode) {
        return null;
      }
      return found || __closestFrom(el.getRootNode().host);
    }
    return __closestFrom(base);
  };
  const onTouchStart = (event) => {
    let e = event;

    if (!carousel.current.enabled) return;

    if (carousel.current.animating && params.preventInteractionOnTransition) {
      return;
    }

    if (
      !carousel.current.animating &&
      params.loop &&
      e.type !== "pointerdown"
    ) {
      carousel.current.loopFix();
    }
    if (e.originalEvent) e = e.originalEvent;
    let targetEl = e.target;

    if (params.touchEventsTarget === "wrapper") {
      if (!wrapperElRef.current.contains(targetEl)) return;
    }

    isTouchEvent = e.type === "touchstart";
    if (!isTouchEvent && "which" in e && e.which === 3) return;
    if (!isTouchEvent && "button" in e && e.button > 0) return;
    if (isTouched && isMoved) return;

    const swipingClassHasValue =
      !!params.noSwipingClass && params.noSwipingClass !== "";

    if (
      swipingClassHasValue &&
      e.target &&
      e.target.shadowRoot &&
      event.path &&
      event.path[0]
    ) {
      targetEl = event.path[0];
    }

    const noSwipingSelector = params.noSwipingSelector
      ? params.noSwipingSelector
      : `.${params.noSwipingClass}`;
    const isTargetShadow = !!(e.target && e.target.shadowRoot);

    if (
      params.noSwiping &&
      (isTargetShadow
        ? closestElement(noSwipingSelector, targetEl)
        : targetEl.closest(noSwipingSelector))
    ) {
      allowClick = true;
      return;
    }

    if (params.swipeHandler) {
      if (!targetEl.closest(params.swipeHandler)) return;
    }

    currentX = e.type === "touchstart" ? e.targetTouches[0].pageX : e.pageX;
    currentY = e.type === "touchstart" ? e.targetTouches[0].pageY : e.pageY;
    const startXCurrent = currentX;
    const startYCurrent = currentY;

    const edgeSwipeDetection =
      params.edgeSwipeDetection || params.iOSEdgeSwipeDetection;
    const edgeSwipeThreshold =
      params.edgeSwipeThreshold || params.iOSEdgeSwipeThreshold;

    if (
      edgeSwipeDetection &&
      (startXCurrent <= edgeSwipeThreshold ||
        startXCurrent >= window.innerWidth - edgeSwipeThreshold)
    ) {
      if (edgeSwipeDetection === "prevent") {
        event.preventDefault();
      } else {
        return;
      }
    }
    isTouched = true;
    isMoved = false;
    allowTouchCallbacks = true;
    isScrolling = undefined;
    startMoving = undefined;

    startX = startXCurrent;
    startY = startYCurrent;
    touchStartTime = Date.now();
    allowClick = true;
    carousel.current.updateSize();
    swipeDirection = undefined;
    if (params.threshold > 0) allowThresholdMove = false;

    if (e.type !== "touchstart") {
      let preventDefault = true;

      if (targetEl.matches(focusableElements)) {
        preventDefault = false;

        if (targetEl.nodeName === "SELECT") {
          isTouched = false;
        }
      }

      if (
        document.activeElement &&
        document.activeElement.matches(focusableElements) &&
        document.activeElement !== targetEl
      ) {
        document.activeElement.blur();
      }

      const shouldPreventDefault =
        preventDefault && allowTouchMove && params.touchStartPreventDefault;

      if (
        (params.touchStartForcePreventDefault || shouldPreventDefault) &&
        !targetEl.isContentEditable
      ) {
        e.preventDefault();
      }
    }

    carousel.current.emit("touchStart", e);
  };
  const onTouchMove = (event) => {
    if (!carousel.current.enabled) return;
    let e = event;
    if (e.originalEvent) e = e.originalEvent;

    if (!isTouched) {
      if (startMoving && isScrolling) {
        carousel.current.emit("touchMoveOpposite", e);
      }

      return;
    }

    if (isTouchEvent && e.type !== "touchmove") return;
    const targetTouch =
      e.type === "touchmove" &&
      e.targetTouches &&
      (e.targetTouches[0] || e.changedTouches[0]);
    const pageX = e.type === "touchmove" ? targetTouch.pageX : e.pageX;
    const pageY = e.type === "touchmove" ? targetTouch.pageY : e.pageY;

    if (e.preventedByNestedCarousel) {
      startX = pageX;
      startY = pageY;
      return;
    }

    if (!allowTouchMove) {
      if (!e.target.matches(focusableElements)) {
        allowClick = false;
      }

      if (isTouched) {
        startX = pageX;
        startY = pageY;
        currentX = pageX;
        currentY = pageY;

        touchStartTime = Date.now();
      }

      return;
    }

    if (isTouchEvent && params.touchReleaseOnEdges && !params.loop) {
      if (params.direction === "vertical") {
        if (
          (pageY < startY &&
            carousel.current.translate <=
              -carousel.current.snapGrid[
                carousel.current.snapGrid.length - 1
              ]) ||
          (pageY > startY &&
            carousel.current.translate >= -carousel.current.snapGrid[0])
        ) {
          isTouched = false;
          isMoved = false;
          return;
        }
      } else if (
        (pageX < startX &&
          carousel.current.translate <=
            -carousel.current.snapGrid[carousel.current.snapGrid.length - 1]) ||
        (pageX > startX &&
          carousel.current.translate >= -carousel.current.snapGrid[0])
      ) {
        return;
      }
    }

    if (isTouchEvent && document.activeElement) {
      if (
        e.target === document.activeElement &&
        e.target.matches(focusableElements)
      ) {
        isMoved = true;
        allowClick = false;
        return;
      }
    }

    if (allowTouchCallbacks) {
      carousel.current.emit("touchMove", e);
    }

    if (e.targetTouches && e.targetTouches.length > 1) return;
    currentX = pageX;
    currentY = pageY;
    const diffX = currentX - startX;
    const diffY = currentY - startY;
    if (
      params.threshold &&
      Math.sqrt(diffX ** 2 + diffY ** 2) < params.threshold
    )
      return;

    if (typeof isScrolling === "undefined") {
      let touchAngle;

      if (
        (params.direction === "horizontal" && currentY === startY) ||
        (params.direction === "vertical" && currentX === startX)
      ) {
        isScrolling = false;
      } else {
        if (diffX * diffX + diffY * diffY >= 25) {
          touchAngle =
            (Math.atan2(Math.abs(diffY), Math.abs(diffX)) * 180) / Math.PI;
          isScrolling =
            params.direction === "horizontal"
              ? touchAngle > params.touchAngle
              : 90 - touchAngle > params.touchAngle;
        }
      }
    }

    if (isScrolling) {
      carousel.current.emit("touchMoveOpposite", e);
    }

    if (typeof startMoving === "undefined") {
      if (currentX !== startX || currentY !== startY) {
        startMoving = true;
      }
    }

    if (isScrolling) {
      isTouched = false;
      return;
    }

    if (!startMoving) {
      return;
    }

    allowClick = false;

    if (e.cancelable) {
      e.preventDefault();
    }

    if (params.touchMoveStopPropagation && !params.nested) {
      e.stopPropagation();
    }

    if (!isMoved) {
      if (params.loop) {
        carousel.current.loopFix();
      }

      startTranslate = carousel.current.getCarouselTranslate();
      carousel.current.setTransition(0);

      if (carousel.current.animating) {
        const evt = new window.CustomEvent("transitionend", {
          bubbles: true,
          cancelable: true,
        });
        wrapperElRef.current.dispatchEvent(evt);
      }

      if (
        params.grabCursor &&
        (carousel.current.allowSlideNext === true ||
          carousel.current.allowSlidePrev === true)
      ) {
        setGrabCursor(true);
      }

      carousel.current.emit("sliderFirstMove", e);
    }

    carousel.current.emit("sliderMove", e);
    isMoved = true;
    let diff = params.direction === "horizontal" ? diffX : diffY;
    diffCurrent = diff;
    diff *= params.touchRatio;
    if (carousel.current.rtlTranslate) diff = -diff;
    swipeDirection = diff > 0 ? "prev" : "next";
    currentTranslate = diff + startTranslate;
    let disableParentCarousel = true;
    let resistanceRatio = params.resistanceRatio;

    if (params.touchReleaseOnEdges) {
      resistanceRatio = 0;
    }

    if (diff > 0 && currentTranslate > -carousel.current.snapGrid[0]) {
      disableParentCarousel = false;
      if (params.resistance)
        currentTranslate =
          -carousel.current.snapGrid[0] -
          1 +
          (--carousel.current.snapGrid[0] + startTranslate + diff) **
            resistanceRatio;
    } else if (
      diff < 0 &&
      currentTranslate <
        -carousel.current.snapGrid[carousel.current.snapGrid.length - 1]
    ) {
      disableParentCarousel = false;
      if (params.resistance)
        currentTranslate =
          -carousel.current.snapGrid[carousel.current.snapGrid.length - 1] +
          1 -
          (-carousel.current.snapGrid[carousel.current.snapGrid.length - 1] -
            startTranslate -
            diff) **
            resistanceRatio;
    }

    if (disableParentCarousel) {
      e.preventedByNestedCarousel = true;
    }

    if (
      !carousel.current.allowSlideNext &&
      swipeDirection === "next" &&
      currentTranslate < startTranslate
    ) {
      currentTranslate = startTranslate;
    }

    if (
      !carousel.current.allowSlidePrev &&
      swipeDirection === "prev" &&
      currentTranslate > startTranslate
    ) {
      currentTranslate = startTranslate;
    }

    if (!carousel.current.allowSlidePrev && !carousel.current.allowSlideNext) {
      currentTranslate = startTranslate;
    }

    if (params.threshold > 0) {
      if (Math.abs(diff) > params.threshold || allowThresholdMove) {
        if (!allowThresholdMove) {
          allowThresholdMove = true;
          startX = currentX;
          startY = currentY;
          currentTranslate = startTranslate;
          diffCurrent =
            params.direction === "horizontal"
              ? currentX - startX
              : currentY - startY;
          return;
        }
      } else {
        currentTranslate = startTranslate;
        return;
      }
    }

    if (!params.followFinger) return;

    carousel.current.updateProgress(currentTranslate);

    carousel.current.setTranslate(currentTranslate);
  };
  const onTouchEnd = (event) => {
    if (["pointercancel", "pointerout", "pointerleave"].includes(event.type)) {
      const proceed =
        event.type === "pointercancel" &&
        (browser.isSafari || browser.isWebView);
      if (!proceed) {
        return;
      }
    }
    if (!carousel.current.enabled) return;
    if (!params.simulateTouch && event.pointerType === "mouse") return;

    let e = event;
    if (e.originalEvent) e = e.originalEvent;
    if (allowTouchCallbacks) {
      carousel.current.emit("touchEnd", e);
    }
    allowTouchCallbacks = false;
    if (!isTouched) {
      if (isMoved && params.grabCursor) {
        setGrabCursor(false);
      }
      isMoved = false;
      startMoving = false;
      return;
    }
    if (
      params.grabCursor &&
      isMoved &&
      isTouched &&
      (carousel.current.allowSlideNext === true ||
        carousel.current.allowSlidePrev === true)
    ) {
      setGrabCursor(false);
    }
    const touchEndTime = Date.now();
    const timeDiff = touchEndTime - touchStartTime;

    if (allowClick) {
      carousel.current.emit("tap click", e);
      if (timeDiff < 300 && touchEndTime - lastClickTime < 300) {
        carousel.current.emit("doubleTap doubleClick", e);
      }
    }

    lastClickTime = Date.now();
    nextTick(() => {
      if (!carousel.current.destroyed) allowClick = true;
    });

    if (
      !isTouched ||
      !isMoved ||
      !swipeDirection ||
      diffCurrent === 0 ||
      currentTranslate === startTranslate
    ) {
      isTouched = false;
      isMoved = false;
      startMoving = false;
      return;
    }
    isTouched = false;
    isMoved = false;
    startMoving = false;

    let currentPos;
    if (params.followFinger) {
      currentPos = carousel.current.rtlTranslate
        ? carousel.current.translate
        : -carousel.current.translate;
    } else {
      currentPos = -currentTranslate;
    }

    let stopIndex = 0;
    let groupSize = carousel.current.slidesSizesGrid[0];
    for (
      let i = 0;
      i < carousel.current.slidesGrid.length;
      i += i < params.slidesPerGroupSkip ? 1 : params.slidesPerGroup
    ) {
      const increment =
        i < params.slidesPerGroupSkip - 1 ? 1 : params.slidesPerGroup;
      if (typeof carousel.current.slidesGrid[i + increment] !== "undefined") {
        if (
          currentPos >= carousel.current.slidesGrid[i] &&
          currentPos < carousel.current.slidesGrid[i + increment]
        ) {
          stopIndex = i;
          groupSize =
            carousel.current.slidesGrid[i + increment] -
            carousel.current.slidesGrid[i];
        }
      } else if (currentPos >= carousel.current.slidesGrid[i]) {
        stopIndex = i;
        groupSize =
          carousel.current.slidesGrid[carousel.current.slidesGrid.length - 1] -
          carousel.current.slidesGrid[carousel.current.slidesGrid.length - 2];
      }
    }

    let rewindFirstIndex = null;
    let rewindLastIndex = null;
    if (params.rewind) {
      if (carousel.current.isBeginning) {
        rewindLastIndex = carousel.current.slides.length - 1;
      } else if (carousel.current.isEnd) {
        rewindFirstIndex = 0;
      }
    }

    const ratio =
      (currentPos - carousel.current.slidesGrid[stopIndex]) / groupSize;
    const increment =
      stopIndex < params.slidesPerGroupSkip - 1 ? 1 : params.slidesPerGroup;
    if (timeDiff > params.longSwipesMs) {
      if (!params.longSwipes) {
        carousel.current.slideTo(carousel.current.activeIndex);
        return;
      }
      if (swipeDirection === "next") {
        if (ratio >= params.longSwipesRatio)
          carousel.current.slideTo(
            params.rewind && carousel.current.isEnd
              ? rewindFirstIndex
              : stopIndex + increment
          );
        else carousel.current.slideTo(stopIndex);
      }
      if (swipeDirection === "prev") {
        if (ratio > 1 - params.longSwipesRatio) {
          carousel.current.slideTo(stopIndex + increment);
        } else if (
          rewindLastIndex !== null &&
          ratio < 0 &&
          Math.abs(ratio) > params.longSwipesRatio
        ) {
          carousel.current.slideTo(rewindLastIndex);
        } else {
          carousel.current.slideTo(stopIndex);
        }
      }
    } else {
      if (!params.shortSwipes) {
        carousel.current.slideTo(carousel.current.activeIndex);
        return;
      }
      if (swipeDirection === "next") {
        carousel.current.slideTo(
          rewindFirstIndex !== null ? rewindFirstIndex : stopIndex + increment
        );
      }
      if (swipeDirection === "prev") {
        carousel.current.slideTo(
          rewindLastIndex !== null ? rewindLastIndex : stopIndex
        );
      }
    }
  };
  const onResize = () => {
    if (carouselElRef.current && carouselElRef.current.offsetWidth === 0)
      return;

    carousel.current.allowSlideNext = true;
    carousel.current.allowSlidePrev = true;
    carousel.current.updateSize();
    carousel.current.updateSlides();
    carousel.current.updateSlidesClasses();

    if (
      (params.slidesPerView === "auto" || params.slidesPerView > 1) &&
      carousel.current.isEnd &&
      !carousel.current.isBeginning &&
      !params.centeredSlides
    ) {
      carousel.current.slideTo(
        carousel.current.slides.length - 1,
        0,
        false,
        true
      );
    } else {
      carousel.current.slideTo(carousel.current.activeIndex, 0, false, true);
    }
  };
  const onClick = (e) => {
    if (!carousel.current.enabled) return;

    if (!allowClick) {
      if (params.preventClicks) e.preventDefault();

      if (params.preventClicksPropagation && carousel.current.animating) {
        e.stopPropagation();
        e.stopImmediatePropagation();
      }
    }
  };
  return {
    onClick,
    onResize,
    onTouchEnd,
    onTouchMove,
    onTouchStart,
    setGrabCursor,
  };
};
