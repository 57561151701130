import { returnAuthConfig } from "utils/auth";

export const screens = {
  first: `search`, // первый поисковый экран
  result: `result`, // результат поиска - карточки
  dataMember: `dataMember`, // данные участника при поиске
  thanks: `thanks`, // благодарность после регистрации
};

const initialState = {
  screen: screens.first,
  userInfo: [],
  searchError: ``,
  popupNotice: false,
  ticketProducts: [],
  statProducts: [],
  statTickets: {},
  freeNumbers: [],
  changeUserPopup: null,
  changeNumberStatus: null,
  assignNumberStatus: null,
  fullTeams: [],
  insideTeamNumbers: [],
  swipeRegStatus: null,
  searchNumber: ``,
  isSearchNumberTicket: true,
  isOpenStat: false,
};

export const ActionType = {
  SET_SCREEN: `SET_SCREEN`,
  SET_INFO: `SET_INFO`,
  SET_ERROR: `SET_ERROR`,
  SET_POPUP: `SET_POPUP`,
  SET_PRODUCTS: `SET_PRODUCTS`,
  SET_STAT_PRODUCTS: `SET_STAT_PRODUCTS`,
  SET_STAT_TICKETS: `SET_STAT_TICKETS`,
  SET_FREE_NUMBERS: `SET_FREE_NUMBERS`,
  SET_CHANGE_USER_POPUP: `SET_CHANGE_USER_POPUP`,
  SET_ASSIGN_NUMBER_STATUS: `SET_ASSIGN_NUMBER_STATUS`,
  SET_FULL_TEAMS: `SET_FULL_TEAMS`,
  SET_INSIDE_NUMBERS: `SET_INSIDE_NUMBERS`,
  SET_SWIPE_REG_STATUS: `SET_SWIPE_REG_STATUS`,
  SET_SEARCH_NUMBER: `SET_SEARCH_NUMBER`,
  SET_IS_SEARCH_NUMBER_TICKET: `SET_IS_SEARCH_NUMBER_TICKET`,
  SET_IS_OPEN_STAT: `SET_IS_OPEN_STAT`,
};

export const ActionCreator = {
  setScreen: (screen) => ({
    type: ActionType.SET_SCREEN,
    payload: screen,
  }),

  setInfo: (info) => ({
    type: ActionType.SET_INFO,
    payload: info,
  }),

  setSearchError: (err) => ({
    type: ActionType.SET_ERROR,
    payload: err,
  }),

  setPopup: (status) => ({
    type: ActionType.SET_POPUP,
    payload: status,
  }),

  setProducts: (data) => ({
    type: ActionType.SET_PRODUCTS,
    payload: data,
  }),

  setStatProducts: (data) => ({
    type: ActionType.SET_STAT_PRODUCTS,
    payload: data,
  }),

  setStatTickets: (data) => ({
    type: ActionType.SET_STAT_TICKETS,
    payload: data,
  }),

  setFreeNumbers: (data) => ({
    type: ActionType.SET_FREE_NUMBERS,
    payload: data,
  }),

  setChangeUserPopup: (data) => ({
    type: ActionType.SET_CHANGE_USER_POPUP,
    payload: data,
  }),

  setAssignNumberStatus: (data) => ({
    type: ActionType.SET_ASSIGN_NUMBER_STATUS,
    payload: data,
  }),
  setFullTeams: (data) => ({
    type: ActionType.SET_FULL_TEAMS,
    payload: data,
  }),
  setInsideTeamNumbers: (data) => ({
    type: ActionType.SET_INSIDE_NUMBERS,
    payload: data,
  }),
  setSwipeRegStatus: (data) => ({
    type: ActionType.SET_SWIPE_REG_STATUS,
    payload: data,
  }),
  setSearchNumber: (number) => ({
    type: ActionType.SET_SEARCH_NUMBER,
    payload: number,
  }),
  setIsSearchNumberTicket: (status) => ({
    type: ActionType.SET_IS_SEARCH_NUMBER_TICKET,
    payload: status,
  }),
  setIsOpenStat: (status) => ({
    type: ActionType.SET_IS_OPEN_STAT,
    payload: status,
  }),
};

export const Operation = {
  getInfo: (data) => async (dispatch, _, api) => {
    const config = returnAuthConfig();

    const response = await api.post(`/api/volunteer/tickets`, data, config);

    if (response.status === 200) {
      dispatch(ActionCreator.setInfo(response.data.result));
      dispatch(ActionCreator.setScreen(screens.result));
    }
    // else if (response.status > 400) {
    //   dispatch(ActionCreator.setSearchError(response.data.message));
    // }
  },

  registration:
    (data, updateData, swipe = false) =>
    async (dispatch, _, api) => {
      const config = returnAuthConfig();

      if (Object.keys(updateData).length === 0) {
        const response = await api.post(
          `/api/volunteer/ticket/offline/register`,
          data,
          config
        );

        if (response.status === 200) {
          !swipe && dispatch(ActionCreator.setScreen(screens.thanks));
          if (swipe) {
            dispatch(ActionCreator.setSwipeRegStatus(true));
          }
        }
      } else {
        const response = await api.post(
          `/api/volunteer/ticket/offline/register`,
          data,
          config
        );
        const responseUpdate = await api.put(
          `/api/volunteer/ticket/offline/register`,
          updateData,
          config
        );

        if (response.status === 200 && responseUpdate.status === 200) {
          dispatch(ActionCreator.setScreen(screens.thanks));
        }
      }
    },

  cancelRegistration:
    (data, ticketData, isSwipe) => async (dispatch, _, api) => {
      const config = returnAuthConfig();
      const response = await api.post(
        `/api/volunteer/ticket/offline/cancel`,
        data,
        config
      );

      if (isSwipe && response.status === 200) {
        dispatch(ActionCreator.setSwipeRegStatus(true));
      }

      if (response.status === 200 && Object.keys(ticketData).length > 0) {
        const responseTicket = await api.post(
          `/api/volunteer/tickets`,
          ticketData,
          config
        );

        if (response.status === 200) {
          dispatch(ActionCreator.setInfo(responseTicket.data.result));
          dispatch(ActionCreator.setScreen(screens.result));
          dispatch(ActionCreator.setPopup(true));
        }
      }
    },

  searchTeamTickets:
    (event_city_public_id, number) => async (dispatch, _, api) => {
      const config = returnAuthConfig();
      const response = await api.get(
        `/api/volunteer/team/tickets/${event_city_public_id}/${number}`,
        config
      );

      if (response.status === 200) {
        dispatch(ActionCreator.setInfo(response.data.result));
        dispatch(ActionCreator.setScreen(screens.result));
      } else if (
        response.data.message === `Команда с указанным номером не существует`
      ) {
        dispatch(ActionCreator.setScreen(screens.result));
      }
    },

  loadTicketProducts:
    (event_public_id, order_public_id) => async (dispatch, _, api) => {
      const config = returnAuthConfig();
      const response = await api.get(
        `/api/volunteer/related/products/${event_public_id}/${order_public_id}`,
        config
      );

      if (response.status === 200) {
        dispatch(ActionCreator.setProducts(response.data.values));
      } else {
        dispatch(ActionCreator.setProducts([]));
      }
    },

  loadStatProducts: (event_public_id) => async (dispatch, _, api) => {
    const config = returnAuthConfig();

    const response = await api.get(
      `/api/volunteer/product/statistics/${event_public_id}`,
      config
    );

    if (response.status === 200) {
      dispatch(ActionCreator.setStatProducts(response.data.values));
    } else {
      dispatch(ActionCreator.setStatProducts([]));
    }
  },

  loadStatTickets: (event_public_id, cityId) => async (dispatch, _, api) => {
    const config = returnAuthConfig();

    const response = await api.get(
      `/api/volunteer/tickets/statistics/${event_public_id}/${cityId}`,
      config
    );

    if (Array.isArray(response.data)) {
      dispatch(ActionCreator.setStatTickets({}));
    } else {
      dispatch(ActionCreator.setStatTickets(response.data));
    }
  },

  transferTicket: (data) => async (dispatch, _, api) => {
    const config = returnAuthConfig();
    const response = await api.put(
      `/api/volunteer/tranfer/ticket/email`,
      data,
      config
    );

    if (response.status === 200) {
      dispatch(
        ActionCreator.setChangeUserPopup(
          `Билет перенесён пользователю ${data.email}`
        )
      );
    }
  },

  generateNewNumber: (data) => async (dispatch, _, api) => {
    const config = returnAuthConfig();
    const response = await api.post(
      `/api/volunteer/generate/ticket/number`,
      data,
      config
    );

    if (response.status === 200) {
      dispatch(
        ActionCreator.setAssignNumberStatus(
          `Билету присвоен новый номер: ${response.data.number}`
        )
      );
    }
  },

  getFreeNumbers: (formatPublicId) => async (dispatch, _, api) => {
    const config = returnAuthConfig();
    const response = await api.get(
      `/api/volunteer/free/numbers/${formatPublicId}/10`,
      config
    );

    if (response.status === 200) {
      dispatch(ActionCreator.setFreeNumbers(response.data.numbers));
    }
  },

  assignNewNumber: (data) => async (dispatch, _, api) => {
    const config = returnAuthConfig();
    const response = await api.post(
      `/api/volunteer/new/ticket/number`,
      data,
      config
    );

    if (response.status === 200) {
      dispatch(
        ActionCreator.setAssignNumberStatus(
          `Билету присвоен новый номер: ${response.data.number}`
        )
      );
    } /* else if (response.status === 400) {
      dispatch(ActionCreator.setAssignNumberStatus(response.data.message));
    } */
  },

  changeNumber: (data) => async (dispatch, _, api) => {
    const config = returnAuthConfig();
    const response = await api.put(
      `/api/volunteer/change/tickets/numbers`,
      data,
      config
    );

    if (response.status === 200) {
      dispatch(
        ActionCreator.setAssignNumberStatus(`Номера билетов были сменены`)
      );
    } /* else if (response.status === 400) {
      dispatch(ActionCreator.setAssignNumberStatus(response.data.message));
    } */
  },

  loadFullTeams: (formatPublicId) => async (dispatch, _, api) => {
    const config = returnAuthConfig();
    const response = await api.get(
      `/api/event_team/event/${formatPublicId}`,
      config
    );

    if (response.status === 200) {
      dispatch(ActionCreator.setFullTeams(response.data.values));
    }
  },

  changeFullTeam: (data) => async (dispatch, _, api) => {
    const config = returnAuthConfig();
    const response = await api.put(`/api/volunteer/team/ticket`, data, config);

    if (response.status === 200) {
      dispatch(ActionCreator.setAssignNumberStatus(`Команда успешно сменена`));
    } /* else if (response.status === 400) {
      dispatch(ActionCreator.setAssignNumberStatus(response.data.message));
    } */
  },

  loadInsideTeamNumbers: (publicId) => async (dispatch, _, api) => {
    const config = returnAuthConfig();
    const response = await api.get(
      `/api/volunteer/inside/team/numbers/${publicId}`,
      config
    );

    if (response.status === 200 && response.data?.free_numbers.length > 0) {
      dispatch(ActionCreator.setInsideTeamNumbers(response.data.free_numbers));
    }
  },
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.SET_SCREEN:
      return { ...state, screen: action.payload };
    case ActionType.SET_INFO:
      return { ...state, userInfo: action.payload };
    case ActionType.SET_ERROR:
      return { ...state, searchError: action.payload };
    case ActionType.SET_POPUP:
      return { ...state, popupNotice: action.payload };
    case ActionType.SET_PRODUCTS:
      return { ...state, ticketProducts: action.payload };
    case ActionType.SET_STAT_PRODUCTS:
      return { ...state, statProducts: action.payload };
    case ActionType.SET_STAT_TICKETS:
      return { ...state, statTickets: action.payload };
    case ActionType.SET_FREE_NUMBERS:
      return { ...state, freeNumbers: action.payload };
    case ActionType.SET_CHANGE_USER_POPUP:
      return { ...state, changeUserPopup: action.payload };
    case ActionType.SET_ASSIGN_NUMBER_STATUS:
      return { ...state, assignNumberStatus: action.payload };
    case ActionType.SET_FULL_TEAMS:
      return { ...state, fullTeams: action.payload };
    case ActionType.SET_INSIDE_NUMBERS:
      return { ...state, insideTeamNumbers: action.payload };
    case ActionType.SET_SWIPE_REG_STATUS:
      return { ...state, swipeRegStatus: action.payload };
    case ActionType.SET_SEARCH_NUMBER:
      return { ...state, searchNumber: action.payload };
    case ActionType.SET_IS_SEARCH_NUMBER_TICKET:
      return { ...state, isSearchNumberTicket: action.payload };
    case ActionType.SET_IS_OPEN_STAT:
      return { ...state, isOpenStat: action.payload };
    default:
      return state;
  }
};
