const initialState = {
  globalErrorMessage: undefined,
};

export const ActionType = {
  SET_GLOBAL_ERROR_MESSAGE: "SET_GLOBAL_ERROR_MESSAGE",
};

export const ActionCreator = {
  setGlobalErrorMessage: (message) => ({
    type: ActionType.SET_GLOBAL_ERROR_MESSAGE,
    payload: message,
  }),
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.SET_GLOBAL_ERROR_MESSAGE:
      return { ...state, globalErrorMessage: action.payload };
    default:
      return state;
  }
};
