const initialState = {
  theme: false,
  themeMemReg: false,
}

export const ActionType = {
  SET_THEME: `SET_THEME`,
  SET_MEM_REG: `SET_MEM_REG`,
}

export const ActionCreator = {
  setTheme: (bool) => ({
    type: ActionType.SET_THEME,
    payload: bool
  }),
  setThemeMemReg: (bool) => ({
    type: ActionType.SET_MEM_REG,
    payload: bool
  }),
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
  case ActionType.SET_THEME:
    return {...state, theme: action.payload};
  case ActionType.SET_MEM_REG:
    return {...state, themeMemReg: action.payload};
  default:
    return state;
  }
}
