export const headerList = [
  { route: `calendar`, t: `item1` },
  { route: `news`, t: `item2` },
  // { route: `https://heroleague.ru/shop`, t: `item3`, external: true },
  { route: `https://shop.heroleague.ru`, t: `item3`, external: true },
  { route: `merch`, t: `merch`, mobile: true },
  { route: `partners`, t: `item4`, mobile: true },
  /*  { route: `logistics`, t: `item13`, mobile: true }, */
  {
    route: `corporate`,
    t: `item5`,
    corporateLinks: [
      { route: `corporate`, t: `item5` },
      { route: `partners`, t: `item4` },
      { route: `logistics`, t: `item13` },
      { route: `merch`, t: `merch` },
    ],
  },
  { route: `https://career.heroleague.ru/`, t: `item9`, external: true },
  { route: `smi`, t: `item14` },
  { route: `about`, t: `item6` },
  { route: `feedback`, t: `item7` },
  { route: `clubs`, t: `clubs` },
  { route: `https://photo.heroleague.ru`, t: `photo`, external: true },
  { route: `results`, t: `item8` },
  // { route: `/newyear`, t: `item11`, newYear: true, external: true },
];

export const footerList = [
  { route: `https://shop.heroleague.ru`, t: `item1`, render: `externalLink` },
  // { route: `shop`, t: `item1`, },
  { route: `merch`, t: `merch` },
  { route: `corporate`, t: `item3` },
  { route: `partners`, t: `item6` },
  {
    route: `https://career.heroleague.ru/`,
    t: `item9`,
    render: `externalLink`,
  },
  { route: `news`, t: `item4` },

  { route: `results`, t: `item2` },
  { route: `clubs`, t: `clubs` },

  { route: `/api/files/document/pers`, t: `item7`, render: `externalLink` },
  { route: `https://faq.heroleague.ru/`, t: `item8`, render: `externalLink` },
  { route: `calendar`, t: `item5` },
  { route: `feedback`, t: `item13` },
  { route: `about`, t: `item10` },
  { route: `/about/med-spravka`, t: `item12`, render: `externalLink` },
  { render: `socialList`, t: `item14` },
];
