import React from 'react';
import carouselStyles from "../carousel.module.scss"

const CarouselPage = ({
    tag: Tag = 'div',
    children,
    className = '',
    carouselSlideIndex,
    ...rest }) => {

    return (
        <Tag
            className={`${carouselStyles.carouselSlide}${className ? ` ${className}` : ''}`}
            data-carousel-slide-index={carouselSlideIndex}
            {...rest}
        >
            {children}

        </Tag>
    )
};

export { CarouselPage }
