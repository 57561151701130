function elementStyle(el, prop) {
  return el && window.getComputedStyle(el, null)?.getPropertyValue(prop);
}
function nextTick(callback, delay) {
  if (delay === void 0) {
    delay = 0;
  }
  return setTimeout(callback, delay);
}
function getComputedStyle(el) {
  let style;

  if (window.getComputedStyle && el) {
    style = window.getComputedStyle(el, null);
  }

  if (!style && el?.currentStyle) {
    style = el.currentStyle;
  }

  if (!style) {
    style = el?.style;
  }

  return style;
}
function getTranslate(el, axis) {
  if (axis === void 0) {
    axis = 'x';
  }

  let matrix;
  let curTransform;
  let transformMatrix;
  const curStyle = getComputedStyle(el, null);

  if (window.WebKitCSSMatrix) {
    curTransform = curStyle?.transform || curStyle?.webkitTransform;

    if (curTransform?.split(',')?.length > 6) {
      curTransform = curTransform
        .split(', ')
        .map((a) => a.replace(',', '.'))
        .join(', ');
    }

    transformMatrix = new window.WebKitCSSMatrix(
      curTransform === 'none' ? '' : curTransform
    );
  } else {
    transformMatrix =
      curStyle.MozTransform ||
      curStyle.OTransform ||
      curStyle.MsTransform ||
      curStyle.msTransform ||
      curStyle.transform ||
      curStyle
        .getPropertyValue('transform')
        .replace('translate(', 'matrix(1, 0, 0, 1,');
    matrix = transformMatrix.toString().split(',');
  }

  if (axis === 'x') {
    if (window.WebKitCSSMatrix) curTransform = transformMatrix.m41;
    else if (matrix.length === 16) curTransform = parseFloat(matrix[12]);
    else curTransform = parseFloat(matrix[4]);
  }

  if (axis === 'y') {
    if (window.WebKitCSSMatrix) curTransform = transformMatrix.m42;
    else if (matrix.length === 16) curTransform = parseFloat(matrix[13]);
    else curTransform = parseFloat(matrix[5]);
  }

  return curTransform || 0;
}
function elementParents(el, selector) {
  const parents = [];
  let parent = el.parentElement;
  while (parent) {
    if (selector) {
      if (parent.matches(selector)) parents.push(parent);
    } else {
      parents.push(parent);
    }
    parent = parent.parentElement;
  }
  return parents;
}
function elementChildren(element, selector = '') {
  return (
    element?.children &&
    [...element.children].filter((el) => el.matches(selector))
  );
}
function elementNextAll(el, selector) {
  const nextEls = [];
  while (el.nextElementSibling) {
    const next = el.nextElementSibling;
    if (selector) {
      if (next.matches(selector)) nextEls.push(next);
    } else nextEls.push(next);
    el = next;
  }
  return nextEls;
}
function elementPrevAll(el, selector) {
  const prevEls = [];
  while (el.previousElementSibling) {
    const prev = el.previousElementSibling;
    if (selector) {
      if (prev.matches(selector)) prevEls.push(prev);
    } else prevEls.push(prev);
    el = prev;
  }
  return prevEls;
}

let browser;
function calcBrowser() {
  function isSafari() {
    const ua = window.navigator.userAgent.toLowerCase();
    return (
      ua.indexOf('safari') >= 0 &&
      ua.indexOf('chrome') < 0 &&
      ua.indexOf('android') < 0
    );
  }

  return {
    isSafari: isSafari(),
    isWebView: /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(
      window.navigator.userAgent
    ),
  };
}

function getBrowser() {
  if (!browser) {
    browser = calcBrowser();
  }

  return browser;
}
let support;

function calcSupport() {
  return {
    smoothScroll:
      document.documentElement &&
      'scrollBehavior' in document.documentElement.style,
    touch: !!(
      'ontouchstart' in window ||
      (window.DocumentTouch && document instanceof window.DocumentTouch)
    ),
    passiveListener: (function checkPassiveListener() {
      let supportsPassive = false;

      try {
        const opts = Object.defineProperty({}, 'passive', {
          get() {
            supportsPassive = true;
          },
        });
        window.addEventListener('testPassiveListener', null, opts);
      } catch (e) {}

      return supportsPassive;
    })(),
    gestures: (function checkGestures() {
      return 'ongesturestart' in window;
    })(),
  };
}
function getSupport() {
  if (!support) {
    support = calcSupport();
  }

  return support;
}
let deviceCached;

function calcDevice(_temp) {
  let { userAgent } = _temp === void 0 ? {} : _temp;
  const support = getSupport();
  const platform = window.navigator.platform;
  const ua = userAgent || window.navigator.userAgent;
  const device = {
    ios: false,
    android: false,
  };
  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;
  const android = ua.match(/(Android);?[\s\/]+([\d.]+)?/);

  let ipad = ua.match(/(iPad).*OS\s([\d_]+)/);
  const ipod = ua.match(/(iPod)(.*OS\s([\d_]+))?/);
  const iphone = !ipad && ua.match(/(iPhone\sOS|iOS)\s([\d_]+)/);
  const windows = platform === 'Win32';
  let macos = platform === 'MacIntel';

  const iPadScreens = [
    '1024x1366',
    '1366x1024',
    '834x1194',
    '1194x834',
    '834x1112',
    '1112x834',
    '768x1024',
    '1024x768',
    '820x1180',
    '1180x820',
    '810x1080',
    '1080x810',
  ];

  if (
    !ipad &&
    macos &&
    support.touch &&
    iPadScreens.indexOf(`${screenWidth}x${screenHeight}`) >= 0
  ) {
    ipad = ua.match(/(Version)\/([\d.]+)/);
    if (!ipad) ipad = [0, 1, '13_0_0'];
    macos = false;
  }

  if (android && !windows) {
    device.os = 'android';
    device.android = true;
  }

  if (ipad || iphone || ipod) {
    device.os = 'ios';
    device.ios = true;
  }
  return device;
}

function getDevice(overrides) {
  if (overrides === void 0) {
    overrides = {};
  }

  if (!deviceCached) {
    deviceCached = calcDevice(overrides);
  }

  return deviceCached;
}

export {
  getSupport,
  getDevice,
  getBrowser,
  elementStyle,
  elementNextAll,
  elementPrevAll,
  elementChildren,
  elementParents,
  nextTick,
  getTranslate,
  getComputedStyle,
};
