import { combineReducers } from '@reduxjs/toolkit';
import { reducer as user } from './user/user';
import { reducer as validation } from './validation/validation';
import { reducer as network } from './network/network';
import { reducer as profile } from './profile/profile';
import { reducer as payment } from './payment/payment';
import { reducer as data } from './data/data';
import { reducer as basket } from './basket/basket';
import { reducer as tickets } from './tickets/tickets';
import { reducer as shop } from './shop/shop';
import { reducer as partners } from './partners/partners';
import { reducer as subscribe } from './subscribe/subscribe';
import { reducer as theme } from './theme/theme';
import { reducer as member_reg } from './memberRegistration/registration';
import { reducer as location } from './location/location';
import { reducer as orders } from './orders/orders';
import { reducer as results } from './results/results';
import { reducer as booked } from './booked/booked';
import { reducer as globalErrors } from './globalError/globalError';
import { bannerSlice } from './banners';
import { feedbackSlice } from './feedback';
import NameSpace from './name-space';

export default combineReducers({
  [NameSpace.USER]: user,
  [NameSpace.VALIDATION]: validation,
  [NameSpace.NETWORK_REG]: network,
  [NameSpace.PROFILE]: profile,
  [NameSpace.PAYMENT]: payment,
  [NameSpace.DATA]: data,
  [NameSpace.BASKET]: basket,
  [NameSpace.TICKETS]: tickets,
  [NameSpace.SHOP]: shop,
  [NameSpace.PARTNERS]: partners,
  [NameSpace.SUBSCRIBE]: subscribe,
  [NameSpace.THEME]: theme,
  [NameSpace.MEMBER_REG]: member_reg,
  [NameSpace.LOCATION]: location,
  [NameSpace.ORDERS]: orders,
  [NameSpace.RESULTS]: results,
  [NameSpace.BOOKED]: booked,
  [NameSpace.GLOBAL_ERRORS]: globalErrors,
  [bannerSlice.reducerPath]: bannerSlice.reducer,
  [feedbackSlice.reducerPath]: feedbackSlice.reducer,
});
