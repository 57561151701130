import { getTranslate } from "../utils";

export const translate = ({ carousel, wrapperElRef, params }) => {

  const setTranslate = (currentTranslate, byController) => {

    let x = 0;
    let y = 0;
    const z = 0;

    if (params.direction === 'horizontal') {
      x = carousel.current.rtlTranslate ? -currentTranslate : currentTranslate;
    } else {
      y = currentTranslate;
    }

    if (params.roundLengths) {
      x = Math.floor(x);
      y = Math.floor(y);
    }

    if (!params.virtualTranslate && wrapperElRef.current) {
      wrapperElRef.current.style.transform = `translate3d(${x}px, ${y}px, ${z}px)`;
    }

    carousel.current.translate = params.direction === 'horizontal' ? x : y;

    let newProgress;
    const translatesDiff = -carousel.current.snapGrid[carousel.current.snapGrid.length - 1] - -carousel.current.snapGrid[0];

    if (translatesDiff === 0) {
      newProgress = 0;
    } else {
      newProgress = (currentTranslate - -carousel.current.snapGrid[0]) / translatesDiff;
    }

    if (newProgress !== carousel.current.progress) {
      carousel.current.updateProgress(currentTranslate);
    }

    carousel.current.emit('setTranslate', carousel.current.translate, byController);
  }
  const getCarouselTranslate = (axis) => {
    if (axis === void 0) {
      axis = params.direction === 'horizontal' ? 'x' : 'y';
    }

    if (params.virtualTranslate) {
      return carousel.current.rtlTranslate ? -carousel.current.translate : carousel.current.translate;
    }

    let currentTranslate = getTranslate(wrapperElRef.current, axis);
    if (carousel.current.rtlTranslate) currentTranslate = -currentTranslate;
    return currentTranslate || 0;
  }
  return { getCarouselTranslate, setTranslate }
}
