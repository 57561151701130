import React, { useRef, useEffect } from 'react';
import { CarouselPage } from './CarouselPage';
import { anyActions } from './modules/anyActions';
import { core } from './modules/core';
import { DEFAULT_FUNCTION, DEFAULT_PARAMS } from './const';
import carouselStyles from "./carousel.module.scss"

export const Carousel = (props) => {

    const {
        onCarousel,
        className,
        children,
        thumb,
        styles,
        tag: Tag = 'div',
        wrapperTag: WrapperTag = 'ul',
        carouselClass = carouselStyles.carousel,
        wrapperClass = carouselStyles.carouselWrapper,
        slideClass = carouselStyles.carouselSlide,
        slideVisibleClass = carouselStyles.carouselSlideVisible,
        slideActiveClass = carouselStyles.carouselSlideActive,
        slideNextClass = carouselStyles.carouselSlideNext,
        slidePrevClass = carouselStyles.carouselSlidePrev,
        paginationBulletClass = styles.pag,
        paginationBulletActiveClass = styles.pag_active,
        thumbClass = styles.thumb,
        breakpoints
    } = props
    const params = { ...DEFAULT_PARAMS, ...props }

    const carousel = useRef({
        ...DEFAULT_FUNCTION,
        transEnd: null, destroyed: false, isEnd: false,
        animating: false, isBeginning: true, activeIndex: 0,
        previousIndex: null, initialized: null, width: 0, height: 0,
        slidesGrid: [], translate: 0, allowSlideNext: true, allowSlidePrev: true,
        rtlTranslate: null, slidesSizesGrid: [], slides: React.Children.toArray(children),
        enabled: true, size: 0, progress: 0, snapGrid: [], slideClass, slideActiveClass, slideNextClass,
        slidePrevClass, wrongRTL: null, loopedSlides: 0, paginationBulletClass, paginationBulletActiveClass,
        mounted: false, slideVisibleClass, eventsListeners: {}, children, thumbClass, breakpoints
    })
    const carouselElRef = useRef(null), wrapperElRef = useRef(null), paginationEl = useRef(null)
    const settings = { carousel, params, carouselElRef, wrapperElRef, thumb, paginationEl }

    const { init, destroy, renderLoop } = core(settings)
    const { handlePrevClick, handleNextClick, handlePaginationClick } = anyActions(settings)

    useEffect(() => {
        if (onCarousel && carousel.current) {
            onCarousel(carousel.current)
        }
        init()
        return () => destroy(true, false);
    }, []);

    const renderSlides = () => {
        if (!params.loop || carousel.current.destroyed) {
            return React.Children.toArray(children).map(child => React.cloneElement(child));
        }
        return renderLoop(children);
    }

    return (
        <>
            <Tag
                ref={carouselElRef}
                className={`${carouselClass}${className ? ` ${className}` : ''}`}
            >
                <WrapperTag ref={wrapperElRef} className={wrapperClass}>
                    {renderSlides()}
                </WrapperTag>

                {params.isPagination && <div>
                    <span
                        className={styles.pagination}
                        ref={paginationEl}
                    >
                        {children.map((_, i) =>
                            <span data-pagination-index={i}
                                onClick={() => handlePaginationClick(i)}
                                className={paginationBulletClass} key={i}>
                            </span>
                        )}</span>
                </div>}
            </Tag>

            {params.isNavigation && (
                <div className={styles.nav} >
                    <button
                        onClick={handlePrevClick}
                        className={styles.btnPrev}
                    />
                    <button
                        onClick={handleNextClick}
                        className={styles.btnNext}
                    />
                </div>
            )}
        </>
    );

};
Carousel.Page = CarouselPage

