import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Operation as DataOperation } from "../../reducer/data/data";
import { Operation as OrdersOperation } from "../../reducer/orders/orders";
import { getAllOrders } from "reducer/orders/selectors";
import { routes } from "const/routes";
import useWindowDimensions from '../../customHooks/useWindowDimensions';
import styles from '../MyOrders/MyOrders.module.scss';
import Container from "components/App/Container/Container";
import OrderCard from "./OrderCard/OrderCard";

const MyOrders = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();

  // const user = JSON.parse(localStorage.getItem('userObj'))
  const orders = useSelector(state => getAllOrders(state));

  useEffect(() => {
    dispatch(OrdersOperation.loadOrders());
    dispatch(DataOperation.loadEvents());
  }, [dispatch]);

  return (
    <>
      {/*      <Header /> */}

      <Container>
        {/*   <Breadcrumbs props={props} /> */}

        {/*    <div className={styles.titleWrap}>
          <div className={styles.buttons}>
            <Link className={styles.link} to={routes.myEvents.path}>
              <span>{t("lk.title")}</span>
            </Link>
            <Link className={`${styles.link} ${styles.linkActive}`} to={routes.myOrders.path}>
              <span>{t("lk.goods")}</span>
            </Link>
            <Link className={`${styles.link} ${styles.settingsLink}`} to={routes.personal.path}>
              <img className={styles.icon} src={GearIcon} alt="Шестеренка" />
              <span>{width > '768' ? t("lk.settings") : ''}</span>
            </Link>
          </div>
        </div> */}

        <h2 className={styles.title}>Товары</h2>

        {orders.length === 0 ? (
          <div className={styles.notExistBlock}>
            <h3 className={styles.notExistTitle}>{t("lk.orders.nothingOrders")}</h3>
            <Link to={routes.shop.path} className={styles.notExistLink}>
              {t("lk.orders.shopBtn")}
            </Link>
          </div>
        ) : (
          <>
            {orders.length ? (
              <section className={styles.unregEvents}>
                <h3 className={styles.label}>{t("lk.orders.myOrders")}</h3>
                <ul className={styles.list}>
                  {orders.map((item) => (
                    <OrderCard
                      item={item}
                      key={item.public_id}
                      styles={styles}
                    />
                  ))}
                </ul>
              </section>
            ) : null}
          </>
        )}

        {/*  <Similar style={styles.similarWrap} /> */}

      </Container>

      {/*  <Footer /> */}
    </>
  );
};

export default MyOrders;
