import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "../Goods.module.scss";
import { ActionCreator } from "reducer/basket/basket";
import { getDeliveryRegions, getEventGoodsById, getSizesById } from "reducer/data/selectors";
import { Operation as BasketOperation } from "reducer/basket/basket";
import { Operation as TicketOperation } from "reducer/tickets/tickets";
import { ReactComponent as SaleLogo } from "images/basket/promocode.svg";
import { ReactComponent as CheckLogo } from "images/basket/check.svg";
import { ReactComponent as WarnLogo } from "images/basket/warnlogo.svg";
import Message from "components/Auth/Registration/Message/Message";
import { checkAgeLimit, updateLocalStorage } from "utils/utils";
import { getAllTickets } from "reducer/tickets/selectors";
import { useValidationText } from "customHooks/useValidation";
import { useValidationPhone } from "customHooks/useValidationPhone";
import { getDate, useValidationDate } from "customHooks/useValidationDate";
import CustomSelect from "components/Calendar/Select/Select";
import { convertBase64 } from "utils/convertBase64";
import { setCount } from "mocks/calendarOptions";
import "../../../Calendar/Select/Select.scss";
import { searchDeliveryRegion } from "utils/data";
import Input from "components/Forms/Input/Input";
import Datepicker from "components/Forms/Datepicker/Datepicker";
import InputPhone from "components/Forms/Input/InputPhone";
import Radio from "components/Forms/Radio/Radio";
import { ReactComponent as ReactLogoName } from "images/profile/profile-name.svg";
import { ReactComponent as DateLogo } from "images/profile/profile-calendar.svg";
import basketStyles from "../../Basket.module.scss";
import { useTranslation } from "react-i18next";
import { getPromocodeErrorByType, getPromocodeSaleByType } from "reducer/payment/selectors";
import { ActionCreator as PaymentActionCreator } from "reducer/payment/payment";
import { Operation } from "reducer/payment/payment";

const GoodsForm = React.memo(({ item, activeTab, handleChange, isLoading, isAddInsurance }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const itemRegions = item.regions ?? null;
  const formatCluster = item.clusters ?? [];
  const promocode = useSelector((state) => getPromocodeSaleByType(state, item));
  const promocodeError = useSelector((state) =>
    getPromocodeErrorByType(state, item)
  );
  const [userObj] = useState(localStorage.getItem("userObj") ? JSON.parse(localStorage.getItem("userObj")) : {});
  const regions = useSelector((state) => getDeliveryRegions(state)) ?? [];
  const [cluster, setCluster] = useState(null);
  const [isLoadFile, setIsLoadFile] = useState(false);
  const [file, setFile] = useState(null);
  const [isErrors, setErrors] = useState({
    date: ``,
    phone: ``,
    gender: ``,
    file: "",
    size: "",
    region: "",
    license: ""
  });
  const [promocodeApplied, setPcApplied] = useState(false);
  const [document, setDocument] = useState(null);
  const [licenseFile, setLicenseFile] = useState(item.licenseFile);
  const [license, setLicense] = useState(item.license);
  const [region, setRegion] = useState(null);
  const [additionalValue, setAdditionalValue] = useState({});
  const sizesById = useSelector((state) => getSizesById(state, `${item.event?.public_id}${item.event_city?.public_id}`));
  const fields = item.additional_fields || [];
  const sizesTshirt = sizesById?.sizes || [];
  const eventGoods = useSelector((state) => getEventGoodsById(state, item.event_city?.public_id)) ?? null;;
  const allTickets = useSelector((state) => getAllTickets(state));
  const userName = item.info?.first_name || userObj?.name || "";
  const userLastName = item.info?.last_name || userObj?.last_name || "";
  const userBirthDate = item.info?.birth_date || userObj?.birth_date && getDate(userObj.birth_date * 1000) || "";
  const userSecondName = item.info?.second_name || userObj?.second_name || "";
  const [gender, setGender] = useState(item.info?.gender || userObj?.gender || `none`);
  const [size, setSize] = useState(null);
  const [place, setPlace] = useState(null);
  const [filteredRegions, setFilteredRegions] = useState([]);
  const promocodeRef = useRef();
  const [isSecondName, setIsSecondName] = useState(item.insurance || item.second_name);

  const [
    name,
    isValidName,
    nameError,
    setNameError,
    handleNameChange,
    handleNameFocus,
    setNameValue,
  ] = useValidationText(userName);

  const [valueDate, handleDateChange, date, isValidDate, setValueDate] =
    useValidationDate(userBirthDate);

  const [
    lastName,
    isValidLastName,
    lastNameError,
    setLastNameError,
    handleLastNameChange,
    handleLastNameFocus,
    setLastName,
  ] = useValidationText(userLastName);

  const [
    secondName,
    isValidSecondName,
    secondNameError,
    setSecondNameError,
    handleSecondNameChange,
    handleSecondNameFocus,
    setSecondName,
  ] = useValidationText(userSecondName);

  const handleFileChange = (file, document) => {
    setDocument(document);

    setFile(file);
    setErrors({ ...isErrors, file: '' });
  };

  const handleLicenseChange = (file, document) => {

    setLicense(document);

    setLicenseFile(file);
    setErrors({ ...isErrors, license: '' });
  };

  const handleFocus = (evt) => {
    const { name } = evt.target;
    setErrors((prev) => ({ ...prev, [name]: '' }));
  };
  const handleFileFocus = () => setErrors({ ...isErrors, file: '' });
  const handleLicenseFocus = () => setErrors({ ...isErrors, license: '' });
  const handleClusterResetError = () =>
    setErrors((prev) => ({ ...prev, cluster: "" }));
  const handleRadioChange = (evt) => {
    setErrors((prev) => ({ ...prev, gender: "" }));
    setGender(evt.target.value);
  };
  const handleDateFocus = () => setErrors({ ...isErrors, date: '' });

  const [phone, isValidTel, handlePhoneChange, , setPhone] = useValidationPhone(
    item.info?.phone && `+${item.info.phone}` || userObj?.phone && `+${userObj?.phone}`
  );
  const isCountSizes = sizesTshirt.length > 0 && !!sizesTshirt.find((el) => el.count > 0);

  const usedTicket = allTickets.find(
    (el) =>
      el.event_format.public_id === item.public_id && item.selector === "ticket"
  );

  const isVipUser = userObj?.role && typeof userObj?.role !== "string" && userObj.role.find((el) => el === "vip"); // Проверка относится ли user к категории vip

  useEffect(() => {
    if (item.select && item.select_field && item.select.values && item.select.values.length) {
      const defaultPlace = item.select.values.find((el) => el.value === item.select_field.name);
      setPlace(defaultPlace);
    }
  }, []);

  useEffect(() => {
    if (regions?.length && item.info?.region && !region) {
      setRegion(searchDeliveryRegion(regions, item.info.region, "region"));
    }
  }, [regions]);

  useEffect(() => {
    let filteredRegions = [];
    if (
      item.regions &&
      item.regions?.length > 0 &&
      regions.length > 0
    ) {
      item.regions?.forEach((el) => {
        filteredRegions.push(searchDeliveryRegion(regions, el, "region"));
      });
    } else if (item.regions) {
      filteredRegions = regions.map((el) => {
        return { id: el.id, label: el.name_ru, value: el.id };
      });
    }
    setFilteredRegions(filteredRegions);
  }, [regions]);

  useEffect(() => {
    setPcApplied(!!(promocode && promocode.active));
  }, [promocode, item]);

  useEffect(() => {
    if (eventGoods) {
      const newObj = { ...item, eventGoods: eventGoods?.data };

      dispatch(ActionCreator.updateGoods(newObj));
      updateLocalStorage('basketGoods', 'add', newObj);
    }
  }, [eventGoods]);

  useEffect(() => {
    if (item.info?.item_size && sizesTshirt?.length > 0) {
      const size = sizesTshirt.find((el) => el.size === item.info.item_size);

      setSize({ label: size?.size, value: size?.count });
    } else if (item.defaultSize && sizesTshirt?.length > 0) {
      setSize(item.defaultSize);
    }

  }, [sizesTshirt]);



  useEffect(() => {
    if (item.cluster?.document) {
      setDocument(item.cluster?.document);
    }
    if (item.file) {
      setFile({ name: item.file });
    }
  }, []);

  useEffect(() => {

    if (formatCluster.length > 0 && item.cluster) {
      const defaultCluster = formatCluster.find((el) => el.value === item.cluster?.name);

      if (defaultCluster) {
        setCluster(defaultCluster);
      }
    }

  }, [formatCluster, item.cluster]);

  useEffect(() => {
    if (formatCluster.length > 0 && cluster) {
      const currentCluster = formatCluster.find((el) => el.value === cluster.value);
      setIsLoadFile((prev) => prev !== currentCluster.file ? currentCluster.file : prev);
    }
  }, [cluster]);

  const handlePromocodeCheck = () => {
    const value = promocodeRef.current.value;

    if (value.length > 0) {
      const codeInfo = { code: value, ...item };

      dispatch(Operation.promocode(codeInfo));

      if (codeInfo.selector === "shop") {
        updateLocalStorage('basketGoods', 'add', {
          ...codeInfo,
          promocode: value,
        });
      }
    } else {
      dispatch(
        PaymentActionCreator.setPromocodeError({
          message: 'Введите промокод',
          ...item,
        })
      );
    }
  };

  // смена промокода - по сути удаление из Store
  const handlePromocodeChange = () => {
    dispatch(PaymentActionCreator.removePromocodes(item));
  };

  const promocodeFocusHandler = () => {
    // delete message
    dispatch(PaymentActionCreator.removePromocodeError(item));
  };

  useEffect(() => {
    if (!isLoading) {
      const newObj = {
        ...item,
        isErrorTab: true,
        preReg: ""
      };

      dispatch(ActionCreator.updateGoods(newObj));

      let additional_fields = [];
      let sizeError = "";
      let regionError = "";
      let placeError = "";

      if (item.additional_fields && fields.length) {
        additional_fields = fields.map((el) => {
          const name = el.name;
          if (!additionalValue[`${name}`] && el.required) {
            setErrors((prev) => ({ ...prev, [name]: 'Заполните поле' }));
          } else {
            setErrors((prev) => ({ ...prev, [name]: '' }));
          }
          return {
            value: additionalValue[name],
            name,
            required: el.required,
          };
        });
      }
      const filteredFields = additional_fields.filter((el) => {
        if (el.required && el?.value?.length) return true;
        if (!el.required) return true;
        return false;
      }) ?? [];

      if (size === null && sizesTshirt.length > 0 && isCountSizes && !item.shirt_unavailable) {
        setErrors((prev) => ({ ...prev, size: 'Выберите размер' }));
        sizeError = true;
      } else {
        sizeError = false;
        setErrors((prev) => ({ ...prev, size: '' }));
      }
      if (!isValidName) {
        setNameError('Заполните поле');
      } else {
        setNameError("");
      }
      if (item.select && place === null) {
        setErrors((prev) => ({ ...prev, place: 'Выберите место получения стартового номера' }));
        placeError = true;
      } else {
        placeError = false;
        setErrors((prev) => ({ ...prev, place: '' }));
      }
      if (!isValidLastName) {
        setLastNameError('Заполните поле');
      } else {
        setLastNameError("");
      }
      if (!isSecondName) {
        setSecondName("");
      }
      if (!region && itemRegions) {
        setErrors((prev) => ({ ...prev, region: 'Выберите регион' }));
        regionError = true;
      } else {
        regionError = false;
        setErrors((prev) => ({ ...prev, region: '' }));
      }

      if (!isValidDate && !date) {
        setErrors((prev) => ({ ...prev, date: 'Введите корректную дату' }));
      } else {
        setErrors((prev) => ({ ...prev, date: '' }));
      }
      if (!isValidTel) {
        setErrors((prev) => ({ ...prev, phone: 'Заполните поле' }));
      } else {
        setErrors((prev) => ({ ...prev, phone: '' }));
      }
      if (gender === 'none') {
        setErrors((prev) => ({
          ...prev,
          gender: 'Выберите одно из значений',
        }));
      }

      if (item?.age_min && checkAgeLimit(date, item.age_min, null, item.start_time)) {

        setErrors((prev) => ({
          ...prev,
          date: `Участие старше ${item.age_min}`,
        }));
        return;
      }

      if (item?.age_max && checkAgeLimit(date, null, item.age_max, item.start_time)) {

        setErrors((prev) => ({
          ...prev,
          date: `Участие младше ${item.age_max}`,
        }));
        return;

      }

      if (formatCluster.length > 0) {
        if (!cluster) {
          setErrors((prev) => ({ ...prev, cluster: 'Выберите кластер' }));
          return;
        } else {
          setErrors((prev) => ({ ...prev, cluster: '' }));
        }
        if (!document && isLoadFile) {
          setErrors((prev) => ({ ...prev, file: 'Выберите файл' }));
          return;
        } else {
          setErrors((prev) => ({ ...prev, file: '' }));
        }
      }

      if (item.license_file && !license) {
        setErrors((prev) => ({ ...prev, license: 'Выберите файл' }));
        return;
      } else {
        setErrors((prev) => ({ ...prev, license: '' }));
      }

      if (isValidName &&
        isValidLastName &&
        isValidDate &&
        date &&
        isValidTel &&
        gender !== 'none' &&
        filteredFields.length === fields.length &&
        !sizeError &&
        !regionError &&
        !placeError) {

        let newObj = {
          ...item,
          preReg: "ok"
        };
        delete newObj.info;

        const formattedDate = date?.toISOString();

        let info = {
          first_name: name,
          last_name: lastName,
          phone: phone?.slice(1),
          gender,
          birth_date: formattedDate,
        };

        if (item.regions) {
          if (region) {
            info = {
              ...info,
              region: region.id,
            };
          }
        }

        if (secondName) {
          info = {
            ...info,
            second_name: secondName,
          };
        }
        if (additional_fields?.length > 0) {
          additional_fields.forEach((el) => {
            info[`${el?.name}`] = el?.value;
          });
        }

        if (size && size?.label !== "Футболка не нужна" || item.info?.item_size && size?.label !== "Футболка не нужна" && !item.shirt_unavailable) {
          info = {
            ...info,
            item_size: size?.label || item.info?.item_size,
          };
        } else if (size?.label === "Футболка не нужна") {
          newObj = {
            defaultSize: { value: "defaultvalue", label: "Футболка не нужна" },
            ...newObj,
            info
          };
          delete info?.info?.item_size;
          updateLocalStorage('basketGoods', 'add', newObj);
          handleChange();
        }

        if (item.select && place?.value) {
          newObj = {
            ...newObj,
            select_field: { name: place?.value }
          };
        }

        if (item.license_file && license) {
          newObj = {
            ...newObj,
            license: license
          };
        }

        if (formatCluster.length > 0 && isLoadFile) {
          newObj = {
            ...newObj,
            file: file?.name,
            cluster: {
              name: cluster && cluster.value,
              document
            }
          };
        }
        else if (formatCluster.length > 0 && !isLoadFile) {
          newObj = {
            ...newObj,
            cluster: {
              name: cluster && cluster.value
            }
          };
        }

        newObj = {
          ...newObj,
          isErrorTab: false
        };

        newObj = {
          ...newObj,
          info
        };

        updateLocalStorage('basketGoods', 'add', newObj);
        dispatch(ActionCreator.updateGoods(newObj));

        handleChange();
      }
    }
  }, [name, place, formatCluster.length, isLoading, license, isValidDate, region, additionalValue, size, isCountSizes, lastName, secondName, phone, gender, valueDate, date, isSecondName, file, cluster, document, isLoadFile]);

  useEffect(() => {
    if (isVipUser && !allTickets.length) {
      dispatch(TicketOperation.loadTickets());
    }
  }, [isVipUser, allTickets, dispatch]);


  useEffect(() => {
    if (item.delivery) {
      if (item.selector === "shop") {
        dispatch(
          BasketOperation.getDeliveryByItemType(item.type)
        );
      } else {
        dispatch(
          BasketOperation.getDeliveryByItemType(item.selector)
        );
      }
    }
  }, []);

  useEffect(() => {
    if (fields?.length) {
      fields.forEach((field) => {
        setAdditionalValue(prev => ({ ...prev, [field.name]: item.info?.[`${field.name}`] }));
      });
    }
  }, []);

  const handleSelectChange = (value) => {
    setIsLoadFile((prev) => prev !== value.file ? value.file : prev);
    setCluster(value);
  };

  const handleSizeResetError = () => {
    setErrors((prev) => ({ ...prev, size: "" }));
    /*   dispatch(ActionCreator.registrationMessage("")) */
  };

  const handleAdditionalFiledFocus = (name) => {
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleAdditionalField = (event) => {
    setAdditionalValue(prev => ({ ...prev, [event.target.name]: event.target.value }));


    let newInfo = { ...item.info, [event.target.name]: event.target.value };

    /* updateLocalStorage('basketGoods', 'add', { ...item, info: newInfo }); */
    dispatch(ActionCreator.updateGoods({ ...item, info: newInfo }));
  };

  //update to validation

  const handleFirstNameChange = (e) => {
    handleNameChange(e);

    dispatch(ActionCreator.updateGoods({ ...item, info: { ...item.info, first_name: e.target.value } }));
  };

  const handleLastNameChanges = (e) => {
    handleLastNameChange(e);

    dispatch(ActionCreator.updateGoods({ ...item, info: { ...item.info, last_name: e.target.value } }));
  };

  const handleSecondNameChanges = (e) => {
    handleSecondNameChange(e);

    dispatch(ActionCreator.updateGoods({ ...item, info: { ...item.info, second_name: e.target.value } }));
  };

  const handlePhoneChanges = (e) => {

    if (e) {
      handlePhoneChange(e);

      dispatch(ActionCreator.updateGoods({ ...item, info: { ...item.info, phone: e?.slice(1) } }));
    } else {

      dispatch(ActionCreator.updateGoods({ ...item, info: { ...item.info, phone: "" } }));
    }
  };

  const handleChangeSize = (e) => {
    setSize(e);

    if (e.label) {
      dispatch(ActionCreator.updateGoods({ ...item, info: { ...item.info, item_size: e.label } }));
    }
  };

  const handleChangeRegion = (e) => {
    setRegion(e);

    if (e) {
      dispatch(ActionCreator.updateGoods({ ...item, info: { ...item.info, region: e.id } }));
    }
  };

  const handleDateChanges = (e) => {
    handleDateChange(e);

    if (e.target.value && e.target.value.length >= 10) {

      const newDate = e.target.value.split('.').reverse().join('-');

      if (new Date(newDate).toLocaleDateString('en-GB') !== 'Invalid Date') {
        const formattedDate = new Date(newDate).toISOString();

        dispatch(ActionCreator.updateGoods({ ...item, info: { ...item.info, birth_date: formattedDate } }));
      }
    } else {
      dispatch(ActionCreator.updateGoods({ ...item, info: { ...item.info, birth_date: " " } }));
    }
  };

  const handleRadioChanges = (e) => {
    handleRadioChange(e);

    dispatch(ActionCreator.updateGoods({ ...item, info: { ...item.info, gender: e.target.value } }));
  };

  const hadleClusterChange = (e) => {
    handleSelectChange(e);

    dispatch(ActionCreator.updateGoods({ ...item, cluster: { ...item.cluster, name: e.value, } }));
  };
  const handleLicenseChanges = async (e) => {

    const file = e.target.files[0];
    const maxFileSize = 4 * 1024 * 1024; // 4 MB

    if (file && file.size > maxFileSize) {
      setErrors({ ...isErrors, license: 'Размер файла больше допустимого!' });

      const newObj = {
        ...item,
        isErrorTab: true,
        preReg: ""
      };

      dispatch(ActionCreator.updateGoods(newObj));

    } else if (file && file.size < maxFileSize) {
      const document = await convertBase64(e.target.files[0]);

      dispatch(ActionCreator.updateGoods({ ...item, license: document, licenseFile: { name: e.target.files[0]?.name } }));

      setErrors({ ...isErrors, license: '' });
      handleLicenseChange(e.target.files[0], document);
    }

  };

  const handleClusterDocumentChange = async (e) => {
    const file = e.target.files[0];
    const maxFileSize = 4 * 1024 * 1024; // 4 MB

    if (file && file.size > maxFileSize) {
      setErrors({ ...isErrors, file: 'Размер файла больше допустимого!' });

      const newObj = {
        ...item,
        isErrorTab: true,
        preReg: ""
      };

      dispatch(ActionCreator.updateGoods(newObj));

    } else if (e.target.files[0]) {
      const document = await convertBase64(e.target.files[0]);

      dispatch(ActionCreator.updateGoods({ ...item, cluster: { ...item.cluster, document }, file: e.target.files[0]?.name }));

      handleFileChange(e.target.files[0], document);
    }
  };

  const handleChangePlace = (e) => {
    setPlace(e);

    dispatch(ActionCreator.updateGoods({ ...item, select_field: { name: e.value } }));
  };

  return (
    <>
      <div className={styles.ticketForm}>

        <div style={{ display: "flex", gap: "30px", flexWrap: "wrap" }}>
          <p className={styles.formText}>Необходимо заполнить обязательные данные участника</p></div>

        <div className={styles.formWrap}>
          <div className={styles.wrapperForm}>
            <Input
              firstLetterUpperCase
              error={nameError}
              handleFieldChange={handleFirstNameChange}
              handleFocus={handleNameFocus}
              Logo={ReactLogoName}
              label={'Ваше имя'}
              name={'name'}
              id={'ticket-name'}
              value={name}
            />

            <Input
              firstLetterUpperCase
              error={lastNameError}
              handleFieldChange={handleLastNameChanges}
              handleFocus={handleLastNameFocus}
              Logo={ReactLogoName}
              label={'Ваша Фамилия'}
              name={'lastName'}
              id={'ticket-lastName'}
              value={lastName}
            />

            {isSecondName &&
              <Input
                handleFieldChange={handleSecondNameChanges}
                handleFocus={handleSecondNameFocus}
                error={secondNameError}
                Logo={ReactLogoName}
                label={'Ваше Отчество'}
                name={'secondNname'}
                id={'ticket-secondName'}
                placeholder={'Если есть'}
                defaultValue={secondName}
              />}

            <InputPhone
              country="RU"
              outsideStyle={styles}
              international={true}
              withCountryCallingCode={true}
              error={isErrors.phone}
              label={"Введите номер телефона:"}
              id="ticket-basket-phone"
              type="tel"
              name="phone"
              value={phone}
              onFocus={handleFocus}
              onChange={handlePhoneChanges}
            />

            {sizesTshirt?.length > 0 && !item.shirt_unavailable && (
              <div className={styles.cluster}>
                <CustomSelect
                  error={isErrors.size}
                  handleSelectChange={handleChangeSize}
                  handleFocus={handleSizeResetError}
                  value={size || ""}
                  prefix={"basketsize-select"}
                  styles={styles}
                  title={"Размер футболки"}
                  name={"size"}
                  options={setCount(sizesTshirt, "size")}
                  isOptionDisabled={(option) => option.disabled}
                  placeholder={"Выберите ваш размер"}
                  closeMenuOnScroll={() => false}
                />
              </div>
            )}

            {itemRegions && regions?.length ? (
              <CustomSelect
                error={isErrors.region}
                handleSelectChange={handleChangeRegion}
                handleFocus={() => setErrors((prev) => ({ ...prev, region: "" }))}
                value={region || ""}
                prefix={"ticket-select"}
                styles={styles}
                title={"Регион проживания"}
                name={"region"}
                options={filteredRegions}
                placeholder={"Выберите ваш регион"}
                closeMenuOnScroll={() => false}
              />
            ) : null}

            {fields?.length ? fields.map((field) => (
              <Input
                key={field.name}
                handleFocus={() =>
                  handleAdditionalFiledFocus(field.name)
                }
                handleFieldChange={handleAdditionalField}
                error={isErrors[`${field.name}`]}
                label={field.label}
                name={field.name}
                id={`basket-${field.name}`}
                defaultValue={additionalValue?.[field.name]}
              />
            )) : null}

            <Datepicker
              error={isErrors.date}
              value={valueDate}
              handleDateChange={handleDateChanges}
              handleDateFocus={handleDateFocus}
              Logo={DateLogo}
              label={'День рождения'}
              id={'birth_date'}
            />

            <div className={styles.radioWrapper}>
              <p
                className={styles.genderTitle}

              >
                Ваш пол:
              </p>
              <ul className={styles.genderList}>
                <li className={styles.genderItem}>
                  <Radio
                    error={isErrors.gender}
                    initialValue={'male'}
                    value={gender}
                    name={item.unique_id}
                    id={`${item.event.public_id}${item.event_city.public_id}${item.format_public_id}${item.unique_id}`}
                    label={'Мужской'}
                    handleRadioChange={handleRadioChanges}
                  />
                </li>
                <li className={styles.genderItem}>
                  <Radio
                    error={isErrors.gender}
                    initialValue={'female'}
                    value={gender}
                    name={item.unique_id}
                    id={`${item.format_public_id}${item.event_city.public_id}${item.event.public_id}${item.unique_id}`}
                    label={'Женский'}
                    handleRadioChange={handleRadioChanges}
                  />
                </li>
              </ul>
              {isErrors?.gender?.length ? (
                <Message message={isErrors.gender} styles={styles} />
              ) : null}
            </div>

            {formatCluster?.length > 0 && (
              <>
                <CustomSelect
                  error={isErrors.cluster}
                  handleSelectChange={hadleClusterChange}
                  handleFocus={handleClusterResetError}
                  value={cluster || ""}
                  prefix={"cluster-select"}
                  styles={styles}
                  title={"Кластер*"}
                  name={"cluster"}
                  options={formatCluster}
                  placeholder={"Выберите ваш кластер"}
                  closeMenuOnScroll={() => false}
                />

                {isLoadFile && <label className={styles.labelFile}>Подтверждение*
                  <span className={`${styles.textFile} ${isErrors.file && styles.labelFileError}`}>
                    Загрузить документ
                    <input className={styles.inputFile}
                      accept="image/pdf, image/jpeg, image/png , image/jpg"
                      type="file"
                      onChange={handleClusterDocumentChange}
                      name="file"
                      onFocus={handleFileFocus}
                    />
                  </span>
                  {isErrors.file.length ? (
                    <span>{isErrors.file}</span>
                  ) : null}
                  {file && file.name || item.fileName ? (
                    <span style={{ maxWidth: "300px", overflow: "hidden" }}>{file?.name || item.fileName}</span>
                  ) : null}
                </label>}
              </>
            )}

            {item.license_file && <label className={styles.labelFile}>Лицензия*
              <span className={`${styles.textFile} ${isErrors.license && styles.labelFileError}`}>
                Загрузить документ
                <input className={styles.inputFile}
                  accept="image/pdf, image/jpeg, image/png , image/jpg"
                  type="file"
                  onChange={handleLicenseChanges}
                  name="license"
                  onFocus={handleLicenseFocus}
                />
              </span>
              <span>{isErrors.license}</span>
              {licenseFile && licenseFile.name ? (
                <span style={{ maxWidth: "300px", overflow: "hidden" }}>{licenseFile?.name}</span>
              ) : null}
            </label>}

            {item.select && (
              <div className={styles.cluster}>
                <CustomSelect
                  error={isErrors.place}
                  handleSelectChange={handleChangePlace}
                  handleFocus={() => setErrors((prev) => ({ ...prev, place: "" }))}
                  value={place || ""}
                  prefix={"baskets-place-select"}
                  styles={styles}
                  title={item.select.title}
                  name={"place"}
                  options={item.select.values.length ? item.select.values : []}
                  placeholder={"Выберите значение"}
                  closeMenuOnScroll={() => false}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {item.notes?.length > 0 ? <div className={styles.row}>
        <ul className={styles.notesWrapper}>
          {item.notes.map((el, i) => (
            <li key={i} className={styles.notes}>
              {el}
            </li>
          ))}
        </ul>
      </div> : null}
      <div className={styles.addBlock}>

        <div className={`${styles.promocodeBtnWrap} ${promocodeError && !!promocodeError.message ? styles.promocodeBtnWrapError : ``}
          ${promocodeApplied ? styles.promocodeBtnWrapCheck : ``}`}>
          <div className={styles.promocodeWrap}>
            <SaleLogo className={styles.inputLogo} />
            <input
              className={styles.promocodeField}
              type="text"
              placeholder={t("basket.good.promocode.placeholder")}
              id={item.public_id}
              disabled={
                promocodeApplied ||
                (item.selector === "ticket" &&
                  usedTicket === undefined &&
                  isVipUser) ||
                item.selector === "certificate"
              }
              ref={promocodeRef}
              onChange={() => { }}
              onFocus={promocodeFocusHandler}
              defaultValue={promocode && promocode.code}
            />

            {promocodeError && !!promocodeError.message && (
              <WarnLogo className={styles.checkLogo} />
            )}
            {promocodeApplied && <CheckLogo className={styles.checkLogo} />}
          </div>
          <button
            disabled={
              (item.selector === "ticket" &&
                usedTicket === undefined &&
                isVipUser) ||
              item.selector === "certificate"
            }
            className={styles.promocodeBtn}
            onClick={
              !promocodeApplied ? handlePromocodeCheck : handlePromocodeChange
            }
          >
            {!promocodeApplied
              ? t("basket.good.promocode.btnApply")
              : t("basket.good.promocode.btnChange")}
          </button>

          {promocodeError && !!promocodeError.message ? (
            <Message styles={styles} message={promocodeError.message} />
          ) : null}
        </div>

        <p className={basketStyles.certificate}>
          {t("basket.aside.ref")}
          <span className={basketStyles.certificateHelp}>
            Описание для мед справки
          </span>
        </p>
      </div>
    </>
  );
});

export default GoodsForm;
