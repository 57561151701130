import { elementParents } from "../utils";

export const observer = ({ params, wrapperElRef, carouselElRef, carousel }) => {
  const observers = [];
  const attach = (target, options = {}) => {
    const ObserverFunc = window.MutationObserver || window.WebkitMutationObserver;
    const observer = new ObserverFunc((mutations) => {
      if (mutations.length === 1) {
        carousel.current.emit('observerUpdate', mutations[0]);
        return;
      }

      const observerUpdate = function observerUpdate() {
        carousel.current.emit('observerUpdate', mutations[0]);
      };

      if (window.requestAnimationFrame) {
        window.requestAnimationFrame(observerUpdate);
      } else {
        window.setTimeout(observerUpdate, 0);
      }
    });

    observer.observe(target, {
      attributes: typeof options.attributes === 'undefined' ? true : options.attributes,
      childList: typeof options.childList === 'undefined' ? true : options.childList,
      characterData: typeof options.characterData === 'undefined' ? true : options.characterData,
    });

    observers.push(observer);
  };
  const init = () => {
    if (!params.observer) return;
    if (params.observeParents) {
      const containerParents = elementParents(carouselElRef.current);
      for (let i = 0; i < containerParents.length; i += 1) {
        attach(containerParents[i]);
      }
    }
    attach(carouselElRef.current, {
      childList: params.observeSlideChildren,
    });

    attach(wrapperElRef.current, { attributes: false });
  };
  const destroy = () => {
    observers.forEach((observer) => {
      observer.disconnect();
    });
    observers.splice(0, observers.length);
  };
  carousel.current.on('init', init);
  carousel.current.on('destroy', destroy);
}
