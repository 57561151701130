import rootReducer from '../reducer/reducer';
import loggerMiddleware from './middlewares/logger';
import { configureStore } from '@reduxjs/toolkit';
import { bannerSlice } from 'reducer/banners';
import { feedbackSlice } from 'reducer/feedback';
import { api } from 'index';

export default function configurateStore(preloadedState) {
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: api,
        },
      })
        .concat(bannerSlice.middleware)
        .concat(feedbackSlice.middleware)
        .concat(loggerMiddleware),
    devTools: process.env.NODE_ENV === 'development',
    preloadedState,
  });

  return store;
}
