export const stylesMerge = (defaultStyles, outsideStyles) => {
  const newStyles = {...defaultStyles, ...outsideStyles};

  if (outsideStyles) {
    for (const key in defaultStyles) {
      for (const keyTwo in outsideStyles) {
        if (key === keyTwo) {
          newStyles[key] = `${outsideStyles[keyTwo]} ${defaultStyles[key]}`;
        }
      }
    }
  }

  return newStyles;
};

export const getClasses = (classes) => {
  const currentClasses = [classes.defaultClass];
  if (classes.errorClass) currentClasses.push(classes.errorClass);
  if (classes.validClass) currentClasses.push(classes.validClass);
  if (classes.disabledClass) currentClasses.push(classes.disabledClass);
  return currentClasses;
};
