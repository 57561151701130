import { updateLocalStorage } from 'utils/utils';
import { returnAuthConfig } from 'utils/auth';
import { ActionCreator as ValidActionCreator } from 'reducer/validation/validation';
import { MESSAGES } from 'const/messages';
import { backendLogger } from 'utils/logger';

const initialState = {
  activity: [],
  dataMessage: ``,
  passwordMessage: false,
  status: false,
  extraContactStatus: false,
  viewCodeField: false,
  changePhoneStatus: ``,
  changeEmailStatus: ``,
};

export const ActionType = {
  LOAD_ACTIVITY: `LOAD_ACTIVITY`,
  SET_NEW_DATA: `SET_NEW_DATA`,
  SET_ERROR: `SET_ERROR`,
  SET_STATUS: `SET_STATUS`,
  SET_PASSWORD_MESSAGE: `SET_PASSWORD_MESSAGE`,
  SET_EXTRA_CONTACT_STATUS: `SET_EXTRA_CONTACT_STATUS`,
  SET_VIEW_CODE_FIELD: `SET_VIEW_CODE_FIELD`,
  CHANGE_PHONE_STATUS: `CHANGE_PHONE_STATUS`,
  CHANGE_EMAIL_STATUS: `CHANGE_EMAIL_STATUS`,
};

export const ActionCreator = {
  loadActivity: (sessions) => ({
    type: ActionType.LOAD_ACTIVITY,
    payload: sessions,
  }),

  setNewData: (data) => ({
    type: ActionType.SET_NEW_DATA,
    payload: data,
  }),

  setMessage: (error) => ({
    type: ActionType.SET_ERROR,
    payload: error,
  }),

  setStatus: (status) => ({
    type: ActionType.SET_STATUS,
    payload: status,
  }),

  setPasswordMessage: (bool) => ({
    type: ActionType.SET_PASSWORD_MESSAGE,
    payload: bool,
  }),

  setExtraContactStatus: (bool) => ({
    type: ActionType.SET_EXTRA_CONTACT_STATUS,
    payload: bool,
  }),

  setViewCodeField: (bool) => ({
    type: ActionType.SET_VIEW_CODE_FIELD,
    payload: bool,
  }),

  changePhoneStatus: (string) => ({
    type: ActionType.CHANGE_PHONE_STATUS,
    payload: string,
  }),

  changeEmailStatus: (string) => ({
    type: ActionType.CHANGE_EMAIL_STATUS,
    payload: string,
  }),
};

export const Operation = {
  setNewData: (data) => async (dispatch, getState, api) => {
    try {
      const config = returnAuthConfig();
      const response = await api.put('/api/user', data, config);

      updateLocalStorage('userObj', 'update', data);

      if (data.name || data.birth_date || data.last_name || data.second_name) {
        dispatch(ActionCreator.setStatus(response.data.message));
        dispatch(ActionCreator.setMessage('Данные успешно обновлены'));
      }
      if (data.extra_contact) {
        dispatch(ActionCreator.setExtraContactStatus(response.data.message));
      }
    } catch (e) {
      console.error(e);
    }
  },

  setNewMail: (email) => async (dispatch, getState, api) => {
    const config = returnAuthConfig();
    const response = await api.post(`/api/user/change/email`, email, config);

    if (response.status === 200) {
      dispatch(ActionCreator.changeEmailStatus(MESSAGES.EMAIL_NOT_CONFIRM));
    } else {
      dispatch(ValidActionCreator.setLoginError(response.data.message));
    }
  },

  sendNewPhoneCode: (phone) => async (dispatch, getState, api) => {
    const config = returnAuthConfig();
    const response = await api.post(`/api/user/phone/code`, phone, config);

    if (response.status === 200) {
      // view code field
      dispatch(ActionCreator.setViewCodeField(response.data.message));
      dispatch(ActionCreator.changePhoneStatus(MESSAGES.PHONE_NOT_CONFIRM));
    } else if (response.status >= 400) {
      dispatch(ValidActionCreator.setTelError(response.data.message));
    }
  },

  setNewPhone: (data) => async (dispatch, getState, api) => {
    const config = returnAuthConfig();
    const response = await api.post(`/api/user/new/phone`, data, config);

    if (response.status === 200) {
      dispatch(ActionCreator.setViewCodeField(false));
      updateLocalStorage(`userObj`, `update`, { phone: data.phone });
      dispatch(ActionCreator.changePhoneStatus(MESSAGES.PHONE_CONFIRM)); // view update phone text
    } else if (response.status >= 400) {
      dispatch(ValidActionCreator.setCodeError(response.data.message));
    }
  },

  setNewPassword: (data) => async (dispatch, getState, api) => {
    const config = returnAuthConfig();
    const response = await api.put(`/api/user/password`, data, config);

    if (response.status === 200) {
      dispatch(ActionCreator.setPasswordMessage(response.data.message));
    } else if (response.status >= 400) {
      dispatch(ValidActionCreator.setPassError(response.data.message));
      /* backendLogger(response); */
    }
  },

  loadActivity:
    (data = ``) =>
    async (dispatch, getState, api) => {
      const config = returnAuthConfig();
      const response = await api.post(`/api/auth/history`, data, config);

      if (response.status === 200) {
        dispatch(ActionCreator.loadActivity(response.data.values));
      } /* else if (response.status >= 400) {
      backendLogger(response);
    } */
    },
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.LOAD_ACTIVITY:
      return { ...state, activity: action.payload };
    case ActionType.SET_ERROR:
      return { ...state, dataMessage: action.payload };
    case ActionType.SET_STATUS:
      return { ...state, status: action.payload };
    case ActionType.SET_PASSWORD_MESSAGE:
      return { ...state, passwordMessage: action.payload };
    case ActionType.SET_EXTRA_CONTACT_STATUS:
      return { ...state, extraContactStatus: action.payload };
    case ActionType.SET_VIEW_CODE_FIELD:
      return { ...state, viewCodeField: action.payload };
    case ActionType.CHANGE_PHONE_STATUS:
      return { ...state, changePhoneStatus: action.payload };
    case ActionType.CHANGE_EMAIL_STATUS:
      return { ...state, changeEmailStatus: action.payload };
    default:
      return state;
  }
};
