import NameSpace from "../name-space";

const NAME_SPACE = NameSpace.USER;

export const getCurrentAuthStatus = (state) =>
  state[NAME_SPACE].authorizationStatus;
export const getSendingForm = (state) => state[NAME_SPACE].isSendingForm;
export const getRedirectStatus = (state) => state[NAME_SPACE].redirectStatus;
export const getInitCode = (state) => state[NAME_SPACE].initCode;
export const getSecureCode = (state) => state[NAME_SPACE].redirectSecureCode;
export const getResetPopup = (state) => state[NAME_SPACE].resetPopupStatus;
export const getSendMailPopup = (state) => state[NAME_SPACE].isSendMailPopup;
export const getVerifyPopup = (state) => state[NAME_SPACE].isVerifyPopup;
export const getStatusBlockSms = (state) => state[NAME_SPACE].isBlockSms;
export const getViewConfirmBtn = (state) => state[NAME_SPACE].viewConfirmBtn;
export const getRecoveryMailPopup = (state) =>
  state[NAME_SPACE].isRecoveryMailPopup;
export const getIsRealEmail = (state) => state[NAME_SPACE].isRealEmail;
export const getIsLoadCheckRealEmail = (state) =>
  state[NAME_SPACE].isLoadCheckRealEmail;
