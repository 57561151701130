import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Operation,
  ActionCreator as ProfileActionCreator,
} from "reducer/profile/profile";
import { ActionCreator } from "reducer/validation/validation";
import {
  getPasswordError,
  getNewPasswordError,
} from "reducer/validation/selectors";
import { ReactComponent as PasswordIcon } from "images/svg/form-key.svg";
import InputPass from "components/Forms/Input/InputPass";
import { getPasswordMessage } from "reducer/profile/selectors";
import { useTranslation } from "react-i18next";
// import { resetSelect } from 'customHooks/resetSelect';

const Password = ({ styles }) => {
  const dispatch = useDispatch();
  const passwordRef = useRef();
  const passwordNewRef = useRef();
  const messageRef = useRef();
  const formRef = useRef();
  const passwordError = useSelector((state) => getPasswordError(state));
  const newPasswordError = useSelector((state) => getNewPasswordError(state));
  const passwordMessage = useSelector((state) => getPasswordMessage(state));
  const [data, setData] = useState({ password: ``, new_password: `` });
  const [isValidPass, setValidPass] = useState(false);
  const [isValidNewPass, setValidNewPass] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    let timer;
    if (passwordMessage) {
      timer = setTimeout(() => {
        dispatch(ProfileActionCreator.setPasswordMessage(false));
      }, 5000);
    }

    return () => {
      if (timer) clearTimeout(timer);
      dispatch(ActionCreator.setPassError(``));
      dispatch(ActionCreator.setNewPassError(``));
    };
  }, [dispatch, passwordMessage]);

  const handleChange = (evt) => {
    const { value, name } = evt.target;
    setData({ ...data, [name]: value });
  };

  const handlePasswordChange = (evt) => {
    const { value } = evt.target;
    handleChange(evt);
    setValidPass(value.length > 0);
  };

  // ф-ция проверки нового пароля - валидность
  const handleNewPasswordChange = (evt) => {
    const { value } = evt.target;
    handleChange(evt);
    setValidNewPass(false);

    if (value.length < 4 && value.length !== 0) {
      dispatch(ActionCreator.setNewPassError(t("lk.password.condP")));
    } else if (value.length === 0) {
      dispatch(ActionCreator.setNewPassError(``));
    } else {
      dispatch(ActionCreator.setNewPassError(``));
      setValidNewPass(true);
    }
  };

  const handleFocus = (evt) => {
    const { name } = evt.target;
    switch (name) {
      case `password`:
        dispatch(ActionCreator.setPassError(``));
        break;
      case `new_password`:
        dispatch(ActionCreator.setNewPassError(``));
        break;
      default:
        break;
    }
  };

  // ф-ция отправки данных на сервер ( `/api/user/password` )
  // проверка валидности старого и нового паролей
  // отправка данных и сброс значений в полях - formRef.current.reset();
  const handlePasswordSubmit = (evt) => {
    evt.preventDefault();

    if (isValidPass && isValidNewPass) {
      dispatch(Operation.setNewPassword(data));
      setData({ password: ``, new_password: `` });
      formRef.current.reset();
    } else {
      // ошибки при невалидных значениях
      if (!isValidPass && !isValidNewPass) {
        dispatch(ActionCreator.setPassError(t("lk.password.correctP")));
        dispatch(ActionCreator.setNewPassError(t("lk.password.correctP")));
      } else {
        if (!isValidPass) {
          dispatch(ActionCreator.setPassError(t("lk.password.correctP")));
        } else if (!isValidNewPass) {
          dispatch(ActionCreator.setNewPassError(t("lk.password.correctP")));
        }
      }
    }
  };

  return (
    <form
      className={styles.formsWrap}
      onSubmit={handlePasswordSubmit}
      ref={formRef}
    >
      <div className={styles.formsWrapGrid}>
        <InputPass
          error={passwordError}
          handleFieldChange={handlePasswordChange}
          handleFocus={handleFocus}
          Logo={PasswordIcon}
          label={t("lk.password.oldP")}
          name={`password`}
          id={`profile-password`}
          ref={passwordRef}
        />

        <InputPass
          error={newPasswordError}
          handleFieldChange={handleNewPasswordChange}
          handleFocus={handleFocus}
          Logo={PasswordIcon}
          label={t("lk.password.newP")}
          name={`new_password`}
          id={`profile-password-new`}
          ref={passwordNewRef}
        />

        <button className={styles.settingBtn} type="submit">
          {t("lk.password.btnChange")}
        </button>

        {passwordMessage ? (
          <p
            className={styles.message}
            ref={messageRef}
          >{`Пароль успешно изменен!`}</p>
        ) : null}
      </div>
    </form>
  );
};

export default Password;
