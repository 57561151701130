import axios from 'axios';
import { routes } from 'const/routes';
import { api, store } from 'index';
import { ActionCreator } from 'reducer/globalError/globalError';
import { returnAuthConfig } from 'utils/auth';

export const REACT_APP_API =
  process.env.REACT_APP_API || window.location.origin;
const TIMEOUT = 10000;

const Error = {
  UNAUTHORIZED: 401,
  INVALID_TOKEN: 403,
};

export const createAPI = () => {
  const api = axios.create({
    baseURL: REACT_APP_API,
    timeout: TIMEOUT,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });

  const onSuccess = (response) => response;

  const onFail = (err) => {
    const { response } = err;

    if (!response) throw err;
    else if (response.status === 400) {
      store.dispatch(
        ActionCreator.setGlobalErrorMessage(response.data.message)
      );
    } else if (
      response.status === 500 ||
      response.status === 502 ||
      response.status === 422
    ) {
      store.dispatch(
        ActionCreator.setGlobalErrorMessage(
          'Произошла ошибка, попробуйте позже'
        )
      );
    } else if (
      response.status === Error.UNAUTHORIZED ||
      response.status === Error.INVALID_TOKEN
    ) {
      if (response.status === Error.INVALID_TOKEN)
        localStorage.removeItem('token');
      window.location.href = routes.login.path;
      // document.location.reload(true);
      throw err;
    } else if (response.data) return response;

    // Если запрос вообще неудачный - бросить ошибку
    throw err;
  };

  // Установка заголовка Авторизации при существующем токене
  // const createSetAuthInterceptor = options => config => {
  //   if (options) {
  //     config.headers.Authorization = options;
  //   } else {
  //     delete config.headers.Authorization;
  //   }
  //   return config;
  // }

  // const setAuthCb = createSetAuthInterceptor(localStorage.getItem('token'));

  // api.interceptors.request.use(setAuthCb); // перехватчик запроса
  api.interceptors.response.use(onSuccess, onFail); // перехватчик ответа

  return api;
};

export const axiosBaseQuery = () => async (props) => {
  const { url, method, data, params } = props;

  let result;
  try {
    if (typeof props === 'string') {
      result = await api({
        url: props,
        method: 'GET',
        headers: returnAuthConfig().headers,
      });
    } else {
      result = await api({
        url,
        method,
        data,
        params,
        headers: returnAuthConfig().headers,
      });
    }

    return { data: result.data };
  } catch (axiosError) {
    const err = axiosError;
    return {
      error: {
        status: err.response?.status,
        data: err.response?.data || err.message,
      },
    };
  }
};
