import { routes } from "const/routes";
import { ActionCreator as UserActionCreator } from "../user/user";
import { AuthorizationStatus } from "const/const";
import { returnAuthConfig } from "utils/auth";

const initialState = {
  token: ``,
  authError: ``,
};

export const ActionType = {
  SET_TOKEN: `SET_TOKEN`,
  SET_AUTH_ERROR: `SET_AUTH_ERROR`,
};

export const ActionCreator = {
  setToken: (token) => ({
    type: ActionType.SET_TOKEN,
    payload: token,
  }),

  setError: (bool) => ({
    type: ActionType.SET_AUTH_ERROR,
    payload: bool,
  }),
};

export const Operation = {
  regSocial: (id, data, navigate) => async (dispatch, getState, api) => {
    const response = await api.post(`/api/user/${id}`, data);

    if (response.status === 200) {
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("userObj", JSON.stringify(response.data.user));
      dispatch(UserActionCreator.setAuthorization(AuthorizationStatus.AUTH));
      navigate(routes.myEvents.path);
    } /* else if (response.status >= 400) {
      dispatch(ActionCreator.setError(`${response.data.message}`));
    } */
  },

  addAuthSocial: (id, data, navigate) => async (dispatch, _, api) => {
    const config = returnAuthConfig();
    const response = await api.post(`/api/user/add/${id}`, data, config);

    if (response.status === 200) {
      localStorage.setItem("userObj", JSON.stringify(response.data.user));
      navigate(routes.myEvents.path);
    } /* else if (response.status >= 400) {
      dispatch(ActionCreator.setError(`${response.data.message}`));
    } */
  },
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.SET_TOKEN:
      return { ...state, token: action.payload };
    case ActionType.SET_AUTH_ERROR:
      return { ...state, authError: action.payload };
    default:
      return state;
  }
};
