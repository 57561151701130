import { REGEX_MAIL } from 'const/const';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ActionCreator } from 'reducer/validation/validation';


export const useValidationMail = (initState = ``, errorName = `setLoginError`) => {
  const [email, setEmail] = useState(initState);
  const [isValidMail, setValidMail] = useState(false);
  const dispatch = useDispatch();

  const handleInputChange = (evt) => {
    const { value } = evt.target;
    const testValue = REGEX_MAIL.test(value);
    setEmail(value.toLowerCase());
    setValidMail(testValue);

    if (!testValue) {
      dispatch(ActionCreator[errorName](`Введите валидный email`));
    }
    if (testValue || value === ``) {
      dispatch(ActionCreator[errorName](``));
    }
  }

  const handleFocus = (evt) => {
    evt.target.classList.remove('error-reg');
    dispatch(ActionCreator[errorName](``));
  }

  return [email, isValidMail, handleInputChange, handleFocus, setValidMail];
}
