export const VALIDATION_ERROR_MESSAGES = {
  isRequired: 'Заполните поле',
  numberMustBePositive: 'Значение должно быть больше 0',
  numberMustBeInteger: 'Значение должно быть целым числом',
  size: 'Выберите размер',
  fileSize: 'Размер изображения слишком большой',
  fileUpload: 'Загрузите файл',
  fileFormat: 'Неподдерживаемый формат',
  address: 'Введите корректный адрес',
  index: 'Введите корректный Индекс',
  indexCorrect: 'Попробуйте другой индекс',
  time: 'Введите корректное время',
  date: 'Введите корректную дату',
};

export const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

export const MAX_SIZE_IMAGE = 2097152;
//2097152 = 2mb

export const DEFAULT_INDEX_LENGTH = 6;

export const ALL_FIELDS = {
  distance: 'Дистанция',
  comments: 'Примечание',
  status: 'Статус',
  time_start: 'Время старта',
  time_finish: 'Время финиша',
  position_absolute: 'Место чистое',
  position_gen_absolute: 'Место по полу чистое',
  position_cat_absolute: 'Место в категории чистое',
  last_name: 'Фамилия',
  category: 'Категория',
  gender: 'Пол',
  first_name: 'Имя',
  start_number: 'Номер',
  absolute_time_result: 'Время чистое',
  individual_time_result: 'Время официальное',
  fine_time_result: 'Штрафное время',
  time_result: 'Время',
  position: 'Место',
  position_gen: 'Место по полу',
  position_age: 'Место по возрасту',
  position_cat: 'Место по категории',
  position_gen_age: 'Место по полу и возрасту',
};
