export const initDate = new Date();
const currentYear = initDate.getFullYear();
const currentMonth = initDate.getMonth(); // 0, 1, 2 ...
const currentDay = initDate.getDate();
export let nextTwoYear = new Date();
nextTwoYear.setFullYear(nextTwoYear.getFullYear() + 1);
nextTwoYear.setDate(nextTwoYear.getDate() - 1);

export let prevTwoYear = new Date();
prevTwoYear.setFullYear(prevTwoYear.getFullYear() - 1);
prevTwoYear.setDate(prevTwoYear.getDate() - 1);

export const months = [
  `Январь`,
  `Февраль`,
  `Март`,
  `Апрель`,
  `Май`,
  `Июнь`,
  `Июль`,
  `Август`,
  `Сентябрь`,
  `Октябрь`,
  `Ноябрь`,
  `Декабрь`,
];

export const monthsFull = months.map((month, i) => ({
  id: i,
  date_from: new Date(
    i >= currentMonth ? currentYear : currentYear + 1,
    i,
    i === currentMonth ? currentDay : 1
  ), // дата начала месяца
  date_to: new Date(
    i >= currentMonth ? currentYear : currentYear + 1,
    i + 1,
    0,
    23,
    59,
    59
  ), // дата конца месяца
  title: month,
}));

export const dateFormation = (object, selector) => {
  const { monthNum, year } = object;
  const condition = monthNum >= currentMonth && year === currentYear;
  const actualYear = condition ? currentYear : currentYear + 1;

  if (selector === `from`) {
    return new Date(
      actualYear,
      monthNum,
      monthNum === currentMonth && year === currentYear ? currentDay : 1
    );
  } else {
    return new Date(actualYear, monthNum + 1, 0, 23, 59, 59);
  }
};

const getCurrentMonth = (months) => {
  const index = new Date().getMonth();
  const first = months.slice(0, index - 1);
  const second = months.slice(index);
  return second.concat(first);
};

export const sortedMonths = getCurrentMonth(monthsFull);
export const dateFormationResult = (object, selector) => {
  const { monthNum, year } = object;
  const condition = monthNum >= currentMonth && year === currentYear;
  const actualYear = condition ? currentYear : currentYear - 1;

  if (selector === `from`) {
    return new Date(actualYear, monthNum, 1);
  } else {
    return new Date(actualYear, monthNum + 1, 0, 23, 59, 59);
  }
};
