import Select, { components } from "react-select";
import { ReactComponent as ArrowIcon } from "images/svg/arrow-select-icon.svg";
import { customStyles, customTheme } from "components/Calendar/Select/custom";

const CustomSelect = ({
  options,
  Logo = null,
  styles,
  error = '',
  disabled = false,
  handleSelectChange = () => { },
  handleFocus = () => { },
  defVal = '',
  value,
  prefix = 'prefix',
  customArrow = null,
  title = '',
  name = '',
  placeholder = '',
  isSearch = false,
  customS = customStyles,
  customT = customTheme,
  closeMenuOnScroll = (param = true) => param,
  ...rest
}) => {
  // custom components
  const ControlComponent = (props) => (
    <div
      className={`${styles.selector} ${error.length ? styles.selectorError : ``
        } ${disabled ? styles.selectorDisabled : ``}`}
    >
      {<b className={styles.filter}>{title}</b>}
      {customArrow ? <components.Control {...props}>
        <ArrowIcon style={{ width: "11px" }} />
        {props.children}
      </components.Control> : <components.Control {...props} />}
      {error.length !== 0 ? (
        <p className={styles.messageError}>{error}</p>
      ) : null}
    </div>
  );

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <ArrowIcon />
      </components.DropdownIndicator>
    );
  };

  return (
    <div className={styles.inputWrap}>
      {Logo && (
        <Logo
          className={`${styles.selectIcon} ${error && styles.selectIconError}`}
        />
      )}
      <Select
        {...rest}
        onFocus={handleFocus}
        onChange={handleSelectChange}
        value={value}
        className={`${prefix}-container`}
        defaultValue={defVal}
        classNamePrefix={prefix}
        isSearchable={isSearch}
        components={{ Control: ControlComponent, DropdownIndicator: customArrow ? null : DropdownIndicator }}
        name={name}
        options={options}
        placeholder={placeholder}
        styles={customS}
        theme={customT}
        defaultMenuIsOpen={false}
        closeMenuOnScroll={closeMenuOnScroll}
      />
    </div>
  );
};

export default CustomSelect;
