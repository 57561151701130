const NameSpace = {
  DATA: 'DATA',
  USER: 'USER',
  VALIDATION: 'VALIDATION',
  NETWORK_REG: 'NETWORK_REG',
  PROFILE: 'PROFILE',
  PAYMENT: 'PAYMENT',
  BASKET: 'BASKET',
  TICKETS: 'TICKETS',
  FEEDBACK: 'FEEDBACK',
  SHOP: 'SHOP',
  PARTNERS: 'PARTNERS',
  SUBSCRIBE: 'SUBSCRIBE',
  THEME: 'THEME',
  MEMBER_REG: 'MEMBER_REG',
  LOCATION: 'LOCATION',
  EVENT: 'EVENT',
  ORDERS: 'ORDERS',
  RESULTS: 'RESULTS',
  BANNERS: 'BANNERS',
  BOOKED: 'BOOKED',
  GLOBAL_ERRORS: 'GLOBAL_ERRORS',
  BANNERS_RTK: 'BANNERS_RTK',
};

export default NameSpace;
