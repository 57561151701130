import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ReactComponent as ManLogo } from 'images/profile/profile-name.svg';
import Input from 'components/Forms/Input/Input';

import CustomSelect from 'components/Calendar/Select/Select';
import { optionsPerson } from 'mocks/calendarOptions';
import { newPersonalStyles } from 'components/Calendar/Select/custom';
// стили по умолчанию для Select
import customSelectStyles from '../Settings.module.scss'; // стили для модификации Select
import '../Selector.scss'; // для скроллбара и анимации меню, если нужно (after/before) - осторожно глобальные стили
import { ActionCreator, Operation } from 'reducer/profile/profile';
import { ActionCreator as ValidActCreator } from 'reducer/validation/validation';
import { getExtraContactStatus } from 'reducer/profile/selectors';
import { getExtaContactTelError } from 'reducer/validation/selectors';
import { useValidationPhone } from 'customHooks/useValidationPhone';
import { useShallowEqualSelector } from 'customHooks/useShallowEqualSelector';
import InputPhone from 'components/Forms/Input/InputPhone';
import { useTranslation } from 'react-i18next';


const Relationship = ({styles}) => {
  const dispatch = useDispatch();
  const helpName = useRef();
  const messageRef = useRef();
  const userObj = useRef(JSON.parse(localStorage.userObj));
  const [phone, isValidTel, handlePhoneChange, handlePhoneFocus] = useValidationPhone(userObj.current.contact_phone && `+${userObj.current.contact_phone}`, `setExtraContactTelError`);
  const [extra_contact, setExtraContact] = useState(userObj.current.extra_contact);
  const [isErrorContact, setErrorContact] = useState(``);
  const [type_relate, setTypeRelate] = useState(userObj.current.type_relate);
  const [isErrorType, setErrorType] = useState(``);
  const extraContactStatus = useShallowEqualSelector(state => getExtraContactStatus(state));
  const extraContactTelError = useShallowEqualSelector(state => getExtaContactTelError(state));
  const { t } = useTranslation();


  useEffect(() => {
    let timer = setTimeout(() => {
      dispatch(ActionCreator.setExtraContactStatus(false));
    }, 5000);

    return () => {
      if (timer) clearTimeout(timer);
    }
  });

  const handleInputChange = (evt) => setExtraContact(evt.target.value);
  const handleSelectChange = (evt) => setTypeRelate(evt.value);

  const handleFocus = (evt) => {
    const { name } = evt.target;

    if (name === `helpname`) setErrorContact(``);
    else setErrorType(``);
  }

  // проверка заполненности полей Экстраконтакта
  // и отсылка значений - заполнение всех полей обязательно!
  const handleSubmit = (evt) => {
    evt.preventDefault();

    if (!extra_contact && !type_relate && !isValidTel) {
      setErrorContact(t('lk.relship.nameError'));
      setErrorType(t('lk.relship.relError'));
      dispatch(ValidActCreator.setExtraContactTelError(t('lk.relship.telError')));
    } else if (!extra_contact) {
      setErrorContact(t('lk.relship.nameError'));
    } else if (!type_relate) {
      setErrorType(t('lk.relship.relError'));
    } else if (!isValidTel) {
      dispatch(ValidActCreator.setExtraContactTelError(t('lk.relship.telError')));
    } else {
      dispatch(Operation.setNewData({extra_contact, type_relate, contact_phone: phone.slice(1)}));
    }
  }

  return (
    <div className={styles.formsWrap}>
      <b className={styles.helpTitle}>{t('lk.relship.title')}</b>
      <p className={styles.helpSubtitle}>{t('lk.relship.sub')}</p>
      <div className={styles.formsWrapGrid}>
        <Input
          error={isErrorContact}
          handleFieldChange={handleInputChange}
          handleFocus={handleFocus}
          Logo={ManLogo}
          label={t('lk.relship.name')} name={`helpname`} id={`profile-helpname`}
          defaultValue={extra_contact}
          ref={helpName}
        />

        <div className={styles.inputWrap}>
          <b className={styles.inputCustomLabel}>{t('lk.relship.rel')}*</b>
          <CustomSelect
            error={isErrorType}
            handleSelectChange={handleSelectChange}
            handleFocus={handleFocus}
            defVal={type_relate}
            prefix={`setting-select`}
            styles={customSelectStyles}
            customStyles={newPersonalStyles}
            name={`help`}
            options={optionsPerson}
            placeholder={type_relate ? type_relate : t('lk.relship.textSelect')}
          />
        </div>

        <InputPhone
          error={extraContactTelError}
          value={phone}
          country="RU" international={true} withCountryCallingCode={true}
          onFocus={handlePhoneFocus}
          onChange={handlePhoneChange}
          id={`relate-phone`} name={`relate-phone`} label={t('lk.relship.tel')}
        />

          {extraContactStatus ? <p className={styles.message} ref={messageRef}>{t('lk.relship.dataSave')}</p> : null}
      </div>
      <button
        onClick={handleSubmit}
        type="button"
        className={`${styles.settingBtn} ${styles.settingBtnRelate}`}
      >{t('lk.relship.btn')}
      </button>
    </div>
  );
}

export default Relationship;
