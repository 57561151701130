import styles from "./Container.module.scss";

const Container = ({ children, shop = false }) => {
  return (
    <div className={`${styles.centerman} ${shop ? styles.shop : ``}`}>
      {children}
    </div>
  );
};

export default Container;
