import { socialNetsReg } from 'mocks/mockArrays';
import SocItem from '../SocItem';

const SocRegList = ({styles}) => {
  return <ul className={styles.socialList}>
    { socialNetsReg.map(data => (
      <li key={data.id} className={styles.socialItem}>
        <a
          href={data.link}
          className={styles.socialBtn}
        >
          <SocItem styles={styles} data={data} />
          {data.title}
        </a>
      </li>
    )) }
  </ul>
};

export default SocRegList;
