import { ReactComponent as WarnLogo } from 'images/profile/warn.svg';

const Status = ({status, styles}) => {
  return (
    <span className={styles.phoneChangeStatus}>
      <WarnLogo className={styles.warnLogo} />
      {status}
    </span>
  );
}

export default Status;
