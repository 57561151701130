import { returnAuthConfig } from '../../utils/auth';

const initialState = {
  freeSeatNumbers: [],
  bookedNumbers: [],
  space: {},
  spaceList: [],
};

const ActionType = {
  SET_FREE_SEAT_NUMBERS: `SET_FREE_SEAT_NUMBERS`,
  SET_BOOKED_NUMBERS: `SET_BOOKED_NUMBERS`,
  SET_SPACE: `SET_SPACE`,
  SET_SPACE_LIST: `SET_SPACE_LIST`,
};

const ActionCreator = {
  setFreeNumbers: (numbers) => ({
    type: ActionType.SET_FREE_SEAT_NUMBERS,
    payload: numbers,
  }),
  setBookedNumbers: (numbers) => ({
    type: ActionType.SET_BOOKED_NUMBERS,
    payload: numbers,
  }),
  setSpace: (space) => ({
    type: ActionType.SET_SPACE,
    payload: space,
  }),
  setSpaceList: (spaceList) => ({
    type: ActionType.SET_SPACE_LIST,
    payload: spaceList,
  }),
};

export const Operation = {
  getNumber: (formatPublicId) => async (dispatch, _, api) => {
    const response = await api.get(`/api/booked/numbers/${formatPublicId}`);

    if (response.status === 200) {
      dispatch(ActionCreator.setFreeNumbers(response.data.free_numbers));
      dispatch(ActionCreator.setBookedNumbers(response.data.booked_numbers));
    }
  },
  bookedNumbers: (data, callback) => async (dispatch, _, api) => {
    const config = returnAuthConfig();
    const response = await api.post(`/api/booked/number`, data, config);

    if (response.status === 200) {
      callback();
    }
  },
  fetchSpace: (space) => async (dispatch, _, api) => {
    const response = await api.get(`/api/space/${space}`);

    if (response.status === 200) {
      dispatch(ActionCreator.setSpace(response.data))
    }
  },
  fetchSpaceList: (space) => async (dispatch, _, api) => {
    const response = await api.get(`/api/space/map/list/${space}`);

    if (response.status === 200) {
      dispatch(ActionCreator.setSpaceList(response.data.values))
    }
  },
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.SET_FREE_SEAT_NUMBERS:
      return { ...state, freeSeatNumbers: action.payload };
    case ActionType.SET_BOOKED_NUMBERS:
      return { ...state, bookedNumbers: action.payload };
    case ActionType.SET_SPACE:
      return { ...state, space: action.payload };
    case ActionType.SET_SPACE_LIST:
      return { ...state, spaceList: action.payload };
    default:
      return state;
  }
};

