export const customStyles = {
  container: (provided) => ({
    ...provided,
  }),

  menu: (provided, state) => ({
    ...provided,
    width: `100%`,
    color: `white`,
    padding: `1.875rem 1.25rem 1.25rem 0.625rem`,
    backgroundColor: `#151515`,
    zIndex: `1`,
    borderRadius: `5px`,
  }),

  menuList: (provided) => ({
    ...provided,
    maxHeight: 200,
  }),

  option: (provided, { data, isFocused }) => {
    const disabled = data?.size && data?.value <= 1

    return {
      ...provided,
      padding: `0.5rem 0.75rem`,
      color: `white`,
      fontFamily: `DIN Pro`,
      fontSize: `1rem`,
      fontWeight: 700,
      lineHeight: 1.222,
      backgroundColor: isFocused
        ? `rgba(255,255,255, 0.3)`
        : `transparent`,
      color: disabled && "rgba(255,255,255, 0.1)",
      cursor: `pointer`,
      transition: `background-color 0.2s ease`,
    };
  },

  control: (_, { selectProps: { width } }) => ({
    display: `flex`,
    justifyContent: `space-between`,
    alignItems: `center`,
    width: `100%`,
    padding: `1rem 0.625rem 1rem 1.5rem`,
    cursor: `pointer`,
  }),

  valueContainer: (provided) => ({
    ...provided,
    display: `flex`,
    alignItems: `center`,
    padding: `0`,
    marginTop: `auto`,
  }),

  indicatorsContainer: () => ({ padding: `0` }),
  indicatorSeparator: () => ({ display: `none` }),
  dropdownIndicator: (provided) => ({ ...provided, padding: `0` }),

  singleValue: () => ({
    color: `white`,
  }),

  placeholder: () => ({
    fontSize: `0.75rem`,
    lineHeight: `1.166`,
    color: `rgba(255, 255, 255, 0.3)`,
  }),
};

const getCustomStyles = () => {
  return {
    ...customStyles,

    placeholder: () => ({
      fontSize: `1rem`,
      lineHeight: `1.166`,
      color: `rgba(255, 255, 255, 1)`,
    }),
  };
};

export const newPersonalStyles = getCustomStyles();

export const customTheme = (theme) => {
  return {
    ...theme,
    colors: {
      ...theme.colors,
      primary: `#7d7fec`,
      primary50: `#7d7fec`,
    },
  };
};
