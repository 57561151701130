export const images = ({ params, carousel, carouselElRef }) => {
  let imagesLoaded = 0, imagesToLoad = []

  const preloadImages = () => {
    imagesToLoad = carouselElRef.current.querySelectorAll("img");

    function onReady() {
      if (carousel.current.destroyed) return;
      if (imagesLoaded !== undefined) imagesLoaded += 1;

      if (imagesLoaded === imagesToLoad.length) {
        if (params.updateOnImagesReady) carousel.current.update();
        carousel.current.emit('imagesReady');
      }
    }

    for (let i = 0; i < imagesToLoad.length; i += 1) {
      const imageEl = imagesToLoad[i];
      loadImage(imageEl, imageEl.currentSrc || imageEl.getAttribute('src'), imageEl.srcset || imageEl.getAttribute('srcset'), imageEl.sizes || imageEl.getAttribute('sizes'), onReady);
    }
  }
  const loadImage = (imageEl, src, srcset, sizes, callback) => {
    let image;

    function onReady() {
      if (callback) callback();
    }

    const isPicture = imageEl.parentElement.nodeName === "PICTURE"

    if (!isPicture && !imageEl.complete) {
      if (src) {
        image = new window.Image();
        image.onload = onReady;
        image.onerror = onReady;

        if (sizes) {
          image.sizes = sizes;
        }

        if (srcset) {
          image.srcset = srcset;
        }

        if (src) {
          image.src = src;
        }
      } else {
        onReady();
      }
    } else {
      onReady();
    }
  }
  return { loadImage, preloadImages }
}
