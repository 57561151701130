import { Link } from 'react-router-dom';
import { routes } from 'const/routes';
import styles from './CheckMailPopup.module.scss';
import Popup from '../Popup/Popup';


const CheckMailPopup = ({closePopupHandler}) => {
  return (
  <Popup>
    <div className={styles.wrapper}>
      <h2 className={styles.title}>Проверьте почту</h2>
      <p className={styles.text}>Вы успешно зарегистрировались, осталось подтвердить почту!</p>
      <p className={styles.text}>Для подтверждения перейдите по ссылке, указанной в письме (письмо может быть в спаме)</p>
      <Link className={styles.link} to={routes.main.path}>Назад на главную</Link>
      <button onClick={closePopupHandler}
        className={styles.btn}>
        <span className="visually-hidden">На главную</span>
      </button>
    </div>
  </Popup>
  );
}

export default CheckMailPopup;
