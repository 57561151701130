import styles from "../MyInsurance.module.scss";

export const InsuranceSmallCard = ({ setSelectedInsurance }) => {

    return (<li className={styles.insuranceSmallCard}>

        <p className={styles.nameSmall}>Страхование участника</p>
        <p className={styles.day}>Покрытие несчастных случаев в день участия</p>

        <div className={styles.foot}>
            <div className={styles.price}>
                <p className={styles.insurancePrice}>Стоимость:</p>
                <p className={styles.number}>200 ₽</p>
            </div>
            <button onClick={() => setSelectedInsurance(true)} className={styles.btnInsurance}>Оформить</button>
        </div>
    </li>);
};