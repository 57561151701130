export const anyActions = ({ carousel, params }) => {
  const handlePaginationClick = (index) => {
    carousel.current.slideToLoop(index);
  }
  const handleThumbClick = (index) => {
    carousel.current.slideToLoop(index);
  }
  const handlePrevClick = (e) => {
    e.preventDefault();
    if (carousel.current.isBeginning && !params.loop && !params.rewind) return;
    carousel.current.slidePrev();
  }
  const handleNextClick = (e) => {
    e.preventDefault();
    if (carousel.current.isEnd && !params.loop && !params.rewind) return;
    carousel.current.slideNext();
  }
  return { handlePrevClick, handleNextClick, handlePaginationClick, handleThumbClick }
}
