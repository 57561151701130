import styles from '../Popup/Popup.module.scss';
import Popup from 'components/Popups/Popup/Popup';


const VerifyPopup = ({closePopupHandler}) => {
  return (
    <Popup>
      <div className={styles.wrapper}>
        <h2 className={styles.title}>Email успешно подтвержден</h2>
        <button onClick={closePopupHandler}
          className={styles.closeBtn}>
          <span className="visually-hidden">Закрыть</span>
        </button>
      </div>
    </Popup>
  )
}

export default VerifyPopup;