import { routes } from 'const/routes';
import { returnAuthConfig } from 'utils/auth';

const initialState = {
  tickets: [],
  result: null,
  onlineTicketInfo: undefined,
  isLoadTickets: false,
  loadResultMessage: '',
  transferTicketMessage: '',
  returnTransferTicketMessage: '',
  transferTicket: [],
  pastTicket: null,
  regMessage: ``,
  isCancelReg: {
    popup: false,
    message: ``,
  },
  isCancelBuy: {
    popup: false,
    isTicketReturned: false,
    message: ``,
    sum: 0,
  },
};

export const ActionType = {
  LOAD_TICKETS: `LOAD_TICKETS`,
  REMOVE_TICKET: 'REMOVE_TICKET',
  REGISTRATION_TICKET: `REGISTRATION_TICKET`,
  CANCEL_REG_POPUP: `CANCEL_REG_POPUP`,
  CANCEL_REG_MESSAGE: `CANCEL_REG_MESSAGE`,
  CANCEL_BUY_POPUP: `CANCEL_BUY_POPUP`,
  CANCEL_BUY_TICKET: `CANCEL_BUY_TICKET`,
  CANCEL_BUY_TICKET_MESSAGE: `CANCEL_BUY_TICKET_MESSAGE`,
  REGISTRATION_MESSAGE: `REGISTRATION_MESSAGE`,
  GET_SUM: 'GET_SUM',
  LOAD_RESULT_MESSAGE: 'LOAD_RESULT_MESSAGE',
  TRANSFER_TICKET_MESSAGE: 'TRANSFER_TICKET_MESSAGE',
  RETURN_TRANSFER_TICKET_MESSAGE: ' RETURN_TRANSFER_TICKET_MESSAGE',
  SET_TRANSFER_TICKET: 'SET_TRANSFER_TICKET',
  SET_PAST_TICKET: 'SET_PAST_TICKET',
  SET_ONLINE_TICKET_INFO: 'SET_ONLINE_TICKET_INFO',
  GET_USER_RESULT: 'GET_USER_RESULT',
};

export const ActionCreator = {
  loadTickets: (tickets) => ({
    type: ActionType.LOAD_TICKETS,
    payload: tickets,
  }),
  removeTicket: (tickets) => ({
    type: ActionType.REMOVE_TICKET,
    payload: tickets,
  }),
  setUserResult: (result) => ({
    type: ActionType.GET_USER_RESULT,
    payload: result,
  }),

  setTransferTicket: (tickets) => ({
    type: ActionType.SET_TRANSFER_TICKET,
    payload: tickets,
  }),
  setPastTicket: (tickets) => ({
    type: ActionType.SET_PAST_TICKET,
    payload: tickets,
  }),
  setReturnTransferMessage: (tickets) => ({
    type: ActionType.RETURN_TRANSFER_TICKET_MESSAGE,
    payload: tickets,
  }),

  registrationTicket: (ticket) => ({
    type: ActionType.REGISTRATION_TICKET,
    payload: ticket,
  }),
  setOnlineTicketInfo: (ticket) => ({
    type: ActionType.SET_ONLINE_TICKET_INFO,
    payload: ticket,
  }),

  registrationMessage: (message) => ({
    type: ActionType.REGISTRATION_MESSAGE,
    payload: message,
  }),

  setLoadResultMessage: (message) => ({
    type: ActionType.LOAD_RESULT_MESSAGE,
    payload: message,
  }),
  setTransferTicketMessage: (message) => ({
    type: ActionType.TRANSFER_TICKET_MESSAGE,
    payload: message,
  }),
  // CANCEL REGISTRATION TICKET
  cancelRegPopup: (bool) => ({
    type: ActionType.CANCEL_REG_POPUP,
    payload: bool,
  }),

  isCancelRegMessage: (message) => ({
    type: ActionType.CANCEL_REG_MESSAGE,
    payload: message,
  }),

  // CANCEL BUY TICKET
  cancelBuyPopup: (bool) => ({
    type: ActionType.CANCEL_BUY_POPUP,
    payload: bool,
  }),

  cancelBuyTicket: (bool) => ({
    type: ActionType.CANCEL_BUY_TICKET,
    payload: bool,
  }),

  cancelBuyTicketMessage: (message) => ({
    type: ActionType.CANCEL_BUY_TICKET_MESSAGE,
    payload: message,
  }),
  getSum: (sum) => ({
    type: ActionType.GET_SUM,
    payload: sum,
  }),
};

export const Operation = {
  loadTickets: () => async (dispatch, getState, api) => {
    const config = returnAuthConfig();
    const response = await api.get(`/api/user/tickets/my`, config);

    if (response.status === 200) {
      dispatch(ActionCreator.loadTickets(response.data.values));
    }
  },
  confirmTicket: (isConfirm, public_id) => async (dispatch, getState, api) => {
    const config = returnAuthConfig();
    await api.put(
      `/api/user/ticket/confirm/transfer`,
      { transfer_confirm: isConfirm, public_id },
      config
    );
  },

  getUserResult: (ticket_public_id) => async (dispatch, getState, api) => {
    const config = returnAuthConfig();
    const response = await api.get(
      `/api/user/result/${ticket_public_id}`,
      config
    );

    if (response.status === 200) {
      dispatch(ActionCreator.setUserResult(response.data));
    }
  },
  loadTOnlineTicketInfo: (public_id) => async (dispatch, getState, api) => {
    const config = returnAuthConfig();
    const response = await api.get(
      `/api/user/ticket/online/${public_id}`,
      config
    );

    if (response.status === 200) {
      dispatch(ActionCreator.setOnlineTicketInfo(response.data));
    }
  },

  loadCertificate:
    (ticket_public_id, setLoading) => async (dispatch, getState, api) => {
      try {
        const config = returnAuthConfig();
        setLoading(true);

        const response = await api.get(
          `/api/user/certificate/result/${ticket_public_id}`,
          { ...config, responseType: 'blob' }
        );

        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const fileName = response?.headers?.['content-disposition']
            ?.split('filename=')[1]
            ?.split(';')?.[0];
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
      }
    },
  transferTicket: (data) => async (dispatch, getState, api) => {
    const config = returnAuthConfig();

    const response = await api.put(`/api/user/ticket/transfer`, data, config);

    if (response.status === 200) {
      dispatch(ActionCreator.setTransferTicketMessage(response.data.message));
    } /* else if (response.status >= 400) {
      dispatch(ActionCreator.setTransferTicketMessage(response.data.message));
    } */
  },

  getTransferTicket: () => async (dispatch, getState, api) => {
    const config = returnAuthConfig();
    const response = await api.get(`/api/user/tickets/transfered`, config);

    if (response.status === 200) {
      dispatch(ActionCreator.setTransferTicket(response.data.values));
    } /* else if (response.status >= 400) {
      backendLogger(response);
    } */
  },
  getPastTicket: () => async (dispatch, getState, api) => {
    const config = returnAuthConfig();
    const response = await api.get(`/api/user/tickets/old`, config);

    if (response.status === 200) {
      dispatch(ActionCreator.setPastTicket(response.data.values));
    } /*  else if (response.status >= 400) {
      backendLogger(response);
    } */
  },

  loadResult: (data) => async (dispatch, getState, api) => {
    const config = returnAuthConfig();

    const response = await api.put(`/api/user/ticket/online`, data, config);

    if (response.status === 200) {
      dispatch(Operation.loadTickets());
      dispatch(ActionCreator.setLoadResultMessage(response.data.message));
    } /*  else if (response.status >= 400) {
      dispatch(ActionCreator.setLoadResultMessage(response.data.message));
    } */
  },
  returnTransferTicket: (public_id) => async (dispatch, getState, api) => {
    const config = returnAuthConfig();

    const response = await api.put(
      `/api/user/ticket/return`,
      public_id,
      config
    );

    if (response.status === 200) {
      dispatch(ActionCreator.setReturnTransferMessage(response.data.message));
    } /* else if (response.status >= 400) {
      dispatch(ActionCreator.setReturnTransferMessage(response.data.message));
    } */
  },

  registrationTicket: (ticket, navigate) => async (dispatch, __, api) => {
    const config = returnAuthConfig();
    const response = await api.put(`/api/user/ticket`, ticket, config);

    if (response.status === 200) {
      // update tickets
      navigate(routes.myEvents.path);
    } /* else if (response.status >= 400) {
      dispatch(ActionCreator.registrationMessage(response.data.message));
    } */
  },

  cancelRegistrationTicket: (info, navigate) => async (dispatch, _, api) => {
    const config = returnAuthConfig();
    const response = await api.put(`/api/user/ticket/disable`, info, config);

    if (response.status === 200) {
      dispatch(ActionCreator.cancelRegPopup(false));
      dispatch(Operation.loadTickets());
      /*   navigate(routes.myEvents.path); */
    } /*  else if (response.status >= 400) {
      dispatch(ActionCreator.isCancelRegMessage(response.data.message));
    } */
  },

  cancelBuyTicket: (ticket, setIsLoading) => async (dispatch, _, api) => {
    const config = returnAuthConfig();
    setIsLoading(true);

    try {
      const response = await api.post(`/api/payment/cancel`, ticket, {
        timeout: 30000,
        ...config,
      });

      if (response.status === 200) {
        dispatch(ActionCreator.getSum(response.data.sum));
        dispatch(ActionCreator.cancelBuyPopup(false));
        dispatch(ActionCreator.cancelBuyTicket(true));
        setIsLoading(false);
      } else if (response.status >= 400) {
        dispatch(ActionCreator.cancelBuyPopup(false));
      }
    } catch (e) {
      dispatch(ActionCreator.cancelBuyPopup(false));
    }
  },
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.LOAD_TICKETS:
      return { ...state, tickets: action.payload, isLoadTickets: true };
    case ActionType.REGISTRATION_MESSAGE:
      return { ...state, regMessage: action.payload };
    case ActionType.CANCEL_REG_POPUP:
      return {
        ...state,
        isCancelReg: { ...state.isCancelReg, popup: action.payload },
      };
    case ActionType.GET_USER_RESULT:
      return {
        ...state,
        result: action.payload,
      };
    case ActionType.CANCEL_REG_MESSAGE:
      return {
        ...state,
        isCancelReg: { ...state.isCancelReg, message: action.payload },
      };
    case ActionType.CANCEL_BUY_POPUP:
      return {
        ...state,
        isCancelBuy: { ...state.isCancelBuy, popup: action.payload },
      };
    case ActionType.CANCEL_BUY_TICKET:
      return {
        ...state,
        isCancelBuy: { ...state.isCancelBuy, isTicketReturned: action.payload },
      };
    case ActionType.CANCEL_BUY_TICKET_MESSAGE:
      return {
        ...state,
        isCancelBuy: { ...state.isCancelBuy, message: action.payload },
      };
    case ActionType.LOAD_RESULT_MESSAGE:
      return { ...state, loadResultMessage: action.payload };
    case ActionType.SET_ONLINE_TICKET_INFO:
      return { ...state, onlineTicketInfo: action.payload };
    case ActionType.TRANSFER_TICKET_MESSAGE:
      return { ...state, transferTicketMessage: action.payload };
    case ActionType.RETURN_TRANSFER_TICKET_MESSAGE:
      return { ...state, returnTransferTicketMessage: action.payload };
    case ActionType.SET_TRANSFER_TICKET:
      return {
        ...state,
        tickets: [...state.tickets, ...action.payload],
        transferTicket: action.payload,
      };
    case ActionType.SET_PAST_TICKET:
      return {
        ...state,
        tickets: [...state.tickets, ...action.payload],
        pastTicket: action.payload,
      };
    case ActionType.REMOVE_TICKET:
      return {
        ...state,
        tickets: state.tickets.filter(
          (el) => el?.public_id !== action?.payload?.public_id
        ),
      };
    case ActionType.GET_SUM:
      return {
        ...state,
        isCancelBuy: { ...state.isCancelBuy, sum: action.payload },
      };
    default:
      return state;
  }
};
