import styles from "./CorpSentPopup.module.scss";
import Popup from "../Popup/Popup";
import { Link } from "react-router-dom";

const CorpSentPopup = ({
  statePopupHandler,
  title,
  description,
  buttonTitle = "На главную",
  to = "/",
}) => {
  // const closePopup = () => {
  //   statePopupHandler(false);
  // };

  return (
    <Popup>
      <div className={styles.wrapper}>
        {/* <h2 className={styles.title}>Лучший вклад <br/> в твою команду</h2> */}
        <h2 className={styles.title}>{title}</h2>
        {/* <p className={styles.description}>Спасибо! Ваша заявка отправлена. Наши менеджеры свяжутся с вами в ближайшее время</p> */}
        <p className={styles.description}>{description}</p>

        <Link className={styles.btn} to={to} onClick={statePopupHandler}>
          {buttonTitle}
        </Link>

        <button
          // onClick={closePopup}
          onClick={statePopupHandler}
          data-state="close"
          className={styles.popupCloseBtn}
          type="button"
          aria-label="Закрыть"
        >
          <span className="visually-hidden">Закрыть</span>
        </button>
      </div>
    </Popup>
  );
};

export default CorpSentPopup;
