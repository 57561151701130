import { backendLogger } from "utils/logger";
import {
  setFilterShop,
  setFilterSubgroups,
  setCollectionShop,
} from "utils/shop";

const initialState = {
  itemsHome: [],
  itemsBasket: [],
  goods: [],
  previewGoods: [],
  collections: [],
  filteredGoods: {
    byCategory: [],
    bySubgroup: [],
  },
};

export const ActionType = {
  LOAD_ITEMS: `LOAD_ITEMS`,
  LOAD_ITEMS_BASKET: `LOAD_ITEMS_BASKET`,
  LOAD_SHOP_ITEMS: `LOAD_SHOP_ITEMS`,
  FILTER_SHOP_ITEMS: `FILTER_SHOP_ITEMS`,
  FILTER_SHOP_SUBGROUPS: `FILTER_SHOP_SUBGROUPS`,
  SET_SHOP_COLLECTION: `SET_SHOP_COLLECTION`,
  LOAD_PREVIEW_SHOP_ITEMS: "LOAD_PREVIEW_SHOP_ITEMS",
  SET_PREVIEW_SHOP_COLLECTION: "SET_PREVIEW_SHOP_COLLECTION",
};

export const ActionCreator = {
  loadItems: (items) => ({
    type: ActionType.LOAD_ITEMS,
    payload: items,
  }),

  loadItemsBasket: (items) => ({
    type: ActionType.LOAD_ITEMS_BASKET,
    payload: items,
  }),

  loadShopItems: (items) => ({
    type: ActionType.LOAD_SHOP_ITEMS,
    payload: items,
  }),

  loadPreviewShopItems: (items) => ({
    type: ActionType.LOAD_PREVIEW_SHOP_ITEMS,
    payload: items,
  }),

  filterShopItems: (filter) => ({
    type: ActionType.FILTER_SHOP_ITEMS,
    payload: filter,
  }),
  filterShopSubgroups: (filter) => ({
    type: ActionType.FILTER_SHOP_SUBGROUPS,
    payload: filter,
  }),
  setShopCollection: (collection) => ({
    type: ActionType.SET_SHOP_COLLECTION,
    payload: collection,
  }),
  setPreviewShopCollection: (collection) => ({
    type: ActionType.SET_PREVIEW_SHOP_COLLECTION,
    payload: collection,
  }),
};

export const Operation = {
  loadItems: (page) => async (dispatch, getState, api) => {
    const response = await api.get(`/api/shop/items/${page}`);

    if (response.status === 200) {
      if (page === `home`)
        dispatch(ActionCreator.loadItems(response.data.values));
      if (page === `basket`)
        dispatch(ActionCreator.loadItemsBasket(response.data.values));
    } /* else if (response.status >= 400) backendLogger(response); */
  },

  loadShopItems: () => async (dispatch, getState, api) => {
    const response = await api.get(`/api/shop/project/heroes`);

    if (response.status === 200) {
      dispatch(ActionCreator.loadShopItems(response.data.values));
    } else {
      alert(response.data.message);
    }
  },
  loadPreviewShopItems: () => async (dispatch, getState, api) => {
    const response = await api.get(`/api/shop/project/preview/heroes`);

    if (response.status === 200) {
      dispatch(ActionCreator.loadPreviewShopItems(response.data.values));
    } else {
      alert(response.data.message);
    }
  },

  filterShopItems: (filter) => async (dispatch, getState, api) => {
    if (filter) {
      dispatch(ActionCreator.filterShopItems(filter));
    } else {
      alert("Попробуйте позже");
    }
  },

  filterShopSubgroups: (filter) => async (dispatch, getState, api) => {
    if (filter) {
      dispatch(ActionCreator.filterShopSubgroups(filter));
    } else {
      alert("Попробуйте позже");
    }
  },

  setShopCollection: (collection_name) => async (dispatch, getState, api) => {
    if (collection_name) {
      dispatch(ActionCreator.setShopCollection(collection_name));
    } else {
      alert("Проблема при загрузке");
    }
  },
  setPreviewShopCollection:
    (collection_name) => async (dispatch, getState, api) => {
      if (collection_name) {
        dispatch(ActionCreator.setPreviewShopCollection(collection_name));
      } else {
        alert("Проблема при загрузке");
      }
    },
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.LOAD_ITEMS:
      return { ...state, itemsHome: action.payload };
    case ActionType.LOAD_ITEMS_BASKET:
      return { ...state, itemsBasket: action.payload };
    // New shop
    case ActionType.LOAD_SHOP_ITEMS:
      return { ...state, goods: action.payload };
    case ActionType.LOAD_PREVIEW_SHOP_ITEMS:
      return { ...state, previewGoods: action.payload };
    case ActionType.FILTER_SHOP_ITEMS:
      return {
        ...state,
        filteredGoods: {
          ...state.filteredGoods,
          byCategory: setFilterShop(state.goods, action.payload),
        },
      };
    case ActionType.FILTER_SHOP_SUBGROUPS:
      return {
        ...state,
        filteredGoods: {
          ...state.filteredGoods,
          bySubgroup: setFilterSubgroups(
            state.filteredGoods.byCategory,
            action.payload
          ),
        },
      };
    case ActionType.SET_SHOP_COLLECTION:
      return {
        ...state,
        collections: [
          ...state.collections,
          setCollectionShop(state.goods, action.payload),
        ],
      };
    case ActionType.SET_PREVIEW_SHOP_COLLECTION:
      return {
        ...state,
        collections: [
          ...state.collections,
          setCollectionShop(state.previewGoods, action.payload),
        ],
      };
    default:
      return state;
  }
};
