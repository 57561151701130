import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ActionCreator } from 'reducer/validation/validation';


export const useValidationPassword = (initialState) => {
  const dispatch = useDispatch();
  const [password, setPassword] = useState(initialState);
  const [isValidPass, setValidPass] = useState(false);

  const handlePasswordChange = (evt) => {
    const { value } = evt.target;

    setPassword(value);
    setValidPass(value !== ``);
  }

  const handleFocus = (evt) => {
    evt.target.classList.remove('error-reg');
    dispatch(ActionCreator.setPassError(``));
  }

  return [password, isValidPass, handlePasswordChange, handleFocus];
}