export const loop = ({ carousel }) => {
  const loopFix = () => {
    carousel.current.emit('beforeLoopFix');

    let newIndex;
    carousel.current.allowSlideNext = true;
    const snapTranslate = -carousel.current.snapGrid[carousel.current.activeIndex];
    const diff = snapTranslate - carousel.current.getCarouselTranslate();
    if (carousel.current.activeIndex < carousel.current.loopedSlides) {
      newIndex = carousel.current.slides.length - carousel.current.loopedSlides * 3 + carousel.current.activeIndex;
      newIndex += carousel.current.loopedSlides;
      const slideChanged = carousel.current.slideTo(newIndex, 0, false, true);

      if (slideChanged && diff !== 0) {
        carousel.current.setTranslate((carousel.current.rtlTranslate ? -carousel.current.translate : carousel.current.translate) - diff);
      }
    } else if (carousel.current.activeIndex >= carousel.current.slides.length - carousel.current.loopedSlides) {

      newIndex = -carousel.current.slides.length + carousel.current.activeIndex + carousel.current.loopedSlides;
      newIndex += carousel.current.loopedSlides;
      const slideChanged = carousel.current.slideTo(newIndex, 0, false, true);

      if (slideChanged && diff !== 0) {
        carousel.current.setTranslate((carousel.current.rtlTranslate ? -carousel.current.translate : carousel.current.translate) - diff);
      }
    }
    carousel.current.emit('loopFix');
  }
  const calcLoopedSlides = (slides, carouselParams) => {
    let slidesPerViewParams = carouselParams.slidesPerView;

    let loopedSlides = Math.ceil(parseFloat(carouselParams.loopedSlides || slidesPerViewParams, 10));
    loopedSlides += carouselParams.loopAdditionalSlides;

    if (loopedSlides > slides.length) {
      loopedSlides = slides.length;
    }
    return loopedSlides;
  }
  return { loopFix, calcLoopedSlides }
}
