import styles from "./ClubsAddPopup.module.scss";
import Input from '../../Forms/Input/Input';
import { ReactComponent as ReactLogoName } from '../../../images/profile/profile-name.svg';
import { useValidationText } from '../../../customHooks/useValidation';
import { useEffect, useRef, useState } from 'react';
import { useValidationMail } from '../../../customHooks/useValidationMail';
import { ReactComponent as ReactLogoMail } from '../../../images/svg/form-mail.svg';
import { useValidationPhone } from '../../../customHooks/useValidationPhone';
import InputPhone from '../../Forms/Input/InputPhone';
import { ReactComponent as ReactLogoSms } from '../../../images/svg/form-sms.svg';
import { Operation as OperationSubscribe } from '../../../reducer/subscribe/subscribe';
import { useDispatch } from 'react-redux';
import CorpSentPopup from '../../Popups/CorpSent/CorpSentPopup';
import { useMediaQuery } from "react-responsive";
import { useSetNewQuestionMutation } from "reducer/feedback";

const ClubsAddPopup = ({
  statePopupHandler
}) => {
  const [setNewQuestion] = useSetNewQuestionMutation();

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const [
    name,
    isValidName,
    nameError,
    setNameError,
    handleNameChange,
    handleNameFocus,
    setNameValue,
  ] = useValidationText(``);

  const [email, isValidMail, handleInputChange, handleEmailFocus] =
    useValidationMail(``);

  const [phone, isValidPhone, handlePhoneChange, , setPhone, setValidPhone] = useValidationPhone(``);

  const [isErrors, setErrors] = useState({
    email: ``,
    phone: ``,
  });
  const [isValidForm, setValidForm] = useState(false);
  const [isCheckPolitics, setCheckPolitics] = useState(false);
  const [isCheckSubscribe, setCheckSubscribe] = useState(false);
  const [isCorpSentPopup, setIsCorpSentPopup] = useState(false);

  const nameRef = useRef();
  const emailRef = useRef();
  const telRef = useRef();
  const checkPoliticsRef = useRef();
  const textareaRef = useRef();

  const dispatch = useDispatch();

  useEffect(() => {
    setValidForm(
      isValidName
      && isValidMail
      && isValidPhone
      && isCheckPolitics
    );

  }, [isValidName, isValidMail, isValidPhone, isCheckPolitics, dispatch]);

  const handleFocus = (evt) => {
    const { name } = evt.target;
    setErrors((prev) => ({ ...prev, [name]: `` }));
  };

  const handleFormSubmit = async (evt) => {
    evt.preventDefault();

    if (!isValidName) setNameError(`Заполните имя`);
    if (!isValidMail) setErrors((prev) => ({ ...prev, email: `Введите корректный email` }));
    if (!isValidPhone) setErrors((prev) => ({ ...prev, phone: `Введите корректный телефон` }));

    let textareaText = textareaRef.current.value;

    try {
      const responce = await setNewQuestion({
        name: name,
        email: email,
        phone: phone.slice(1),
        text: textareaText,
        support_type: "clubs",
      }).unwrap();

      if (responce.message) {
        setIsCorpSentPopup(true);
      }
    }
    catch (e) {
      console.error(e);
    }
    if (isCheckSubscribe) {
      dispatch(OperationSubscribe.setNewSubscribe({
        email: email,
      }));

      setCheckSubscribe(false);
    }

    setNameValue('');
    setPhone('');
    setCheckPolitics(false);
    setValidForm(false);
    setValidPhone(false);
    evt.target.reset();
  };

  const handleCheckChange = (evt) => {
    let check = evt.target.checked;
    if (check) evt.target.classList.remove('error-reg');
    setCheckPolitics(check);
  };

  const handleSubscribeChange = (evt) => {
    let check = evt.target.checked;

    setCheckSubscribe(check);
  };

  useEffect(() => {
    if (!isMobile) {
      document.body.style.overflow = `hidden`;
    }
    else {
      document.body.style.overflow = ``;
    }
    return () => {
      document.body.style.overflow = ``;
    };
  }, [isMobile]);

  return (
    <>
      <div className={styles.popup}>
        <div className={styles.wrapper}>

          <button
            onClick={statePopupHandler}
            data-state="close"
            className={styles.popupCloseBtn}
            type="button"
            aria-label="Закрыть"
          >
            <span className="visually-hidden">Закрыть</span>
          </button>

          <section className={styles.formMerch} id="contact-form">
            <h2 className={styles.title}>Ответим за пару часов</h2>
            <form className={styles.form} onSubmit={handleFormSubmit} >
              <div className={styles.row}>
                <Input
                  firstLetterUpperCase
                  error={nameError}
                  handleFieldChange={handleNameChange}
                  handleFocus={handleNameFocus}
                  Logo={ReactLogoName}
                  label={`Ваше имя`}
                  name={`name`}
                  id={`ticket-name`}
                  ref={nameRef}
                  outsideStyle={styles}
                />
                <Input
                  handleFieldChange={handleInputChange}
                  error={isErrors.email}
                  handleFocus={handleFocus}
                  Logo={ReactLogoMail}
                  label={`Ваш email`}
                  name={`email`}
                  id={`email`}
                  ref={emailRef}
                  // value={email}
                  outsideStyle={styles}
                />
              </div>
              <div className={styles.row}>
                <InputPhone
                  country="RU"
                  international={true}
                  withCountryCallingCode={true}
                  error={isErrors.phone}
                  label="Ваш телефон"
                  id="ticket-phone"
                  type="tel"
                  name="phone"
                  value={phone}
                  onFocus={handleFocus}
                  onChange={handlePhoneChange}
                  ref={telRef}
                  outsideStyle={styles}
                />
              </div>
              <div className={styles.inputGroupTextarea}>
                <ReactLogoSms className={styles.iconTextarea} />
                <label
                  className={styles.label}
                  htmlFor="yourtext">Любые пожелания</label>
                <textarea
                  className={styles.textarea}
                  id="yourtext"
                  autoComplete="off"
                  type="text"
                  ref={textareaRef}
                />
              </div>

              <div className={styles.btnWrap}>
                <div className={styles.firstCheckbox}>
                  <input
                    className={`visually-hidden`}
                    id="reg-check-politics"
                    type="checkbox"
                    ref={checkPoliticsRef}
                    onChange={handleCheckChange}
                    checked={isCheckPolitics}
                  />
                  <label className={styles.checkboxLabel} htmlFor="reg-check-politics">
                    Я согласен на обработку <a href="/api/files/document/pers">персональных данных</a>
                  </label>
                </div>
                <div className={styles.secondCheckbox}>
                  <input
                    className={`visually-hidden`}
                    id="reg-check-mailing"
                    name="mailing"
                    type="checkbox"
                    onChange={handleSubscribeChange}
                    checked={isCheckSubscribe}
                  />
                  <label className={styles.checkboxLabel} htmlFor="reg-check-mailing">
                    Я хочу получать рассылку и новости о акциях и новинках компании
                  </label>
                </div>
                <button
                  className={styles.btn} type="submit"
                  disabled={!isValidForm}
                >Отправить</button>
              </div>
            </form>

            {isCorpSentPopup ? <CorpSentPopup
              title="Лучший вклад в твою команду"
              description="Спасибо! Ваша заявка отправлена. Наши менеджеры свяжутся с вами в ближайшее время."
              statePopupHandler={() => setIsCorpSentPopup(false)} /> : null}
          </section>
        </div>
      </div>
    </>
  );
};

export default ClubsAddPopup;
