import { elementChildren, elementNextAll, elementPrevAll, elementStyle, getSupport } from "../utils";
import carouselStyles from "../carousel.module.scss"

export const updates = ({ carouselElRef, wrapperElRef, carousel, params }) => {
  const support = getSupport()
  let realIndex = 0, virtualSize = 0, snapIndex = 0
  let classNames = []

  const updateSlides = () => {
    const getDirectionLabel = (property) => {
      if (params.direction === 'horizontal') {
        return property;
      }
      return {
        'width': 'height',
        'margin-top': 'margin-left',
        'margin-bottom ': 'margin-right',
        'margin-left': 'margin-top',
        'margin-right': 'margin-bottom',
        'padding-left': 'padding-top',
        'padding-right': 'padding-bottom',
        'marginRight': 'marginBottom'
      }[property];
    }
    function getDirectionPropertyValue(node, label) {
      return parseFloat(node.getPropertyValue(getDirectionLabel(label)) || 0);
    }

    const previousSlidesLength = carousel.current.slides?.length;
    const slidesCurrent = elementChildren(wrapperElRef.current, `.${carousel.current.slideClass}`);
    const slidesLength = slidesCurrent?.length;
    let newSnapGrid = [];
    const newSlidesGrid = [];
    const newSlidesSizesGrid = [];

    const previousSnapGridLength = carousel.current.snapGrid.length;
    const previousSlidesGridLength = carousel.current.slidesGrid.length;
    let spaceBetweenCurrent = params.spaceBetween;
    let slidePosition = -0;
    let prevSlideSize = 0;
    let index = 0;

    if (typeof carousel.current.size === 'undefined') {
      return;
    }

    if (typeof spaceBetweenCurrent === 'string' && spaceBetweenCurrent.indexOf('%') >= 0) {
      spaceBetweenCurrent = parseFloat(spaceBetweenCurrent.replace('%', '')) / 100 * carousel.current.size;
    }

    virtualSize = -spaceBetweenCurrent;

    slidesCurrent?.forEach((slideEl) => {
      if (carousel.current.rtlTranslate) {
        slideEl.style.marginLeft = '';
      } else {
        slideEl.style.marginRight = '';
      }
      slideEl.style.marginBottom = '';
      slideEl.style.marginTop = '';
    });

    let slideSize;

    for (let i = 0; i < slidesLength; i += 1) {
      slideSize = 0;
      let slide;
      if (slidesCurrent?.[i]) slide = slidesCurrent[i];

      if (slidesCurrent?.[i] && elementStyle(slide, 'display') === 'none') continue;

      if (params.slidesPerView === 'auto') {

        const slideStyles = getComputedStyle(slide);
        const currentTransform = slide.style.transform;
        const currentWebKitTransform = slide.style.webkitTransform;

        if (currentTransform) {
          slide.style.transform = 'none';
        }

        if (currentWebKitTransform) {
          slide.style.webkitTransform = 'none';
        }

        if (params.roundLengths) {
          slideSize = params.direction === 'horizontal' ? slide.outerWidth(true) : slide.outerHeight(true);
        } else {
          const width = getDirectionPropertyValue(slideStyles, 'width');
          const paddingLeft = getDirectionPropertyValue(slideStyles, 'padding-left');
          const paddingRight = getDirectionPropertyValue(slideStyles, 'padding-right');
          const marginLeft = getDirectionPropertyValue(slideStyles, 'margin-left');
          const marginRight = getDirectionPropertyValue(slideStyles, 'margin-right');
          const boxSizing = slideStyles.getPropertyValue('box-sizing');

          if (boxSizing && boxSizing === 'border-box') {
            slideSize = width + marginLeft + marginRight;
          } else {
            const {
              clientWidth,
              offsetWidth
            } = slide[0];
            slideSize = width + paddingLeft + paddingRight + marginLeft + marginRight + (offsetWidth - clientWidth);
          }
        }

        if (currentTransform) {
          slide[0].style.transform = currentTransform;
        }

        if (currentWebKitTransform) {
          slide[0].style.webkitTransform = currentWebKitTransform;
        }

        if (params.roundLengths) slideSize = Math.floor(slideSize);
      } else {
        slideSize = (carousel.current.size - (params.slidesPerView - 1) * spaceBetweenCurrent) / params.slidesPerView;
        if (params.roundLengths) slideSize = Math.floor(slideSize);

        if (slidesCurrent[i]) {
          slidesCurrent[i].style[getDirectionLabel('width')] = `${slideSize}px`;
        }
      }

      if (slidesCurrent[i]) {
        slidesCurrent[i].carouselSlideSize = slideSize;
      }

      newSlidesSizesGrid.push(slideSize);

      if (params.centeredSlides) {
        slidePosition = slidePosition + slideSize / 2 + prevSlideSize / 2 + spaceBetweenCurrent;
        if (prevSlideSize === 0 && i !== 0) slidePosition = slidePosition - carousel.current.size / 2 - spaceBetweenCurrent;
        if (i === 0) slidePosition = slidePosition - carousel.current.size / 2 - spaceBetweenCurrent;
        if (Math.abs(slidePosition) < 1 / 1000) slidePosition = 0;
        if (params.roundLengths) slidePosition = Math.floor(slidePosition);
        if (index % params.slidesPerGroup === 0) newSnapGrid.push(slidePosition);
        newSlidesGrid.push(slidePosition);
      } else {
        if (params.roundLengths) slidePosition = Math.floor(slidePosition);
        if ((index - Math.min(params.slidesPerGroupSkip, index)) % params.slidesPerGroup === 0) newSnapGrid.push(slidePosition);
        newSlidesGrid.push(slidePosition);
        slidePosition = slidePosition + slideSize + spaceBetweenCurrent;
      }

      virtualSize += slideSize + spaceBetweenCurrent;
      prevSlideSize = slideSize;
      index += 1;
    }

    virtualSize = Math.max(virtualSize, carousel.current.size) + 0;

    if (carousel.current.rtlTranslate && carousel.current.wrongRTL && (params.effect === 'slide' || params.effect === 'coverflow')) {
      wrapperElRef.current.style.width = `${virtualSize + spaceBetweenCurrent}px`;
    }

    if (!params.centeredSlides) {
      const newSlidesGrid = [];

      for (let i = 0; i < newSnapGrid.length; i += 1) {
        let slidesGridItem = newSnapGrid[i];
        if (params.roundLengths) slidesGridItem = Math.floor(slidesGridItem);

        if (newSnapGrid[i] <= virtualSize - carousel.current.size) {
          newSlidesGrid.push(slidesGridItem);
        }
      }

      newSnapGrid = newSlidesGrid;

      if (Math.floor(virtualSize - carousel.current.size) - Math.floor(newSnapGrid[newSnapGrid.length - 1]) > 1) {
        newSnapGrid.push(virtualSize - carousel.current.size);
      }
    }

    if (newSnapGrid.length === 0) newSnapGrid = [0];

    if (spaceBetweenCurrent !== 0) {
      const key = params.direction === 'horizontal' && carousel.current.rtlTranslate ? 'marginLeft' : getDirectionLabel('marginRight');
      slidesCurrent?.filter((_, slideIndex) => {
        if (params.loop) return true;
        if (slideIndex === slidesCurrent?.length - 1) {
          return false;
        }
        return true;
      })
        .forEach((slideEl) => {
          slideEl.style[key] = `${spaceBetweenCurrent}px`;
        });
    }

    if (params.centeredSlides && params.centeredSlidesBounds) {
      let allSlidesSize = 0;
      newSlidesSizesGrid.forEach(slideSizeValue => {
        allSlidesSize += slideSizeValue + (params.spaceBetween ? params.spaceBetween : 0);
      });
      allSlidesSize -= params.spaceBetween;
      const maxSnap = allSlidesSize - carousel.current.size;
      newSnapGrid = newSnapGrid.map(snap => {
        if (snap < 0) return -0;
        if (snap > maxSnap) return maxSnap + 0;
        return snap;
      });
    }

    carousel.current.slides = slidesCurrent
    carousel.current.snapGrid = newSnapGrid
    carousel.current.slidesGrid = newSlidesGrid
    carousel.current.slidesSizesGrid = newSlidesSizesGrid


    if (slidesLength !== previousSlidesLength) {
      carousel.current.emit('slidesLengthChange');
    }

    if (newSlidesGrid.length !== previousSlidesGridLength) {
      carousel.current.emit('slidesGridLengthChange');
    }
  }
  const updateSize = () => {
    let widthCurrent;
    let heightCurrent;

    if (typeof params.newWidth !== 'undefined' && params.newWidth !== null) {
      widthCurrent = params.newWidth;
    } else {
      widthCurrent = carouselElRef.current?.clientWidth;
    }

    if (typeof params.newHeight !== 'undefined' && params.newHeight !== null) {
      heightCurrent = params.newHeight;
    } else {
      heightCurrent = carouselElRef.current?.clientHeight;
    }

    if (widthCurrent === 0 && params.direction === 'horizontal' || heightCurrent === 0 && params.direction === 'vertical') {
      return;
    }

    widthCurrent =
      widthCurrent -
      parseInt(elementStyle(carouselElRef.current, 'padding-left') || 0, 10) -
      parseInt(elementStyle(carouselElRef.current, 'padding-right') || 0, 10);
    heightCurrent =
      heightCurrent -
      parseInt(elementStyle(carouselElRef.current, 'padding-top') || 0, 10) -
      parseInt(elementStyle(carouselElRef.current, 'padding-bottom') || 0, 10);

    if (Number.isNaN(widthCurrent)) widthCurrent = 0;
    if (Number.isNaN(widthCurrent)) widthCurrent = 0;

    carousel.current.width = widthCurrent
    carousel.current.height = heightCurrent
    carousel.current.size = params.direction === 'horizontal' ? widthCurrent : heightCurrent
  }
  const updateProgress = (currentTranslate) => {

    if (typeof currentTranslate === 'undefined') {
      const multiplier = carousel.current.rtlTranslate ? -1 : 1;

      currentTranslate = carousel.current.translate && carousel.current.translate * multiplier || 0;
    }

    const translatesDiff = -carousel.current.snapGrid[carousel.current.snapGrid.length - 1] - -carousel.current.snapGrid[0];

    if (translatesDiff === 0) {
      carousel.current.progress = 0;
      carousel.current.isBeginning = true;
      carousel.current.isEnd = true;
    } else {
      carousel.current.progress = (currentTranslate - -carousel.current.snapGrid[0]) / translatesDiff;
      carousel.current.isBeginning = carousel.current.progress <= 0;
      carousel.current.isEnd = carousel.current.progress >= 1;
    }

    carousel.current.emit('progress', carousel.current.progress);
  }
  const updateSlidesClasses = () => {
    carousel.current.slides?.forEach((slideEl) => {
      slideEl.classList?.remove && slideEl.classList.remove(carousel.current.slideActiveClass, carousel.current.slideNextClass, carousel.current.slidePrevClass);
    });

    let activeSlide;

    activeSlide = carousel.current.slides?.[carousel.current.activeIndex];
    if (activeSlide) {
      activeSlide.classList?.add && activeSlide.classList.add(carousel.current.slideActiveClass);

      let nextSlide = elementNextAll(activeSlide, `.${carousel.current.slideClass}`)[0];
      if (params.loop && !nextSlide) {
        nextSlide = carousel.current.slides?.[0];
      }
      if (nextSlide) {
        nextSlide.classList?.add && nextSlide.classList.add(carousel.current.slideNextClass);
      }
      let prevSlide = elementPrevAll(activeSlide, `.${carousel.current.slideClass}`)[0];
      if (params.loop && !prevSlide === 0) {
        prevSlide = carousel.current.slides?.[carousel.current.slides?.length - 1];
      }
      if (prevSlide) {
        prevSlide.classList?.add && prevSlide.classList.add(carousel.current.slidePrevClass);
      }
    }
  }
  const updateActiveIndex = (newActiveIndex) => {
    const currentTranslate = carousel.current.rtlTranslate ? carousel.current.translate : -carousel.current.translate;
    let currentActiveIndex = newActiveIndex;
    let snapIndexCurrent;

    if (typeof currentActiveIndex === 'undefined') {
      for (let i = 0; i < carousel.current.slidesGrid.length; i += 1) {
        if (typeof carousel.current.slidesGrid[i + 1] !== 'undefined') {
          if (currentTranslate >= carousel.current.slidesGrid[i] && currentTranslate < carousel.current.slidesGrid[i + 1] - (carousel.current.slidesGrid[i + 1] - carousel.current.slidesGrid[i]) / 2) {
            currentActiveIndex = i;
          } else if (currentTranslate >= carousel.current.slidesGrid[i] && currentTranslate < carousel.current.slidesGrid[i + 1]) {
            currentActiveIndex = i + 1;
          }
        } else if (currentTranslate >= carousel.current.slidesGrid[i]) {
          currentActiveIndex = i;
        }
      }

      if (currentActiveIndex < 0 || typeof currentActiveIndex === 'undefined') currentActiveIndex = 0;
    }

    if (carousel.current.snapGrid.indexOf(currentTranslate) >= 0) {
      snapIndexCurrent = carousel.current.snapGrid.indexOf(currentTranslate);
    } else {
      const skip = Math.min(params.slidesPerGroupSkip, currentActiveIndex);
      snapIndexCurrent = skip + Math.floor((currentActiveIndex - skip) / params.slidesPerGroup);
    }

    if (snapIndexCurrent >= carousel.current.snapGrid.length) snapIndexCurrent = carousel.current.snapGrid.length - 1;

    if (currentActiveIndex === carousel.current.activeIndex) {
      if (snapIndexCurrent !== snapIndex) {
        snapIndex = snapIndexCurrent;
        carousel.current.emit('snapIndexChange');
      }

      return;
    }
    let currentRealIndex;
    if (carousel.current.slides?.[currentActiveIndex]?.getAttribute) {
      currentRealIndex = parseInt(
        carousel.current.slides?.[currentActiveIndex].getAttribute('data-carousel-slide-index') || currentActiveIndex,
        10,
      );
    } else {
      currentRealIndex = currentActiveIndex;
    }

    snapIndex = snapIndexCurrent
    realIndex = currentRealIndex
    carousel.current.previousIndex = carousel.current.activeIndex
    carousel.current.activeIndex = currentActiveIndex

    carousel.current.emit('activeIndexChange');
    carousel.current.emit('snapIndexChange');

    if (realIndex !== currentRealIndex) {
      carousel.current.emit('realIndexChange');
    }

    if (carousel.current.initialized || params.runCallbacksOnInit) {
      carousel.current.emit('slideChange');
    }
  }
  const update = () => {
    if (carousel.current.destroyed) return;

    updateSize();
    updateSlides();
    updateProgress();
    updateSlidesClasses();

    const setCurrentTranslate = () => {
      const translateValue = carousel.current.rtlTranslate ? carousel.current.translate * -1 : carousel.current.translate;
      const newTranslate = Math.min(Math.max(translateValue, -carousel.current.snapGrid[carousel.current.snapGrid.length - 1]), -carousel.current.snapGrid[0]);
      carousel.current.setTranslate(newTranslate);
      updateActiveIndex();
      updateSlidesClasses();
    }

    let translated;

    if ((params.slidesPerView === 'auto' || params.slidesPerView > 1) && carousel.current.isEnd && !params.centeredSlides) {
      translated = carousel.current.slideTo(carousel.current.slides?.length - 1, 0, false, true);
    } else {
      translated = carousel.current.slideTo(carousel.current.activeIndex, 0, false, true);
    }

    if (!translated) {
      setCurrentTranslate();
    }

    carousel.current.emit('update');
  }
  const removeClasses = () => {
    carouselElRef.current.classList.remove(...classNames);
  }
  const addClasses = () => {
    if (!support.touch) {
      carouselElRef.current.classList.add(carouselStyles.carouselPointerEvents);
    }
  }
  return { update, updateActiveIndex, updateProgress, updateSize, updateSlides, addClasses, removeClasses, updateSlidesClasses }
}
