import defaultStyles from './Datepicker.module.scss';
import InputMask from 'react-input-mask';
import { useEffect, useState } from 'react';
import { getClasses, stylesMerge } from 'utils/styles';
import DefaultLogo from 'components/DefaultLogo/DefaultLogo';


const Datepicker = ({
  disabled = false,
  error = ``,
  value = ``,
  handleDateChange = () => { },
  handleDateFocus = () => { },
  Logo = DefaultLogo,
  label = `Дата`,
  id = Date.now(),
  placeholder = `30.12.2000`,
  outsideStyle = null,
}) => {
  const [styles, setStyles] = useState(stylesMerge(defaultStyles, outsideStyle));
  const [classes, setClasses] = useState({});

  useEffect(() => {
    setStyles(stylesMerge(defaultStyles, outsideStyle));
  }, [outsideStyle]);

  useEffect(() => {
    setClasses({
      defaultClass: styles.datepicker,
      errorClass: error ? styles.datepickerError : ``,
      disabledClass: disabled ? styles.datepickerDisabled : ``,
    });
  }, [disabled, error, styles.datepicker, styles.datepickerError, styles.datepickerDisabled]);

  return (
    <div className={getClasses(classes).join(` `)}>
      {label !== `` && <label htmlFor={id} className={styles.label}>{label}&nbsp;</label>}
      <div style={{ display: "flex", flexDirection: "column", color: "#CA3C3C", fontSize: "0.75rem" }}>
        <div className={styles.pseudoWrap}>
          <Logo className={styles.icon} />
          <InputMask
            id={id}
            className={styles.datepickerInput}
            mask={`99.99.9999`}
            maskChar={null}
            value={value}
            onChange={handleDateChange}
            onFocus={handleDateFocus}
            placeholder={placeholder}
          />
        </div>
        {error.length ? <span>{error}</span> : null}
      </div>
    </div>
  );
};

export default Datepicker;
