import { ReactComponent as CalendarIcon } from 'images/profile/profile-calendar.svg';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useValidationMail } from 'customHooks/useValidationMail';
import { resetSelect } from 'customHooks/resetSelect';
import { Operation } from 'reducer/profile/profile';
import { ActionCreator } from 'reducer/validation/validation';
import { getLoginError } from 'reducer/validation/selectors';
import { getChangeEmailStatus } from 'reducer/profile/selectors';
import { getLSValue } from 'utils/storage';
import Input from 'components/Forms/Input/Input';
import Status from '../Status/Status';
import { MESSAGES } from 'const/messages';
import { useTranslation } from 'react-i18next';
import { getIsRealEmail } from 'reducer/user/selectors';
import { Operation as UserOperation } from 'reducer/user/user';
import { ActionCreator as UserActionCreator } from 'reducer/user/user';

const Email = ({ styles }) => {
  const [userObj] = useState(JSON.parse(localStorage.userObj));
  const [email, isValidMail, handleInputChange, handleFocus] = useValidationMail(userObj.email);
  const [isRealEmailError, setIsRealEmailError] = useState("");

  const dispatch = useDispatch();
  const emailRef = useRef();
  const loginError = useSelector(state => getLoginError(state));
  const isRealEmail = useSelector(state => getIsRealEmail(state));
  const changeEmailStatus = useSelector(state => getChangeEmailStatus(state));
  const { t } = useTranslation();

  const handleOnBlur = () => {
    if (isValidMail && email) {
      setIsRealEmailError("");
      dispatch(UserOperation.verifyIsRealEmail({ email }));
    }
  };

  useEffect(() => {
    if (!isRealEmail) {
      setIsRealEmailError("Укажите действительный адрес");
    }
    return () => {
      dispatch(UserActionCreator.setIsRealEmail(true));
    };
  }, [isRealEmail]);

  useEffect(() => {
    return () => dispatch(ActionCreator.setLoginError(``));
  }, [dispatch]);

  // обновление или добавление емейла
  // при совпадении старого и нового - появляется ошибка
  // при невалидном тоже самое
  const handleSubmit = (evt) => {
    if (isValidMail && email !== userObj.email) {
      dispatch(Operation.setNewMail({ email }));
    } else if (email === userObj.email) {
      dispatch(ActionCreator.setLoginError(t('lk.email.errorMessage')));
    } else {
      if (!isValidMail) resetSelect(emailRef.current);
    }
  };

  return (
    <div className={`${styles.formsWrap}`}>
      {/* ${styles.formsWrapDev} */}
      <div className={styles.formsWrapGrid}>
        <div className={styles.fieldWrapper}>
          {changeEmailStatus.length ?
            <Status status={changeEmailStatus} styles={styles} /> : null}
          <Input
            onBlur={handleOnBlur}
            error={loginError || isRealEmailError}
            handleFieldChange={handleInputChange}
            handleFocus={handleFocus}
            Logo={CalendarIcon}
            label={t('lk.email.email')} name={`email`} id={`profile-email`}
            ref={emailRef}
            defaultValue={email}
            valid={changeEmailStatus}
          />
        </div>
        <button
          type="button"
          className={`${styles.settingBtn} ${styles.settingBtnSec}`}
          onClick={handleSubmit}
        >
          {// добавление статуса подтверждения
            changeEmailStatus === MESSAGES.EMAIL_NOT_CONFIRM ? t('lk.email.confirm') :
              getLSValue(`userObj`, `email`) ? t('lk.email.change') : t('lk.email.add')
          }
        </button>

      </div>
    </div>
  );
};

export default Email;
