import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ActionCreator } from "reducer/validation/validation";

export const useValidationPhone = (initState, dispatchName = `setTelError`) => {
  const [phone, setPhone] = useState(initState);
  const [isValidPhone, setValidPhone] = useState(initState ? true : false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (phone && phone.length === 12) {
      setValidPhone(true);
      dispatch(ActionCreator[dispatchName](``));
    } else if (!phone) {
      setValidPhone(false);
      dispatch(ActionCreator[dispatchName](``));
    } else {
      setValidPhone(false);
      dispatch(ActionCreator[dispatchName](t("lk.relship.telError")));
    }
  }, []);

  const handlePhoneChange = (phone) => {
    setPhone(phone);

    if (phone && phone.length === 12) {
      setValidPhone(true);
      dispatch(ActionCreator[dispatchName](``));
    } else if (!phone) {
      setValidPhone(false);
      dispatch(ActionCreator[dispatchName](``));
    } else {
      setValidPhone(false);
      dispatch(ActionCreator[dispatchName](t("lk.relship.telError")));
    }
  };

  const handleFocus = (evt) => {
    evt.target.classList.remove("error-reg");
    dispatch(ActionCreator[dispatchName](``));
  };

  return [
    phone,
    isValidPhone,
    handlePhoneChange,
    handleFocus,
    setPhone,
    setValidPhone,
  ];
};
