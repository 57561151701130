import NameSpace from '../name-space';

const NAME_SPACE = NameSpace.ORDERS;

export const getAllOrders = (state) => state[NAME_SPACE].orders;

export const getOrderById = (state, public_id) => {
  return state[NAME_SPACE].orders.find(el => el.public_id === public_id)
}

export const getShops = (state) => state[NAME_SPACE].shops

export const getIsLoadOrders = (state) => state[NAME_SPACE].isLoadOrders