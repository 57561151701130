import NameSpace from '../name-space';

const NAME_SPACE = NameSpace.SHOP;

export const getShopItem = (state, page) => {
  switch (page) {
    case `basket`:
      return state[NAME_SPACE].itemsBasket;
    case `new-shop`:
      return state[NAME_SPACE].goods;
    default:
      return state[NAME_SPACE].itemsHome;
  }
}

export const getPreviewShopItem = (state, page) => {
  switch (page) {
    case `basket`:
      return state[NAME_SPACE].itemsBasket;
    case `new-shop`:
      return state[NAME_SPACE].previewGoods;
    default:
      return state[NAME_SPACE].itemsHome;
  }
}

export const getOneItem = (state, id) => {
  return state[NAME_SPACE].goods.find(el => el.public_id === id)
}

export const getCollection = (state, name) => {
  return state[NAME_SPACE].collections.find(el => el.title === name)
}

export const getFilteredGoods = (state, option) => {
  switch (option) {
    case `category`:
      return state[NAME_SPACE].filteredGoods.byCategory;
    case `subgroup`:
      return state[NAME_SPACE].filteredGoods.bySubgroup;
    default:
      return state[NAME_SPACE].filteredGoods.byCategory;
  }
}