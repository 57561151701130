import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Operation } from 'reducer/profile/profile';


const Subscriptions = ({ styles }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const userObj = localStorage.getItem("userObj") ? JSON.parse(localStorage?.userObj) : {};

    const [isChecked, setChecked] = useState(userObj?.mailing);

    const handleAdChange = (e) => {
        const checked = e.target.checked;
        setChecked(checked);

        dispatch(Operation.setNewData({ mailing: checked }));

    };


    return (
        <div className={styles.formsWrap}>
            <span className={styles.authTitle}>{t('lk.subscriptions.title')}</span>
            <div className={styles.checkboxes}>
                <div className={styles.secondCheckbox}>
                    <input
                        className={`visually-hidden`}
                        id="reg-ad-mailing"
                        name="mailing"
                        type="checkbox"
                        onChange={handleAdChange}
                        checked={isChecked}
                    />
                    <label
                        className={styles.checkboxLabel}
                        htmlFor="reg-ad-mailing"
                    >
                        Рекламные и акционные <a style={{ textDecoration: "underline" }} href="https://heroleague.ru/api/files/document/advertising">предложения</a>
                    </label>
                </div>
                {/* <div className={styles.secondCheckbox}>
                    <input
                        className={`visually-hidden`}
                        id="reg-news-mailing"
                        name="mailing"
                        type="checkbox"
                        onChange={handleNewsChange}
                    />
                    <label
                        className={styles.checkboxLabel}
                        htmlFor="reg-news-mailing"
                    >
                        Новости
                    </label>
                </div> */}
            </div>
        </div>
    );
};

export default Subscriptions;
