import { useRef, useState } from 'react';
import { ReactComponent as LogoTel } from 'images/svg/form-tel.svg';
import Input from 'components/Forms/Input/Input';
import InputPhone from 'components/Forms/Input/InputPhone';
import { useValidationPhone } from 'customHooks/useValidationPhone';
import { useDispatch, useSelector } from 'react-redux';
import { getCodeError, getTelError } from 'reducer/validation/selectors';
import { resetSelect } from 'customHooks/resetSelect';
import { Operation, ActionCreator } from 'reducer/profile/profile';
import { getChangePhoneStatus, getViewCodeParam } from 'reducer/profile/selectors';
import { useValidationCode } from 'customHooks/useValidationCode';
import { getLSValue } from 'utils/storage';
import Status from '../Status/Status';
import { useTranslation } from 'react-i18next';


const Phone = ({styles}) => {
  const [userObj] = useState(JSON.parse(localStorage.userObj));
  const [phone, isValidTel, handlePhoneChange, handlePhoneFocus] = useValidationPhone(userObj.phone && `+${userObj.phone}`);
  const [code, isValidCode, handleCodeChange, handleCodeFocus] = useValidationCode(``);
  const dispatch = useDispatch();
  const phoneRef = useRef();
  const codeRef = useRef();
  const telError = useSelector(state => getTelError(state));
  const codeError = useSelector(state => getCodeError(state));
  const viewCodeField = useSelector(state => getViewCodeParam(state));
  const changePhoneStatus = useSelector(state => getChangePhoneStatus(state));
  const { t } = useTranslation();

  // с телефоном стандартная история
  // получение кода подтверждения нового телефона
  // и отсылка нового кода и телефона на сервер для смены
  const handleCodeSend = (evt) => {
    if (isValidTel) {
      // у телефона удаляется значение "+" в строке
      const phoneSlice = phone.slice(1);
      dispatch(Operation.sendNewPhoneCode({ phone: phoneSlice }));
    } else {
      if (!isValidTel) resetSelect(phoneRef.current);
    }
  }

  const handleInputCode = (evt) => {
    handleCodeChange(evt);

    if (isValidCode.current && isValidTel) {
      dispatch(Operation.setNewPhone({phone: phone.slice(1), phone_code: code.current}));
    }
  }

  const handleResetChange = () => {
    dispatch(ActionCreator.setViewCodeField(``));
    dispatch(ActionCreator.changePhoneStatus(``));
  }

  return (
    <div className={styles.formsWrap}>
      <div className={styles.formsWrapGrid}>
        <div className={styles.fieldWrapper}>
          {changePhoneStatus.length ?
            <Status message={changePhoneStatus} styles={styles} /> : null}
          <InputPhone
            country="RU"
            international={true}
            withCountryCallingCode={true}
            error={telError}
            label={t('lk.phone.tel')}
            id="change-tel" name="phone"
            value={phone}
            onFocus={handlePhoneFocus}
            onChange={handlePhoneChange}
            ref={phoneRef}
            disabled={viewCodeField}
            valid={changePhoneStatus}
          />
          {
            viewCodeField &&
            <button
              className={styles.changePhoneBtn}
              onClick={handleResetChange}
            >
              {t('lk.phone.changeText')}
            </button>
          }
        </div>

      {
        !viewCodeField ?
        <button
          className={`${styles.settingBtn} ${styles.settingBtnSec}`}
          type="button"
          onClick={handleCodeSend}>
            {getLSValue(`userObj`, `phone`) ? t('lk.phone.change') :t('lk.phone.add')}
        </button> :
        <>
          <Input
            error={codeError}
            handleFieldChange={handleInputCode}
            handleFocus={handleCodeFocus}
            Logo={LogoTel}
            label={t('lk.phone.codeText')} name={`code`} id={`profile-code`}
            ref={codeRef}
          />
          <button
            className={`${styles.settingBtn} ${styles.settingBtnCodeAgain}`}
            type="button"
            onClick={handleCodeSend}
          >
            {t('lk.phone.codeAgain')}
          </button>
        </>
      }

      </div>
    </div>
  )
}

export default Phone;
