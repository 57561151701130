export const transition = ({ wrapperElRef, carousel }) => {

  const transitionStart = (runCallbacks, direction) => {
    if (runCallbacks === void 0) {
      runCallbacks = true;
    }

    transitionEmit({
      runCallbacks,
      direction,
      step: 'Start'
    });
  }
  const transitionEnd = (runCallbacks, direction) => {
    if (runCallbacks === void 0) {
      runCallbacks = true;
    }

    carousel.current.animating = false;
    setTransition(0);
    transitionEmit({
      runCallbacks,
      direction,
      step: 'End'
    });
  }
  const transitionEmit = ({
    runCallbacks,
    direction,
    step }) => {
    let dir = direction;

    if (!dir) {
      if (carousel.current.activeIndex > carousel.current.previousIndex) dir = 'next'; else if (carousel.current.activeIndex < carousel.current.previousIndex) dir = 'prev'; else dir = 'reset';
    }

    carousel.current.emit(`transition${step}`);

    if (runCallbacks && carousel.current.activeIndex !== carousel.current.previousIndex) {
      if (dir === 'reset') {
        carousel.current.emit(`slideResetTransition${step}`);
        return;
      }

      carousel.current.emit(`slideChangeTransition${step}`);

      if (dir === 'next') {
        carousel.current.emit(`slideNextTransition${step}`);
      } else {
        carousel.current.emit(`slidePrevTransition${step}`);
      }
    }
  }
  const setTransition = (duration) => {
    if (wrapperElRef.current) {
      wrapperElRef.current.style.transitionDuration = `${duration}ms`;
    }

    carousel.current.emit('setTransition', duration);
  }
  return { setTransition, transitionStart, transitionEnd }
}
