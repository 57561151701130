import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Operation } from 'reducer/user/user';
import { getRecLoginError } from 'reducer/validation/selectors';
import { ReactComponent as ReactLogoMail } from 'images/svg/form-mail.svg';
import { resetSelect } from 'customHooks/resetSelect';
import { useValidationMail } from 'customHooks/useValidationMail';
import Input from 'components/Forms/Input/Input';


const FormRecovery = ({styles, closePopup, setSwitchAuth}) => {
  const [email, isValidMail, handleChange, handleFocus] = useValidationMail(``, `setRecLoginError`);

  const dispatch = useDispatch();
  const mailRef = useRef();
  const loginError = useSelector((state) => getRecLoginError(state));

  const handleSubmit = (evt) => {
    evt.preventDefault();

    if (isValidMail) {
      dispatch(Operation.resetPassword({email}));
    } else {
      resetSelect(mailRef.current);
    }
  }

  const handleAuthClick = () => {
    closePopup();
    setSwitchAuth(false);
  }

  return (
    <form action="" onSubmit={handleSubmit}>
      <Input
        error={loginError}
        handleFieldChange={handleChange}
        handleFocus={handleFocus}
        Logo={ReactLogoMail}
        label={`Ваш email`}
        name={`email`}
        id={`recovery-email`}
        ref={mailRef}
      />
      <button className={styles.submitBtn} type="submit">Отправить</button>
      <button className={styles.authSmsBtn} type="button" onClick={handleAuthClick}>Войти по СМС</button>
    </form>
  );
}

export default FormRecovery;