import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import styles from "./Goods.module.scss";
import { ActionCreator } from "reducer/basket/basket";
import { ActionCreator as PaymentActionCreator } from "reducer/payment/payment";
import {
  getPromocodeErrorByType,
  getPromocodeSaleByType,
} from "reducer/payment/selectors";
import { getEventGoodsById } from "reducer/data/selectors";
import { Operation as BasketOperation } from "reducer/basket/basket";
import { Operation as TicketOperation } from "../../../reducer/tickets/tickets";
import { Operation as DataOperation } from "reducer/data/data";
import { ReactComponent as SaleLogo } from "images/basket/promocode.svg";
import { ReactComponent as CheckLogo } from "images/basket/check.svg";
import { ReactComponent as WarnLogo } from "images/basket/warnlogo.svg";
import OptionalCard from "../OptionalCard/OptionalCard";
import { Operation } from "reducer/payment/payment";
import { BASKET_COUNT } from "const/const";
import { updateLocalStorage } from "utils/utils";
import { getTotalCost, setCostItem, setDiscountPrice } from "utils/goods";
import { getAllTickets } from "reducer/tickets/selectors";
import CustomSelect from "components/Calendar/Select/Select";
import { setCount } from "mocks/calendarOptions";
import { getInsurancesListById } from "reducer/basket/selectors";
import { InsuranceForm } from "./InsuranceForm/InsuranceForm";
import "../../Calendar/Select/Select.scss";
import { getActualGoodsUpdateData, organizeGoods } from "../Basket";
import { getDelivery } from "reducer/basket/selectors";
import GoodsForm from "./GoodsForm";
import basketStyles from "../Basket.module.scss";
import Message from "components/Auth/Registration/Message/Message";

const Goods = React.memo(({ item, handleChange, groups = {} }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const itemRegions = item.regions ?? null;
  const [selectedInsurance, setSelectedInsurance] = useState(null);
  const [userObj] = useState(localStorage.getItem("userObj") ? JSON.parse(localStorage.getItem("userObj")) : {});
  const [isLoading, setIsLoading] = useState(true);
  const [isErrors, setErrors] = useState({
    date: ``,
    phone: ``,
    gender: ``,
    file: "",
    size: "",
    region: "",
    license: ""
  });
  const [activeTab, setActiveTab] = useState({ [item.groupId]: item.unique_id });
  const currentGroup = groups[item.groupId] ?? [];
  const selectedItem = currentGroup?.find((el) => el.unique_id === activeTab.unique_id) ?? item;
  const promocode = useSelector((state) => getPromocodeSaleByType(state, item));
  const promocodeError = useSelector((state) =>
    getPromocodeErrorByType(state, item)
  );
  const delivery = useSelector((state) => getDelivery(state)) ?? [];
  const insurancesByIdList = useSelector((state) => getInsurancesListById(state, item.unique_id)) ?? [];
  const insurancesList = insurancesByIdList?.insurances || [];
  const eventGoods = useSelector((state) => getEventGoodsById(state, item.event_city?.public_id)) ?? null;;
  const allTickets = useSelector((state) => getAllTickets(state));

  const [deliveryMethod, setDeliveryMethod] = useState(null);
  const promocodeRef = useRef();
  const [isSecondName, setIsSecondName] = useState(item.insurance || item.second_name);

  const [promocodeApplied, setPcApplied] = useState(false);
  /*   const userSecondName = item.info?.second_name || userObj?.second_name || "";
    const [
      secondName,
      isValidSecondName,
      secondNameError,
      setSecondNameError,
      handleSecondNameChange,
      handleSecondNameFocus,
      setSecondName,
    ] = useValidationText(userSecondName); */

  const handleTabChange = (groupId, unique_id) => {
    setActiveTab((prev) => ({ ...prev, [groupId]: unique_id, unique_id }));
  };
  const handleSelectInsurance = (event) => {
    if (JSON.parse(event.target.value)) {
      const selectedInsurance = JSON.parse(event.target.value);
      setSelectedInsurance(selectedInsurance);
      const unique_id = new Date().getTime() + selectedInsurance.public_id;

      const insurance = {
        ...selectedInsurance,
        selector: "insurance",
        count: 1, //item.count,
        totalCost: selectedInsurance.price,//item.count * selectedInsurance.price,
        format_public_id: selectedInsurance.public_id,
        format_id: item.format_public_id,
        format_start_time: item.start_time,
        unique_id,
        start_date: item.start_time,
        isScrolling: true
      };

      updateLocalStorage('basketGoods', 'add', insurance);
      dispatch(ActionCreator.addGoods(insurance));
    }
    /*     const goods = localStorage.getItem("basketGoods") && JSON.parse(localStorage.getItem("basketGoods"));
        dispatch(BasketOperation.updateUserBasketGoods(getActualGoodsUpdateData(goods), () => { })); */
  };

  const usedTicket = allTickets.find(
    (el) =>
      el.event_format.public_id === item.public_id && item.selector === "ticket"
  );

  const isVipUser = userObj?.role && typeof userObj?.role !== "string" && userObj.role.find((el) => el === "vip"); // Проверка относится ли user к категории vip

  useEffect(() => {
    if (itemRegions || item.info?.region) {
      dispatch(DataOperation.loadDeliveryRegions());
    }
  }, []);

  useEffect(() => {
    setPcApplied(!!(promocode && promocode.active));
  }, [promocode, item]);

  useEffect(() => {
    if (eventGoods) {
      const newObj = { ...item, eventGoods: eventGoods?.data };

      dispatch(ActionCreator.updateGoods(newObj));
      updateLocalStorage('basketGoods', 'add', newObj);
    }
  }, [eventGoods]);

  useEffect(() => {
    if (item.start_time > Date.now()) {
      deleteGoodsHandler(item);
    }
  }, []);

  useEffect(() => {

    if (item.delivery && delivery.length && !deliveryMethod) {

      let newObj = {
        ...item,
        delivery_method: true
      };

      if (item.selector === "shop") {
        const filteredDelivery = delivery?.filter((el) => el?.pickup);

        if (filteredDelivery.length === delivery.length) {
          newObj = {
            ...newObj,
            isHiddenDeliveryAddres: true
          };
        } else {
          newObj = {
            ...newObj,
            isHiddenDeliveryAddres: false
          };
        }
      }

      updateLocalStorage('basketGoods', 'add', newObj);
      dispatch(ActionCreator.updateGoods(newObj));
      handleChange();

      setErrors((prev) => ({ ...prev, deliveryMethod: 'Выберите способ доставки' }));

    } else if (item.delivery && delivery.length && deliveryMethod?.value) {
      setErrors((prev) => ({ ...prev, deliveryMethod: '' }));

      const currentDelivery = delivery?.find((el) => el?.public_id === deliveryMethod?.value);

      const newObj = {
        ...item,
        currentPickupSelect: currentDelivery?.pickup,
        delivery_public_id: deliveryMethod.value
      };
      updateLocalStorage('basketGoods', 'add', newObj);
      dispatch(ActionCreator.updateGoods(newObj));
      handleChange();
    }

  }, [deliveryMethod, delivery]);


  useEffect(() => {
    if (isVipUser && !allTickets.length) {
      dispatch(TicketOperation.loadTickets());
    }
  }, [isVipUser, allTickets, dispatch]);

  useEffect(() => {
    if (item.delivery) {
      if (item.selector === "shop") {
        dispatch(
          BasketOperation.getDeliveryByItemType(item.type)
        );
      } else {
        dispatch(
          BasketOperation.getDeliveryByItemType(item.selector)
        );
      }
    }
  }, []);

  const handlePromocodeCheck = () => {
    const value = promocodeRef.current.value;

    if (value.length > 0) {
      const codeInfo = { code: value, ...item, promocode: value };

      dispatch(Operation.promocode(codeInfo));

      dispatch(ActionCreator.addGoods(codeInfo));
      updateLocalStorage(`basketGoods`, `add`, codeInfo);


      const goods = localStorage.getItem("basketGoods") && JSON.parse(localStorage.getItem("basketGoods"));

      dispatch(BasketOperation.updateUserBasketGoods(goods, () => { }));
    } else {
      dispatch(
        PaymentActionCreator.setPromocodeError({
          message: `Введите промокод`,
          ...item,
        })
      );
    }
  };

  const handlePromocodeChange = () => {
    dispatch(PaymentActionCreator.removePromocodes(item));
  };

  const promocodeFocusHandler = () => {
    dispatch(PaymentActionCreator.removePromocodeError(item));
  };

  const basketTicketHandler = (evt) => {
    evt.preventDefault();
    const target = evt.target.dataset.ticketBtn;

    let count = item.count;

    let ticketLeft = item.tickets_left?.athlete;

    if (item.kind) {
      ticketLeft = item.tickets_left?.[item.kind];
    }

    if (promocode?.kind) {
      ticketLeft = item.tickets_left?.[promocode?.kind];
    }

    let min_count = /* item.buy_limit ? item.buy_limit : */ BASKET_COUNT.min_count;
    let max_count =
      item?.buy_limit ||
      ticketLeft ||
      BASKET_COUNT.max_count;

    if (item.team_all) {
      min_count =
        item.fixed_count === false ? BASKET_COUNT.min_count : item.max_count;
      max_count = item.max_count * BASKET_COUNT.max_count;
    }
    let totalCost = item.totalCost;

    if (target === 'increment') {

      count = count >= max_count ? max_count : count + min_count;

      if (min_count > 1 && count >= min_count && item.preregistration) {
        if (item?.buy_limit && groups?.[item.groupId]?.length >= item?.buy_limit) {
          return;
        }
        // const groupId = `group_${new Date().getTime()}`; // Добавляем пока в общую группу

        Array.from({ length: min_count }).forEach((_, index) => {
          dispatch(BasketOperation.addGoods({ ...item, info: {}, cluster: null, preReg: "", license: null, select_field: null, file: null, licenseFile: null, isErrorTab: true, unique_id: new Date().getTime() + index + item.public_id, count: 1, groupId: item.groupId }, () => { }, false));
        });

        const goods = localStorage.getItem("basketGoods") && JSON.parse(localStorage.getItem("basketGoods"));
        dispatch(BasketOperation.updateUserBasketGoods(getActualGoodsUpdateData(goods), () => { }));
        return;
      }
      else if (item.preregistration) {
        if (item?.buy_limit && groups?.[item.groupId]?.length >= item?.buy_limit) {
          return;
        }
        dispatch(BasketOperation.addGoods({ ...item, info: {}, cluster: null, preReg: "", license: null, select_field: null, file: null, licenseFile: null, isErrorTab: true, unique_id: new Date().getTime() + item.public_id, count: 1 }, () => { }));
        const goods = localStorage.getItem("basketGoods") && JSON.parse(localStorage.getItem("basketGoods"));
        const { groups: groupdObj } = organizeGoods(goods);
        const currentGroup = groupdObj[item.groupId] ?? [];

        setActiveTab(prev => ({ ...prev, [item.groupId]: currentGroup[currentGroup.length - 1]?.unique_id }));

        return;
      }

      if (item?.buy_limit && item.count >= item?.buy_limit) {
        return;
      }
    } else if (target === 'decrement') {
      count = count <= min_count ? min_count : count - min_count;
      if (min_count > 1 && count >= min_count && item.preregistration) {
        deleteGoodsHandler(selectedItem, min_count);

        setActiveTab(prev => ({ ...prev, [item.groupId]: item.unique_id }));
        return;
      } else if (item.preregistration && currentGroup.length) {
        deleteGoodsHandler(currentGroup[currentGroup.length - 1]);
        setActiveTab(prev => ({ ...prev, [item.groupId]: item.unique_id }));
        return;
      }
    }
    totalCost =
      isVipUser && usedTicket === undefined && item.selector === "ticket"
        ? getTotalCost(
          setDiscountPrice(
            item.price,
            userObj.discount,
            userObj.discount_form
          ),
          1,
          item.insurance && item.insurance_cost
        ) +
        getTotalCost(
          item.price,
          count - 1,
          item.insurance && item.insurance_cost
        )
        : getTotalCost(
          item.price,
          count,
          item.insurance && item.insurance_cost
        );

    let newObj = { ...item, totalCost };

    if (count >= 0) {
      newObj = { ...newObj, totalCost, count };
    }
    dispatch(ActionCreator.updateGoods(newObj));
    updateLocalStorage(`basketGoods`, `add`, newObj);
    const goods = localStorage.getItem("basketGoods") && JSON.parse(localStorage.getItem("basketGoods"));
    dispatch(BasketOperation.updateUserBasketGoods(getActualGoodsUpdateData(goods), () => { }));
  };
  // удаление товара, как из LS, так и из Store
  const deleteGoodsHandler = (item, min_count = 0) => {
    if (item.public_id === "18f88686a3ae3f4eebf08cfae807a6c58635ac1f") {
      const goods = localStorage.getItem("basketGoods") && JSON.parse(localStorage.getItem("basketGoods"));
      const medal = goods?.find((el) => el.public_id === "190ba3238ce08da60220668278b2f92c68e967e7");
      if (medal) {
        dispatch(BasketOperation.removeShop(medal));
        dispatch(PaymentActionCreator.removePromocodes(medal)); // del promocode if exists
        dispatch(ActionCreator.removeCurrentGoods(medal));
      }
    }
    /*  else if (item.eventGoods) {
       const goods = localStorage.getItem("basketGoods") && JSON.parse(localStorage.getItem("basketGoods"));
       item.eventGoods?.forEach((el) => {
         goods.forEach((elem) => {
           if (el?.public_id === elem?.public_id) {
             dispatch(BasketOperation.removeShop(elem));
             dispatch(PaymentActionCreator.removePromocodes(elem));
             dispatch(ActionCreator.removeCurrentGoods(elem));
           }
         });
       });
     } */

    if (min_count) {
      Array.from({ length: min_count }).forEach((_, index) => {
        dispatch(BasketOperation.removeShop(currentGroup[currentGroup.length - 1 - index], () => { }, false));
        dispatch(PaymentActionCreator.removePromocodes(currentGroup[currentGroup.length - 1 - index]));
        dispatch(ActionCreator.removeCurrentGoods(currentGroup[currentGroup.length - 1 - index]));
      });
    } else {
      dispatch(BasketOperation.removeShop(item, () => { }, false));
      dispatch(PaymentActionCreator.removePromocodes(item));
      dispatch(ActionCreator.removeCurrentGoods(item));
    }

    const goods = localStorage.getItem("basketGoods") && JSON.parse(localStorage.getItem("basketGoods"));
    dispatch(BasketOperation.updateUserBasketGoods(getActualGoodsUpdateData(goods), () => { }));
  };

  /* // старые полисы  const handleButtonRemoveInsurance = () => {
      if (!item.second_name) {
        setIsSecondName(false);
      }
      setSecondName(userSecondName);
      handleSecondNameChange({ target: { value: userSecondName } });
      const newObj = {
        ...item,
        insurance: false,
        totalCost: getTotalCost(item.price, item.count),
        preReg: "",
        secondName: userSecondName
      };
      handleChange();
      dispatch(ActionCreator.updateGoods(newObj));
      updateLocalStorage(`basketGoods`, `add`, newObj);
      const goods = localStorage.getItem("basketGoods") && JSON.parse(localStorage.getItem("basketGoods"));
      dispatch(BasketOperation.updateUserBasketGoods(getActualGoodsUpdateData(goods), () => { }));
    };
    const handleButtonAddInsurance = () => {
      setIsSecondName(true);
      setSecondName(userSecondName);
      handleSecondNameChange({ target: { value: userSecondName } });
      const newObj = {
        ...item,
        insurance: true,
        totalCost: getTotalCost(item.price, item.count, item.insurance_cost),
        preReg: ""
      };
      handleChange();
      dispatch(ActionCreator.updateGoods(newObj));
      updateLocalStorage(`basketGoods`, `add`, newObj);
      if (isSecondName && !isValidSecondName) {
        setSecondNameError(`Заполните поле`);
        return;
      } else {
        setSecondNameError("");
      }
      const goods = localStorage.getItem("basketGoods") && JSON.parse(localStorage.getItem("basketGoods"));
      dispatch(BasketOperation.updateUserBasketGoods(getActualGoodsUpdateData(goods), () => { }));
    }; */

  const handleDeleteItemGoods = () => {


    let min_count = BASKET_COUNT.min_count;

    if (item.team_all) {
      min_count =
        item.fixed_count === false ? BASKET_COUNT.min_count : item.max_count;
    }

    let count = item.count <= min_count ? min_count : item.count - min_count;

    if (min_count > 1 && count >= min_count && item.preregistration) {

      deleteGoodsHandler(selectedItem, min_count);
      setActiveTab(prev => ({ ...prev, [item.groupId]: item.unique_id }));

    } else {
      deleteGoodsHandler(selectedItem);

      if (currentGroup[0]?.unique_id === selectedItem.unique_id) {
        const goods = localStorage.getItem("basketGoods") && JSON.parse(localStorage.getItem("basketGoods"));
        const { groups } = organizeGoods(goods);

        setActiveTab(prev => ({ ...prev, [item.groupId]: groups[item.groupId]?.[0]?.unique_id }));
      } else {
        setActiveTab(prev => ({ ...prev, [item.groupId]: item.unique_id }));
      }
    }
  };

  useEffect(() => {
    if (item.selector === "ticket" && item.event_city?.public_id && item.event?.public_id) {

      if (!eventGoods && item.event_city?.public_id) {
        dispatch(DataOperation.loadEventGoods(item.event_city?.public_id));
      }
      dispatch(
        BasketOperation.checkTicketsBeforePaymentByFormatId(item.public_id)
      );
      dispatch(BasketOperation.loadInsurancesList(item.format_public_id, item.unique_id));
      dispatch(
        DataOperation.loadSizeById({
          event_city_public_id: item.event_city?.public_id,
          event_public_id: item.event?.public_id,
        }, setIsLoading)
      );

    } else {
      if (!eventGoods && item.event_city?.public_id) {
        dispatch(DataOperation.loadEventGoods(item.event_city?.public_id));
      }
      if (item.event_city?.public_id && item.event?.public_id) {
        dispatch(
          DataOperation.loadSizeById({
            event_city_public_id: item.event_city?.public_id,
            event_public_id: item.event?.public_id,
          }, setIsLoading)
        );
      }
    }

    /*  return () => dispatch(DataActionCreator.clearSize([])); */
  }, []);

  return (
    <>
      <li className={styles.item}>
        <div className={styles.logoWrap}>
          <img className={styles.logo} src={item.logo} alt="Гонка Героев" />
        </div>
        <div className={styles.infoBlock}>
          {item.event && item.event.title && (
            <h3 className={styles.infoBlockTitle}>{item.event.title}</h3>
          )}
          {item.selector === "insurance" && <h3 className={styles.infoBlockTitle}>{item.name}</h3>}
          <div className={styles.insideItem}>
            <div className={styles.location}>
              <b>
                {item.selector === "shop" ? t("basket.good.name1.product")
                  : item.selector === "insurance" ? t("basket.good.name1.insurance")
                    : t("basket.good.name1.location")}
                :
              </b>
              <p className={styles.text}>
                {item.selector === "shop" || item.selector === "insurance" ? item.title : item.address}
              </p>
            </div>
            {item.selector !== "insurance" && <div className={styles.format}>
              <b>
                {item.selector === "shop"
                  ? item.size
                    ? `${t("basket.good.name2.size")} :`
                    : null
                  : `${t("basket.good.name2.format")} :`}
              </b>
              <p className={styles.text}>
                {item.selector === `shop` ? item.size : item.title}
              </p>
            </div>}
            {item.selector !== "insurance" && <div className={styles.count}>
              <b>{t("basket.good.count")}:</b>
              <div className={`${styles.text} ${styles.countText}`}>
                <button
                  type="button"
                  data-ticket-btn="increment"
                  className={styles.countBtn}
                  onClick={basketTicketHandler}
                >
                  +
                </button>
                <span>{item.preregistration ? groups?.[item.groupId]?.length : item.count}</span>
                <button
                  type="button"
                  data-ticket-btn="decrement"
                  className={styles.countBtn}
                  onClick={basketTicketHandler}
                >
                  -
                </button>
              </div>
            </div>}
            <div className={styles.costWrap}>
              <b>{t("basket.good.cost")}:</b>
              <p className={`${styles.cost} ${styles.totalCost}`}>
                {usedTicket === undefined &&
                  item.selector === "ticket" &&
                  userObj !== null
                  ? setCostItem(item, userObj).toFixed(0)
                  : (item.price * item.count).toFixed(0)}{" "}
                &#8381;
              </p>
            </div>
          </div>

          {item.selector === "ticket" && insurancesList.length ? <select
            className={styles.insuranceSelect}
            name="insurances"
            onChange={handleSelectInsurance}
            value={selectedInsurance || ""}
          >
            <option value="null">
              Добавить страховку
            </option>
            {insurancesList.map((el) => (
              <option
                key={el.public_id}
                value={JSON.stringify(el)}
              >
                {el.name}
              </option>
            ))}
          </select> : null}

          {/*  // старые полисы {item.insurance_available ?
            <div className={`${styles.insideItem} ${styles.insideItemInsurance}`}>
              <div className={`${styles.location} ${styles.locationInsurance}`}>
                <b>{t("basket.good.add")}:</b>
                <div style={{ marginTop: "5px" }}>

                </div>
                <p className={styles.text}>{t("basket.good.insurance")}</p>
              </div>
              <div className={styles.count}>
                <b>{t("basket.good.count")}:</b>

                <div className={styles.text}>
                  {item.insurance && <span>{item.count}</span>}
                  <button
                    onClick={
                      item.insurance
                        ? handleButtonRemoveInsurance
                        : handleButtonAddInsurance
                    }
                    className={`${item.insurance
                      ? styles.deleteInsuranceBtn
                      : styles.addInsuranceBtn
                      }`}
                  >
                    {item.insurance ? "Удалить" : "Добавить"}
                  </button>
                </div>

              </div>

              <div className={styles.costWrap}>
                <p className={styles.cost}>
                  {item.insurance && item.insurance_cost * item.count}
                  {item.insurance && " ₽"}
                </p>
              </div>

            </div>
            : null} */}

          {eventGoods && eventGoods.data.length && item.selector === "ticket"
            ? eventGoods.data.map((product, i) => (
              <OptionalCard
                key={`${product.public_id}${i}`}
                product={product}
                id={`${eventGoods?.id}${product?.public_id}`}
              />
            ))
            : null}
        </div>


        {item.groupId && groups[item.groupId] && (
          <div className={styles.tabsWrapper}>
            <div className={styles.tabs}>
              {groups[item.groupId].length === 1 ? null : groups[item.groupId].map((tabItem, index) => (
                <button
                  key={tabItem.unique_id}
                  className={`${styles.tab} ${activeTab[item.groupId] === tabItem.unique_id ? styles.activeTab : ''} ${tabItem.isErrorTab ? styles.tabError : ""}`}
                  onClick={() => handleTabChange(item.groupId, tabItem.unique_id)}
                >
                  Билет {index + 1}
                </button>
              ))}
            </div>

            <div className="tab-content">
              {groups?.[item.groupId]?.length && groups[item.groupId]
                .filter((group) => group.unique_id === activeTab[item.groupId] || (!activeTab[item.groupId] && group.unique_id === item.unique_id))
                .map((tabItem) => (
                  <div key={tabItem.unique_id}>
                    <GoodsForm activeTab={activeTab} isAddInsurance={isSecondName} isLoading={isLoading} item={tabItem} handleChange={handleChange} />
                  </div>
                ))}
            </div>
          </div>
        )}


        {delivery?.length > 0 && item.delivery ? <div style={{ marginTop: "20px", marginBottom: "20px" }} className={styles.cluster}>
          <CustomSelect
            error={isErrors.deliveryMethod}
            handleSelectChange={setDeliveryMethod}
            handleFocus={() => setErrors((prev) => ({ ...prev, deliveryMethod: "" }))}
            value={deliveryMethod || ""}
            prefix={"basketsize-select"}
            styles={styles}
            title={"Способ доставки"}
            name={"deliveryMethod"}
            options={setCount(delivery, "name", "public_id")}
            placeholder={"Выберите способ доставки"}
            closeMenuOnScroll={() => false}
          />
        </div> : null}
        {item.selector === "insurance" && <InsuranceForm handleChange={handleChange} insurance={item} />}

        {!item.preregistration && <div className={styles.addBlock}>

          <div
            className={`${styles.promocodeBtnWrap}
${promocodeError && !!promocodeError.message
                ? styles.promocodeBtnWrapError
                : ``
              }
${promocodeApplied ? styles.promocodeBtnWrapCheck : ``}`}
          >
            <div className={styles.promocodeWrap}>
              <SaleLogo className={styles.inputLogo} />
              <input
                className={styles.promocodeField}
                type="text"
                placeholder={t("basket.good.promocode.placeholder")}
                id={item.public_id}
                disabled={
                  promocodeApplied ||
                  (item.selector === "ticket" &&
                    usedTicket === undefined &&
                    isVipUser) ||
                  item.selector === "certificate"
                }
                ref={promocodeRef}
                onChange={() => { }}
                onFocus={promocodeFocusHandler}
                defaultValue={promocode && promocode.code}
              />

              {promocodeError && !!promocodeError.message && (
                <WarnLogo className={styles.checkLogo} />
              )}
              {promocodeApplied && <CheckLogo className={styles.checkLogo} />}
            </div>
            <button
              disabled={
                (item.selector === "ticket" &&
                  usedTicket === undefined &&
                  isVipUser) ||
                item.selector === "certificate"
              }
              className={styles.promocodeBtn}
              onClick={
                !promocodeApplied ? handlePromocodeCheck : handlePromocodeChange
              }
            >
              {!promocodeApplied
                ? t("basket.good.promocode.btnApply")
                : t("basket.good.promocode.btnChange")}
            </button>

            {promocodeError && !!promocodeError.message ? (
              <Message styles={styles} message={promocodeError.message} />
            ) : null}
          </div>

          <p className={basketStyles.certificate}>
            {t("basket.aside.ref")}
            <span className={basketStyles.certificateHelp}>
              Описание для мед справки
            </span>
          </p>
        </div>}

        <button onClick={handleDeleteItemGoods} className={styles.deleteItemBtn}>
          {t("basket.good.deleteBtn")}
        </button>
      </li>
    </>
  );
});

export default Goods;
