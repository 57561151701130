import { forwardRef, useState } from 'react';
import defaultStyles from './Radio.module.scss';
import { stylesMerge } from '../../../utils/styles';

const Radio = forwardRef((props, ref) => {
  const {
    disabled = ``,
    error = ``,
    initialValue,
    value,
    name,
    label,
    id,
    handleRadioChange,
    outsideStyle
  } = props;
  const [styles] = useState(stylesMerge(defaultStyles, outsideStyle));

  return (
    <div className={disabled ? styles.wrapDisabled : ``}>
      <input
        onChange={handleRadioChange}
        value={initialValue}
        checked={value === initialValue}
        className="visually-hidden"
        type="radio"
        name={name}
        id={id}
        ref={ref}
      />
      <label className={`${styles.radioLabel} ${error ? styles.labelError : ``}`} htmlFor={id}>{label}</label>
    </div>
  );
});

export default Radio;
