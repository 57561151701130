const initialState = {
  loginError: ``,
  recLoginError: ``,
  passwordError: ``,
  newPasswordError: ``,
  telError: ``,
  codeError: ``,
  extraContactTelError: ``,
};

export const ActionType = {
  SET_LOGIN_ERROR: `SET_LOGIN_ERROR`,
  SET_REC_LOGIN_ERROR: `SET_REC_LOGIN_ERROR`,
  SET_PASS_ERROR: `SET_PASS_ERROR`,
  SET_NEW_PASS_ERROR: `SET_NEW_PASS_ERROR`,
  SET_TEL_ERROR: `SET_TEL_ERROR`,
  SET_CODE_ERROR: `SET_CODE_ERROR`,
  SET_EXTRA_CONTACT_TEL_ERROR: `SET_EXTRA_CONTACT_TEL_ERROR`,
};

export const ActionCreator = {
  setLoginError: (error) => ({
    type: ActionType.SET_LOGIN_ERROR,
    payload: error
  }),

  setRecLoginError: (error) => ({
    type: ActionType.SET_REC_LOGIN_ERROR,
    payload: error
  }),

  setPassError: (error) => ({
    type: ActionType.SET_PASS_ERROR,
    payload: error
  }),

  setNewPassError: (error) => ({
    type: ActionType.SET_NEW_PASS_ERROR,
    payload: error
  }),

  setTelError: (error) => ({
    type: ActionType.SET_TEL_ERROR,
    payload: error
  }),

  setCodeError: (error) => ({
    type: ActionType.SET_CODE_ERROR,
    payload: error
  }),

  setExtraContactTelError: (error) => ({
    type: ActionType.SET_EXTRA_CONTACT_TEL_ERROR,
    payload: error
  }),
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.SET_LOGIN_ERROR:
      return {...state, loginError: action.payload};
    case ActionType.SET_REC_LOGIN_ERROR:
      return {...state, recLoginError: action.payload};
    case ActionType.SET_PASS_ERROR:
      return {...state, passwordError: action.payload};
    case ActionType.SET_NEW_PASS_ERROR:
      return {...state, newPasswordError: action.payload};
    case ActionType.SET_TEL_ERROR:
      return {...state, telError: action.payload};
    case ActionType.SET_CODE_ERROR:
      return {...state, codeError: action.payload};
    case ActionType.SET_EXTRA_CONTACT_TEL_ERROR:
      return {...state, extraContactTelError: action.payload};
    default:
      return state;
  }
}