import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'api';

export const bannerSlice = createApi({
  reducerPath: 'bannerSlice',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getBanners: builder.query({
      query: (banner_page) => `/api/page/banner/${banner_page}`,
    }),
    getProjectBanners: builder.query({
      query: () => '/api/event_type/project',
    }),
  }),
});

export const { useGetProjectBannersQuery, useGetBannersQuery } = bannerSlice;
