import styles from "./InsurancePopup.module.scss";
import Popup from "../Popup/Popup";
import CustomSelect from "components/Calendar/Select/Select";
import { setEventsOptions, setOptions } from "mocks/calendarOptions";
import { useEffect, useState } from "react";
import { ReactComponent as CustomArrow } from "images/svg/dropdownIcon.svg";
import { getActiveInsurances } from "reducer/basket/selectors";
import { useDispatch, useSelector } from "react-redux";
import { Operation, ActionCreator as BasketActionCreator } from "reducer/basket/basket";
import { Operation as DataOperation, ActionCreator } from "reducer/data/data";
import { useNavigate } from "react-router-dom";
import { routes } from "const/routes";
import { initDate, nextTwoYear } from "mocks/time";
import { getEventType, getFilteredEventsInsurance, getFormats } from "reducer/data/selectors";
import "../../Calendar/Select/Select.scss";
import "../../Calendar/Calendar.scss";
import Loader from "components/Auth/Loader/Loader";

const allYearEvents = {
  date_from: initDate,
  date_to: nextTwoYear,
  month: "Все",
};

const formatDate = (timestamp) => {
  const date = new Date(timestamp * 1000);
  return new Intl.DateTimeFormat("ru-RU", {
    day: "numeric",
    month: "long",
  }).format(date);
};

export const setInsuranceOptions = (arr) => {
  if (arr.length > 0) {
    return arr.map((el) => {
      return { value: el.public_id, label: `${formatDate(el.start_time)} - ${el.address}` };
    });
  }
  return [];
};

export const InsurancePopup = ({ statePopupHandler }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navigateTo = () => navigate(routes.basket.path, { replace: true });

  const [city, setCity] = useState("");
  const [activity, setActivity] = useState("");
  const [format, setFormat] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [event, setEvent] = useState(null);
  const [isEvents, setIsEvents] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [isLoadFormats, setIsLoadFormats] = useState(false);

  const [currentFormat, setCurrentFormat] = useState(null);
  const insurances = useSelector((state) => getActiveInsurances(state)) ?? [];
  const insuranceFormats = useSelector((state) => getFormats(state));
  const filteredEvents = useSelector((state) => getFilteredEventsInsurance(state));
  const eventTypes = useSelector((state) => getEventType(state));

  const insurance = insurances?.[0] ?? {};
  const handleActivityFilter = (evt, param) => {
    if (param === "city") {
      dispatch(BasketActionCreator.setActiveInsurancesList([]));
      setIsEvents(false);
      setCity(evt);
      setFormat("");
    }
    if (param === "activity") {
      dispatch(BasketActionCreator.setActiveInsurancesList([]));
      setIsEvents(false);
      const activity = eventTypes.find((el) => el.title === evt.value);
      setEvent(activity);
      setActivity(evt);
    }
    if (param === "format") {
      setIsEvents(false);
      const currentFormat = insuranceFormats.find((el) => el.public_id === evt.value);
      if (currentFormat) {
        setCurrentFormat(currentFormat);
      }
      setFormat(evt);

    }
  };

  const handleCheckChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const submit = () => {
    if (currentFormat.start_time) {

      const sendObj = {
        ...insurance,
        selector: 'insurance',
        totalCost: 1
          ? +(insurance.price * 1).toFixed(2)
          : insurance.price,
        count: 1,
        promocode: null,
        format_public_id: insurance.public_id,
        unique_id: new Date().getTime() + insurance.public_id,
        start_date: currentFormat.start_time
      };

      dispatch(Operation.addGoods(sendObj, navigateTo));
    }

    dispatch(ActionCreator.cleanFormats([]));
    dispatch(ActionCreator.setFilteredEventsInsurance([]));
    setCity("");
    setFormat("");
  };

  useEffect(() => {
    if (city) {
      setIsLoadFormats(false);
      dispatch(DataOperation.loadFormats(city.value, false, "loadFormats", setIsLoadFormats));
    }
    return () => dispatch(ActionCreator.loadFormats([]));
  }, [city]);

  useEffect(() => {
    if (format && format.value) {
      setIsloading(true);
      dispatch(Operation.loadActiveInsurancesList(format.value, setIsEvents, setIsloading));
    }
    return () => dispatch(BasketActionCreator.setActiveInsurancesList([]));
  }, [format]);

  useEffect(() => {
    dispatch(ActionCreator.cleanFormats([]));
    if (event) {
      dispatch(DataOperation.loadFilteredEventsInsurance({
        city: { id: 0 },
        date_from: allYearEvents.date_from,
        date_to: allYearEvents.date_to,
        event_type: { public_id: event.public_id },
      }, setIsEvents),);
      setCity("");
      setFormat("");
    }
    return () => dispatch(ActionCreator.setFilteredEventsInsurance([]));
  }, [event]);

  useEffect(() => {
    if (eventTypes?.length === 0) dispatch(DataOperation.loadEventType());
  }, [dispatch, eventTypes?.length]);

  useEffect(() => {
    if (isLoadFormats && !insuranceFormats.length) {
      setIsEvents(true);
    }
  }, [insuranceFormats, isLoadFormats]);

  return (
    <Popup outsideStyles={styles}>
      <div className={styles.wrapper}>
        <h4 className={styles.title}>Выбор события страхования</h4>
        <div className={styles.selectsWrapper}>

          <div className={styles.filters}>

            <div>
              <p className={styles.description}>Выберите событие</p>
              <CustomSelect
                data-filter={"activity"}
                handleSelectChange={(evt) =>
                  handleActivityFilter(evt, "activity")
                }
                value={activity}
                customArrow={CustomArrow}
                prefix={"select-insurance-activity"}
                name={"activity"}
                options={setOptions(eventTypes)}
                placeholder={"Выберите событие"}
                styles={styles}
                closeMenuOnScroll={() => false}
              />
            </div>
            <div style={{ pointerEvents: !filteredEvents.length ? "none" : "auto" }} className={styles.city}>
              <p className={styles.description}>Выберите город</p>
              <CustomSelect
                data-filter={"city"}
                handleSelectChange={(evt) => handleActivityFilter(evt, "city")}
                value={city}
                customArrow={CustomArrow}
                prefix={"select-insurance-city"}
                name={"city"}
                options={setInsuranceOptions(filteredEvents)}
                placeholder={"Выберите город"}
                styles={styles}
                closeMenuOnScroll={() => false}
              />
            </div>

            {insuranceFormats?.length && city && filteredEvents.length ? <div className={styles.city}>
              <p className={styles.description}>Выберите формат</p>
              <CustomSelect
                data-filter={"format"}
                handleSelectChange={(evt) => handleActivityFilter(evt, "format")}
                value={format}
                customArrow={CustomArrow}
                prefix={"select-insurance-format"}
                name={"format"}
                options={setEventsOptions(insuranceFormats)}
                placeholder={"Выберите формат"}
                styles={styles}
                closeMenuOnScroll={() => false}
              />

              {format && insurance?.name ? <div className={styles.insuranceCard}>
                <p className={styles.name}>{insurance.name}</p>
                <p className={styles.insuranceTitle}>{insurance.title}</p>
                <p className={styles.subtitle}>{insurance.subtitle}</p>
                <p className={styles.subtitle}>{insurance.description}</p>
                {insurance.footnote ? <p className={styles.footnote}>{insurance.footnote}</p> : null}
              </div> : null}


              {insurances.length ? <> <div className={styles.firstCheckbox}>
                <input
                  className={"visually-hidden"}
                  id="insurance-legals"
                  type="checkbox"
                  onChange={handleCheckChange}
                  value={isChecked}
                />
                <label
                  className={styles.checkboxLabel}
                  htmlFor="insurance-legals"
                >
                  Соглашаюсь с {" "}
                  <a className={styles.legals} href={insurance.legals}>Условиями страхования</a>
                </label>
              </div>
                <div className={styles.btn}>
                  <button disabled={!format.value || !isChecked || !insurance?.name} onClick={submit} style={{ opacity: !format.value || !isChecked || !insurance?.name ? 0.5 : 1, pointerEvents: !format.value || !isChecked || !insurance?.name ? "none" : "auto" }} className={styles.btnInsurance}>Купить</button>
                </div> </> : null}
            </div>

              : null}

            {isEvents ? <h4 className={styles.text}>Для выбранного события страховка недоступна</h4> : isLoading ? <Loader customStyles /> : null}


          </div>
          <button
            onClick={statePopupHandler}
            data-state="close"
            className={styles.popupCloseBtn}
            type="button"
            aria-label="Закрыть"
          >
            <span className="visually-hidden">Закрыть</span>
          </button>
        </div>
      </div>
    </Popup>
  );
};
