
const initialState = {
  location: ``,
};

export const ActionType = {
  SET_LOCATION: `SET_LOCATION`,
};

export const ActionCreator = {
  setLocation: (loc) => ({
    type: ActionType.SET_LOCATION,
    payload: loc
  })
};

export const Operation = {
  getLocation: () => async (dispatch, _, api) => {
    const response = await api.get(`api/location/detailed`);

    if (response.status === 200) {
      dispatch(ActionCreator.setLocation(response.data.info));
    }
  },
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.SET_LOCATION:
      return {...state, location: action.payload };
    default:
      return state;
  }
};
