import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { ActionCreator } from 'reducer/validation/validation';

export const useValidationCode = (initState) => {
  const isValidCode = useRef(false);
  const code = useRef(initState);
  const dispatch = useDispatch();

  const handleCodeChange = (evt) => {
    const value = evt.target.value;
    code.current = value;

    if (Number(value) && value.length === 4) {
      isValidCode.current = true;
      dispatch(ActionCreator.setCodeError(``));
    } else if (value.length === 0) {
      isValidCode.current = false;
      dispatch(ActionCreator.setCodeError(``));
    } else {
      isValidCode.current = false;
      dispatch(ActionCreator.setCodeError(`Код должен состоять из 4 цифр`));
    }
  }

  const handleCodeFocus = (evt) => {
    evt.target.classList.remove('error-reg');
    dispatch(ActionCreator.setCodeError(``));
  }

  return [code, isValidCode, handleCodeChange, handleCodeFocus];
}
