import Container from "components/App/Container/Container";
import { Operation, ActionCreator } from "reducer/data/data";
import { Operation as BasketOperation } from "reducer/basket/basket";
import { useDispatch, useSelector } from "react-redux";
import { getActiveUserInsurances, getPastUserInsurances } from "reducer/data/selectors";
import { useEffect, useState } from "react";
import { Insurance } from "./Insurance";

import styles from "./MyInsurance.module.scss";
import { getInsurances } from "reducer/basket/selectors";
import { InsuranceCard } from "./InsuranceCard";
import { InsurancePopup } from "components/Popups/InsurancePopup/InsurancePopup";

export const MyInsurances = () => {

    const [isPastInsuranceTitleClick, setIsPastInsuranceTitleClick] = useState(false);
    const [selectedInsurance, setSelectedInsurance] = useState(null);

    const insurancesActive = useSelector((state) => getActiveUserInsurances(state)) ?? [];
    const insurancesPast = useSelector((state) => getPastUserInsurances(state)) ?? [];
    const insurances = useSelector((state) => getInsurances(state)) ?? [];

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(Operation.loadActiveUserInsurances());
        dispatch(BasketOperation.loadInsurances());

        return () => ActionCreator.setActiveUserIncurances([]);
    }, []);

    const handlePastClick = () => {
        setIsPastInsuranceTitleClick(!isPastInsuranceTitleClick);
        dispatch(Operation.loadPastUserInsurances());
    };

    return <Container>

        <h2 className={styles.mainTitle}>Страховки</h2>

        <h3 className={styles.label}>Активные страховки</h3>
        <ul className={styles.list}>
            {insurancesActive.length ? insurancesActive.map((el, i) => <Insurance key={i} insurance={el}></Insurance>) : <h3>Нет данных</h3>}
        </ul>
        <div className={styles.pastInsurances}>
            <h3 onClick={handlePastClick} className={`${styles.label} ${!isPastInsuranceTitleClick && styles.underlineTitle}`}>Предыдущие страховки</h3>
            {isPastInsuranceTitleClick && <ul className={styles.list}>{insurancesPast.length ? insurancesPast.map((el, i) => <Insurance key={i} insurance={el}></Insurance>) : <h3>Предыдущие страховки не найдены!</h3>}</ul>}
        </div>

        {/* {insurances.length ? <div className={styles.insurancesWrap}>
            <h2 className={styles.mainTitle}>оформить страхование</h2>
            <ul className={styles.insurancesList}>
                {insurances.map((item, i) => <InsuranceCard setSelectedInsurance={(insurance) => setSelectedInsurance(insurance)} item={item} key={i} />)}
            </ul>
        </div> : null} */}

        {selectedInsurance && <InsurancePopup insurance={selectedInsurance} statePopupHandler={() => setSelectedInsurance(null)} />}
    </Container>;
};