import { useEffect, useState } from "react";

export const useValidationText = (initState = ``) => {
  const [value, setValue] = useState(initState);
  const [isValid, setValid] = useState(false);
  const [error, setError] = useState(``);

  useEffect(() => {
    if (value.length === 0) setValid(false);
    else if (value.length > 0) setValid(true);
  }, []);

  const handleChange = (evt) => {
    const { value } = evt.target;
    setValue(value);

    if (value.length === 0) setValid(false);
    else if (value.length > 0) setValid(true);
  };

  const handleFocus = () => setError(``);

  return [
    value,
    isValid,
    error,
    setError,
    handleChange,
    handleFocus,
    setValue,
    setValid,
  ];
};
