import Input from "components/Forms/Input/Input";
import InputPhone from "components/Forms/Input/InputPhone";
import Datepicker from "components/Forms/Datepicker/Datepicker";
import Radio from "components/Forms/Radio/Radio";
import Message from "components/Auth/Registration/Message/Message";
import styles from "../Goods.module.scss";
import moment from "moment-timezone";
import { Operation as BasketOperation } from "reducer/basket/basket";
import { getDate, useValidationDate } from "customHooks/useValidationDate";
import { useEffect, useRef, useState } from "react";
import { ReactComponent as DateLogo } from "images/profile/profile-calendar.svg";
import { ReactComponent as ReactLogoName } from "images/profile/profile-name.svg";
import { updateLocalStorage } from "utils/utils";
import { useValidationPhone } from "customHooks/useValidationPhone";
import { useDispatch } from "react-redux";
import { ActionCreator } from "reducer/basket/basket";

const FIXED_MIN_DATE = new Date("2024-12-01T00:00:00Z");

const addFiveMinutes = (startDate) => {
    const date = new Date(startDate);
    date.setMinutes(date.getMinutes() + 5);
    return date;
};

const roundToDay = (date) => {
    const inputDate = new Date(date);
    const now = new Date();

    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();

    inputDate.setHours(hours, minutes, seconds, 0);

    return inputDate;
};

function calculateStartDate(activationDelay, ticketStartTime = null) {
    if (!activationDelay) {
        return "";
    }

    const now = Date.now();
    const minStartDate = new Date(now + activationDelay * 1000);

    const effectiveMinStartDate = minStartDate > FIXED_MIN_DATE ? minStartDate : FIXED_MIN_DATE;

    let startDate;

    if (ticketStartTime) {
        const ticketStartDate = new Date(ticketStartTime * 1000);
        startDate = ticketStartDate;

        if (startDate < effectiveMinStartDate) {
            startDate = effectiveMinStartDate;
        }
    } else {
        startDate = effectiveMinStartDate;
    }

    return startDate;
}

export const InsuranceForm = ({ insurance }) => {

    const dispatch = useDispatch();
    const formRef = useRef(null);

    const userObj = localStorage.getItem("userObj") ? JSON.parse(localStorage.getItem("userObj")) : {};
    const [valueDate, handleDateChange, date, isValidDate] =
        useValidationDate(insurance.info?.birth_date || userObj.birth_date && getDate(userObj.birth_date * 1000) || "");

    const [valueStartDate, handleStartDateChange, startDate, isValidStartDate, setValue, setValidDate] = useValidationDate(calculateStartDate(insurance.activation_delay, insurance.format_start_time));
    const [phone, isValidTel, handlePhoneChange,] = useValidationPhone(insurance.info?.phone && `+${insurance.info.phone}` || userObj.phone && `+${userObj.phone}`);

    const [isErrors, setErrors] = useState({
        date: "",
        start_date: ""
    });
    const [data, setData] = useState({
        first_name: insurance?.first_name || userObj.name,
        last_name: insurance?.last_name || userObj.last_name,
        second_name: insurance?.second_name || userObj.second_name,
        gender: insurance?.gender || userObj.gender,
    });

    const handleRadioChange = (e) => {
        setErrors({ ...isErrors, gender: false });
        setData({ ...data, gender: e.target.value });
    };

    const handleValidationUpdate = () => {
        let newObj = {
            ...insurance,
            preReg: false,
        };
        updateLocalStorage('basketGoods', 'add', newObj);
        dispatch(ActionCreator.updateGoods(newObj));
    };

    useEffect(() => {
        const { first_name, last_name, gender, second_name } = data;
        /*  if (addFiveMinutes(roundToDay(startDate)) < new Date(FIXED_MIN_DATE)) {
             setErrors((prev) => ({ ...prev, start_date: "Начало действия страховки не может быть меньше 1 декабря 2024" }));
             handleValidationUpdate();
             return;
         }
         else */ /* if (addFiveMinutes(roundToDay(startDate)) < new Date(Date.now() + insurance.activation_delay * 1000)) {
setErrors((prev) => ({ ...prev, start_date: `Начало действия страховки не может быть меньше ${new Date(Date.now() + insurance.activation_delay * 1000)}` }));
handleValidationUpdate();
return;
} else if (!isValidStartDate) {
setErrors((prev) => ({ ...prev, start_date: "Введите корректную дату!" }));
} else {
setErrors((prev) => ({ ...prev, start_date: "" }));
} */

        if (!first_name) {
            setErrors((prev) => ({ ...prev, first_name: "Заполните поле!" }));
        } else {
            setErrors((prev) => ({ ...prev, first_name: "" }));
        }
        if (!last_name) {
            setErrors((prev) => ({ ...prev, last_name: "Заполните поле!" }));
        } else {
            setErrors((prev) => ({ ...prev, last_name: "" }));
        }
        if (!isValidTel) {
            setErrors((prev) => ({ ...prev, phone: "Введите корректный номер телефона!" }));
        } else {
            setErrors((prev) => ({ ...prev, phone: "" }));
        }
        if (!isValidDate) {
            setErrors((prev) => ({ ...prev, birth_date: "Введите корректную дату!" }));
        } else {
            setErrors((prev) => ({ ...prev, birth_date: "" }));
        }
        if (!gender) {
            setErrors((prev) => ({ ...prev, gender: "Выберите одно из значений!", }));
        }


        const birthDate = new Date(date);
        const startDate = new Date(insurance.start_date * 1000);

        let age = startDate.getFullYear() - birthDate.getFullYear();

        const isBeforeBirthday =
            startDate.getMonth() < birthDate.getMonth() ||
            (startDate.getMonth() === birthDate.getMonth() && startDate.getDate() < birthDate.getDate());

        if (isBeforeBirthday) {
            age--; // Если день рождения не достигнут в этом году, уменьшаем возраст на 1
        }
        if (age < 5) {
            setErrors((prev) => ({ ...prev, birth_date: "Допускается возраст от 5 до 86 лет" }));
            handleValidationUpdate();
            return;
        } else if (age > 86) {
            setErrors((prev) => ({ ...prev, birth_date: "Допускается возраст от 5 до 86 лет" }));
            handleValidationUpdate();
            return;
        } else {
            setErrors((prev) => ({ ...prev, birth_date: "" }));
        }

        if (first_name && last_name && isValidTel && isValidDate && gender /* && isValidStartDate */) {

            let newObj = {
                ...insurance,
                last_name,
                first_name,
                phone: phone?.slice(1),
                count: insurance.count,
                /*        start_date: addFiveMinutes(roundToDay(startDate)).getTime() / 1000, */
                /* default_birthday: date, */
                gender,
                insurance: {
                    public_id: insurance.public_id,
                },
                birth_date: moment.utc(date).toISOString(),
                preReg: "ok"
            };

            if (second_name) {
                newObj = {
                    ...newObj,
                    second_name
                };
            }

            updateLocalStorage(`basketGoods`, `add`, newObj);
            dispatch(ActionCreator.updateGoods(newObj));
        }

    }, [data, phone, date/* , startDate */]);

    useEffect(() => {
        if (formRef.current && insurance.isScrolling) {
            formRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }

        let newObj = {
            ...insurance,
            isScrolling: false
        };

        updateLocalStorage(`basketGoods`, `add`, newObj);
        dispatch(ActionCreator.updateGoods(newObj));

        const goods = localStorage.getItem("basketGoods") && JSON.parse(localStorage.getItem("basketGoods"));
        dispatch(BasketOperation.updateUserBasketGoods(goods, () => { }));
    }, [formRef]);

    return (
        <form ref={formRef} style={{ marginTop: "1rem", marginBottom: "1rem" }}>
            <div className={styles.formWrap}>
                {/*   <img style={{ marginTop: "20px", marginBottom: "20px" }} src={`${REACT_APP_API}${insurance.image}`} alt="фото пример лицензии" /> */}
                <h2>Регистрация страховки</h2>
                <p style={{ marginTop: "1rem" }}>Особенно внимательно заполняйте эти поля. Следите за тем, чтобы были указаны действительные данные участника.</p>
                {/*   <p style={{ marginTop: "1rem" }}>Эти данные будут сопоставлены с уже имеющимися у нас и если в нашей базе будет обнаружен указанный Вами e-mail, телефон или ФИО, то часть данных мы возьмем из имеющейся базы.</p>
 */}         <p style={{ marginTop: "0.3rem" }}>Продолжая оплату соглашаюсь с <a target={"_blank"} rel="noreferrer" href={insurance.legals} className={styles.linkInsurance}>условиями страхования</a>.</p>
                <div className={styles.wrapperForm} style={{ marginTop: "1.5rem" }}>
                    <Input
                        firstLetterUpperCase
                        error={isErrors.first_name}
                        handleFieldChange={(e) => setData({ ...data, first_name: e.target.value })}
                        handleFocus={() => setErrors({ ...isErrors, first_name: "" })}
                        Logo={ReactLogoName}
                        label={"Ваше имя*"}
                        name={"first_name"}
                        value={data?.first_name}
                    />
                    <Input
                        firstLetterUpperCase
                        error={isErrors.second_name}
                        handleFieldChange={(e) => setData({ ...data, second_name: e.target.value })}
                        handleFocus={() => setErrors({ ...isErrors, second_name: "" })}
                        Logo={ReactLogoName}
                        label={"Ваше отчество (если есть)"}
                        name={"second_name"}
                        value={data?.second_name}
                    />
                    <Input
                        firstLetterUpperCase
                        error={isErrors.last_name}
                        handleFieldChange={(e) => setData({ ...data, last_name: e.target.value })}
                        handleFocus={() => setErrors({ ...isErrors, last_name: "" })}
                        Logo={ReactLogoName}
                        label={"Ваша Фамилия*"}
                        name={"last_name"}
                        value={data?.last_name}
                    />

                    <Datepicker
                        error={isErrors.birth_date}
                        outsideStyle={styles}
                        value={valueDate}
                        handleDateChange={handleDateChange}
                        handleDateFocus={() => setErrors({ ...isErrors, birth_date: "" })}
                        Logo={DateLogo}
                        label={"Дата рождения*"}
                        id={"birth_date"}
                    />
                    <InputPhone
                        country="RU"
                        international={true}
                        withCountryCallingCode={true}
                        error={isErrors.phone}
                        label={"Ваш телефон*"}
                        type="tel"
                        name="phone"
                        value={phone}
                        onFocus={() => setErrors({ ...isErrors, phone: "" })}
                        onChange={handlePhoneChange}
                    />


                    <div className={styles.radioWrapper}>
                        <p className={styles.genderTitle}>
                            Ваш пол:
                        </p>
                        <ul className={styles.genderList}>
                            <li className={styles.genderItem}>
                                <Radio
                                    error={isErrors.gender}
                                    initialValue={'male'}
                                    value={data?.gender}
                                    name={'gender-radio'}
                                    id={new Date() + Math.random()}
                                    label={'Мужской'}
                                    handleRadioChange={handleRadioChange}
                                />
                            </li>
                            <li className={styles.genderItem}>
                                <Radio
                                    error={isErrors.gender}
                                    initialValue={'female'}
                                    value={data?.gender}
                                    name={'gender-radio'}
                                    id={new Date() - 1000 - Math.random()}
                                    label={'Женский'}
                                    handleRadioChange={handleRadioChange}
                                />
                            </li>
                        </ul>



                        {isErrors.gender?.length ? (
                            <Message message={isErrors.gender} styles={styles} />
                        ) : null}
                    </div>
                    {/* <Datepicker
                        error={isErrors.start_date}
                        outsideStyle={styles}
                        value={valueStartDate}
                        handleDateChange={handleStartDateChange}
                        handleDateFocus={() => setErrors({ ...isErrors, start_date: "" })}
                        Logo={DateLogo}
                        label={"Начало действия страховки*"}
                        id={"start_date"}
                    /> */}
                </div>
            </div>
        </form>
    );
};