import NameSpace from '../name-space';

const NAME_SPACE = NameSpace.VALIDATION;

export const getLoginError = (state) => state[NAME_SPACE].loginError;
export const getPasswordError = (state) => state[NAME_SPACE].passwordError;
export const getNewPasswordError = (state) => state[NAME_SPACE].newPasswordError;
export const getRecLoginError = (state) => state[NAME_SPACE].recLoginError;
export const getTelError = (state) => state[NAME_SPACE].telError;
export const getCodeError = (state) => state[NAME_SPACE].codeError;
export const getExtaContactTelError = (state) => state[NAME_SPACE].extraContactTelError;
