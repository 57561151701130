export const TOUCH_EVENTS_TOUCH = {
  start: 'touchstart',
  move: 'touchmove',
  end: 'touchend',
  cancel: 'touchcancel'
};
export const TOUCH_EVENTS_DESKTOP = {
  start: 'pointerdown',
  move: 'pointermove',
  end: 'pointerup'
};
export const DEFAULT_PARAMS = {
  init: true,
  direction: 'horizontal',
  isNavigation: false,
  isPagination: false,
  isThumbs: false,
  free: false,
  touchEventsTarget: 'wrapper',
  initialSlide: 0,
  speed: 1200,
  updateOnWindowResize: true,
  resizeObserver: true,
  nested: false,
  createElements: false,
  enabled: true,
  focusableElements: 'input, select, option, textarea, button, video, label',
  newWidth: null,
  newHeight: null,
  preventInteractionOnTransition: false,
  userAgent: null,
  url: null,
  edgeSwipeDetection: false,
  edgeSwipeThreshold: 20,
  autoHeight: false,
  setWrapperSize: false,
  virtualTranslate: false,
  effect: 'slide',
  breakpoints: undefined,
  breakpointsBase: 'window',
  spaceBetween: 24,
  slidesPerView: "auto",
  slidesPerGroup: 1,
  slidesPerGroupSkip: 0,
  slidesPerGroupAuto: false,
  centeredSlides: false,
  centeredSlidesBounds: false,
  slidesOffsetBefore: 0,
  slidesOffsetAfter: 0,
  normalizeSlideIndex: true,
  centerInsufficientSlides: false,
  watchOverflow: true,
  roundLengths: false,
  touchRatio: 1,
  touchAngle: 45,
  simulateTouch: true,
  shortSwipes: true,
  longSwipes: true,
  longSwipesRatio: 0.5,
  longSwipesMs: 300,
  followFinger: true,
  allowTouchMove: true,
  threshold: 15,
  touchMoveStopPropagation: false,
  touchStartPreventDefault: true,
  touchStartForcePreventDefault: false,
  touchReleaseOnEdges: false,
  uniqueNavElements: true,
  resistance: true,
  resistanceRatio: 0.85,
  watchSlidesProgress: false,
  grabCursor: false,
  preventClicks: true,
  preventClicksPropagation: true,
  slideToClickedSlide: false,
  preloadImages: true,
  updateOnImagesReady: true,
  loop: true,
  loopAdditionalSlides: 0,
  loopedSlides: null,
  loopFillGroupWithBlank: false,
  loopPreventsSlide: true,
  rewind: false,
  allowSlidePrev: true,
  allowSlideNext: true,
  swipeHandler: null,
  noSwiping: true,
  noSwipingClass: 'carousel-no-swiping',
  noSwipingSelector: null,
  passiveListeners: true,
  maxBackfaceHiddenSlides: 10,
  containerModifierClass: 'carousel-',
  slideClass: 'carousel-slide',
  slideBlankClass: 'carousel-slide-invisible-blank',
  slideActiveClass: 'carousel-slide-active',
  slideDuplicateActiveClass: 'carousel-slide-duplicate-active',
  slideVisibleClass: 'carousel-slide-visible',
  slideDuplicateClass: 'carousel-slide-duplicate',
  slideNextClass: 'carousel-slide-next',
  slideDuplicateNextClass: 'carousel-slide-duplicate-next',
  slidePrevClass: 'carousel-slide-prev',
  slideDuplicatePrevClass: 'carousel-slide-duplicate-prev',
  wrapperClass: 'carousel-wrapper',
  runCallbacksOnInit: true,
  _emitClasses: false,
  autoplayEnabled: true,
  autoplayDelay: 4700,
  autoplayWaitForTransition: true,
  autoplayDisableOnInteraction: true,
  autoplayStopOnLastSlide: false,
  autoplayReverseDirection: false,
  autoplayPauseOnMouseEnter: false,
  autoplayRunning: false,
  autoplayPaused: false,
  autoplayTimeLeft: 0,
  navigationNextEl: null,
  navigationPrevEl: null,
  navigationHideOnClick: false,
  navigationDisabledClass: 'carousel-button-disabled',
  navigationHiddenClass: 'carousel-button-hidden',
  navigationLockClass: 'carousel-button-lock',
  navigationDisabledClass: 'carousel-navigation-disabled',
  observer: false,
  observeParents: false,
  observeSlideChildren: false
};
export const DEFAULT_FUNCTION = {
  on: () => { },
  emit: () => { },
  slideTo: () => { },
  slideNext: () => { },
  onTouchEnd: () => { },
  onTouchMove: () => { },
  onTouchStart: () => { },
  onResize: () => { },
  onClick: () => { },
  off: () => { },
  getCarouselTranslate: () => { },
  loopFix: () => { },
  updateSize: () => { },
  setTransition: () => { },
  updateProgress: () => { },
  setTranslate: () => { },
  updateSlides: () => { },
  updateSlidesClasses: () => { },
  update: () => { },
  transitionStart: () => { },
  updateActiveIndex: () => { },
  transitionEnd: () => { },
  attachEvents: () => { },
  detachEvents: () => { },
  setGrabCursor: () => { },
  preloadImages: () => { },
  addClasses: () => { },
  removeClasses: () => { },
  calcLoopedSlides: () => { },
  slidePrev: () => { }, slideToLoop: () => { },
  handlePrevClick: () => { },
  handleNextClick: () => { },
  handlePaginationClick: () => { },
  handleThumbClick: () => { }
}
