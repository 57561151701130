import { useEffect, useState } from 'react';

export const getDate = (initState) => {
  if (initState) {
    return new Date(initState).toLocaleDateString('en-GB').replaceAll('/', '.');
  }
  return ``;
};

export const useValidationDate = (initState = ``) => {
  const [value, setValue] = useState(getDate(initState));
  const [isValidDate, setValidDate] = useState(initState ? true : false);
  const [date, setDate] = useState(null);

  useEffect(() => {
    if (isValidDate) {
      const newDate = value.split(`.`).reverse().join(`-`);

      if (new Date(newDate).toLocaleDateString('en-GB') === `Invalid Date`) setValidDate(false);
      else setDate(new Date(newDate));

    } else setDate(null);
  }, [isValidDate, value]);

  const handleDateChange = (evt) => {
    const { value } = evt.target;
    setValue(value);

    if (value.length >= 10) setValidDate(true);
    else setValidDate(false);
  }

  return [value, handleDateChange, date, isValidDate, setValue, setValidDate];
};
