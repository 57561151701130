import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from 'components/App/App';
import Loader from 'components/Auth/Loader/Loader';
import configurateStore from 'store';
import i18next from 'i18next';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Operation as UserOperation } from './reducer/user/user';
import { Operation as ShopOperation } from 'reducer/shop/shop';
import { initYupMessages } from 'utils/initYupMessages';
import { YMInitializer } from 'react-yandex-metrika';
import { createAPI } from 'api';
import './i18n';
import './css/index.scss';

export const api = createAPI(() => {});

export const store = configurateStore();

store.dispatch(UserOperation.checkAuth());
store.dispatch(ShopOperation.loadItems(`basket`));

initYupMessages();

if (localStorage.lang) i18next.changeLanguage(localStorage.lang);

ReactDOM.render(
  // <React.StrictMode>
  <BrowserRouter>
    <Suspense fallback={<Loader />}>
      <Provider store={store}>
        <YMInitializer
          accounts={[90909954]}
          options={{ webvisor: false }}
        />
        <App />
      </Provider>
    </Suspense>
  </BrowserRouter>,
  // </React.StrictMode>,
  document.getElementById('root')
);
