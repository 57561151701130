import { REACT_APP_API } from "api";
import styles from "./index.module.scss";
import { Link } from 'react-router-dom';
import { useMediaQuery } from "react-responsive";

export const ClubsItem = ({ club }) => {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    let truncatedDescription = club?.description;
    const description = club?.description;
    if (!isMobile && description?.length > 130 || isMobile && description?.length > 70) {
        truncatedDescription = description.slice(0, isMobile ? 70 : 130) + "...";
    }
    return (
        <li className={styles.item}>
            <img
                className={styles.logo}
                src={`${REACT_APP_API}${club?.picture}`}
                alt={club?.description}
            />
            <b className={styles.itemTitle}>{club?.title}</b>
            <div style={{ marginBottom: "23px", overflow: "hidden" }}>
                <p className={styles.itemText}>{truncatedDescription}</p>
            </div>
            <Link className={styles.btnPart} to={`/clubs/${club?.public_name}`}>Перейти в клуб</Link>
        </li>
    );
};
