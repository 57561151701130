import styles from "./TicketErrorPopup.module.scss";
import Popup from "../Popup/Popup";

const TicketErrorPopup = ({
  statePopupHandler,
  title,
  description,
  buttonTitle = "На главную",
}) => {

  return (
    <Popup>
      <div className={styles.wrapper}>
        <h4 className={styles.title}>{title}</h4>
        <p className={styles.description}>{description}</p>

        <button className={styles.btn} onClick={statePopupHandler}>
          {buttonTitle}
        </button>

        <button
          onClick={statePopupHandler}
          data-state="close"
          className={styles.popupCloseBtn}
          type="button"
          aria-label="Закрыть"
        >
          <span className="visually-hidden">Закрыть</span>
        </button>
      </div>
    </Popup>
  );
};

export default TicketErrorPopup;
