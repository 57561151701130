import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { routes } from "const/routes";
import styles from "./Breadcrumbs.module.scss";

const BreadcrumbsItem = () => {
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();

  const arrayPathname = pathname.slice(1).split("/");
  const altKey = arrayPathname[0];

  const isManyPath = arrayPathname.length > 1;

  const getName = () => {
    for (let key in routes) {
      if (key.toLowerCase() === altKey) {
        return t(`breadcrumbs.${key}.main`);
      }
    }
  };

  const getChildName = () => {
    for (let key in routes) {
      if (key.toLowerCase() === altKey && isManyPath) {
        const result =
          i18n.exists(`breadcrumbs.${key}.child`) &&
          t(`breadcrumbs.${key}.child`);
        return result && result;
      }
    }
  };

  return (
    <>
      <li
        className={`${styles.breadcrumb}  ${` ${
          isManyPath ? "" : styles.breadcrumbActive
        }`}`}
      >
        <Link to={`/${altKey}`}>{getName()}</Link>
      </li>
      {isManyPath && getChildName() && (
        <li className={`${styles.breadcrumb} ${`${styles.breadcrumbActive}`}`}>
          <Link to={pathname || ""}>{getChildName()}</Link>
        </li>
      )}
    </>
  );
};

export default BreadcrumbsItem;
