export const resize = ({ carousel, params, carouselElRef }) => {

  let observer = null;
  let animationFrame = null;

  const resizeHandler = () => {
    if (carousel.current.destroyed || !carousel.current.initialized) return;
    carousel.current.setBreakpoints()
    carousel.current.emit('beforeResize');
    carousel.current.emit('resize');
  };
  const createObserver = () => {
    if (carousel.current.destroyed || !carousel.current.initialized) return;
    observer = new ResizeObserver(entries => {
      animationFrame = window.requestAnimationFrame(() => {
        let newWidth = carousel.current.width;
        let newHeight = carousel.current.height;
        entries.forEach(({ contentBoxSize, contentRect, target }) => {
          if (target && target !== carouselElRef.current) return;
          newWidth = contentRect ? contentRect.width : (contentBoxSize[0] || contentBoxSize).inlineSize;
          newHeight = contentRect ? contentRect.height : (contentBoxSize[0] || contentBoxSize).blockSize;
        });

        if (newWidth !== carousel.current.width || newHeight !== carousel.current.height) {
          resizeHandler();
        }
      });
    });
    observer.observe(carouselElRef.current);
  };
  const removeObserver = () => {
    if (animationFrame) {
      window.cancelAnimationFrame(animationFrame);
    }

    if (observer && observer.unobserve && carouselElRef.current) {
      observer.unobserve(carouselElRef.current);
      observer = null;
    }
  };
  const orientationChangeHandler = () => {
    carousel.current.setBreakpoints()
    if (carousel.current.destroyed || !carousel.current.initialized) return;
    carousel.current.emit('orientationchange');
  };
  carousel.current.on('init', () => {
    if (params.resizeObserver && typeof window.ResizeObserver !== 'undefined') {
      createObserver();
      return;
    }

    window.addEventListener('resize', resizeHandler);
    window.addEventListener('orientationchange', orientationChangeHandler);
  });
  carousel.current.on('destroy', () => {
    removeObserver();
    window.removeEventListener('resize', resizeHandler);
    window.removeEventListener('orientationchange', orientationChangeHandler);
  });
}
