import React from "react";
import { Carousel } from "components/Carousel";
import styles from "./ExternalCollection.module.scss";
import { REACT_APP_API } from "api";

export default function ExternalCollection({ slides = [] }) {

  if (!slides.length) return null;
  return (
    <section className={styles.section}>
      <div className={styles.title_wrap}>
      </div>
      <Carousel
        slidesPerView={4}
        slidesPerGroup={4}
        styles={styles}
        breakpoints={{
          "768": {
            slidesPerView: 2,
            slidesPerGroup: 2
          },
          "900": {
            slidesPerGroup: 4,
            slidesPerView: 4
          }
        }}
        isNavigation
      >
        {slides.length
          ? slides.map((product) => (
            <Carousel.Page
              tag="li"
              className={`${styles.slide}`}
              key={product.public_id}
            >
              <a target="_blank" href={product.link} >
                <div className={styles.imageWrap}>
                  <img
                    className={styles.slideImg}
                    src={`${REACT_APP_API}${product.picture_main}`}
                    alt={product.title}
                  />
                </div>
                <div className={styles.slideContent}>
                  <b className={styles.slideText}>{product.title}</b>
                  {product?.old_price > 0 && product?.price > 0 ? (
                    <p className={styles.priceContainer}>
                      <span
                        className={`${styles.price} ${product.old_price ? styles._sale : ``
                          }`}
                      >
                        {product.price} Руб.
                      </span>
                      <span className={styles.priceOld}>
                        {product.old_price} Руб.
                      </span>
                    </p>
                  ) : (
                    <>
                      {product?.price > 0 && <p>
                        <span className={styles.price}>
                          {product.price} Руб.
                        </span>
                      </p>}
                    </>
                  )}
                </div>
              </a>
            </Carousel.Page>
          ))
          : null}
      </Carousel>
    </section>
  );
}
