import styles from '../Personal.module.scss';
import Data from './Data/Data';
import Email from './Email/Email';
import Phone from './Phone/Phone';
import Password from './Password/Password';
import Social from './Social/Social';
import Gender from './Gender/Gender';
import Relationship from './Relationship/Relationship';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import Subscriptions from './Subscriptions/Subscriptions';

const Settings = () => {
  const { t } = useTranslation();

  // Локальный state для disabled кнопки обновления информации
  // От лишних запросов со стороны пользователя
  const [isLoading, setLoading] = useState(false);

  return (
    <>
      <h2 className={styles.title}>{t('lk.settings')}</h2>
      <span className={styles.subtitle}>{t('lk.settingPage.subtitle')}</span>
      <b className={styles.desc}>{t('lk.settingPage.correctData')}</b>

      <div className={styles.form}>

        <Subscriptions styles={styles} />

        <Data styles={styles} isLoading={isLoading} setLoading={setLoading} />

        <Email styles={styles} />

        <Phone styles={styles} />

        <Password styles={styles} />

        {/*    <Social styles={styles} /> */}

        <Gender styles={styles} />

        <Relationship styles={styles} />

      </div>
    </>
  );
};

export default Settings;
