import styles from './Footer.module.scss';
import vkLogo from 'images/svg/VK-Logo-White.svg';
import tgLogo from 'images/svg/tg-logo.svg';
import logo from 'images/svg/logo-league.svg';
import { Link } from 'react-router-dom';
import Container from 'components/App/Container/Container';
import { routes } from 'const/routes';
import { useSelector } from 'react-redux';
import { getCurrentAuthStatus } from 'reducer/user/selectors';
import { AuthorizationStatus } from 'const/const';
import { footerList } from 'mocks/links';
import { useTranslation } from 'react-i18next';


const renderItem = (item, t) => {
  switch (item.render) {
    case `externalLink`:
      return <a href={item.route}
        target="_blank"
        rel="noopener noreferrer"
      >{t(`footer.${item.t}`)}</a>;
    case `socialList`:
      return <ul className={styles.socials}>
        <li className={styles.social}>
          <a href="https://vk.com/heroleague" target="_blank" rel="noopener noreferrer"><img src={vkLogo} alt="vk" /></a>
        </li>
        <li className={styles.social}>
          <a href="https://t.me/heroleague_official" target="_blank" rel="noopener noreferrer"><img src={tgLogo} alt="tg" /></a>
        </li>
        {/*        <li className={styles.social}>
          <a href="https://www.facebook.com/heroleaguerussia" target="_blank" rel="noopener noreferrer"><img src={fbLogo} alt="fb"/></a>
        </li>
        <li className={styles.social}>
          <a href="https://www.instagram.com/heroleague/" target="_blank" rel="noopener noreferrer"><img src={instaLogo} alt="insta"/></a>
        </li>*/}
      </ul>;
    default:
      return <Link to={routes[item.route]['path']}>{t(`footer.${item.t}`)}</Link>;
  }
};

const Footer = ({ shop }) => {
  const currentAuthStatus = useSelector((state) => getCurrentAuthStatus(state));
  const { t } = useTranslation();

  return (
    <Container shop={shop}>
      <footer className={styles.footer}>
        <div className={styles.header}>
          <span className={styles.title}>{t('footer.menu')}</span>

          {currentAuthStatus === AuthorizationStatus.AUTH ? null :
            <Link className={styles.cabinet_link} to={routes.myEvents.path}>
              {t('footer.lk')}
            </Link>
          }

        </div>
        <div className={styles.wrapper}>
          <ul className={styles.links_list}>
            {footerList.map(item => <li key={item.t}
              className={styles.links_item}>{renderItem(item, t)}</li>)}
          </ul>
          <div className={styles.copyright_block}>
            <img src={logo} alt="Лига Героев" />
            <p className={styles.copyright}>
              {/* Copyright © АНО “Гонка Героев” 2015 - {times.getYear(1, Date.now())}<br /> */}
              АНО "Гонка Героев" СМИ 18+<br />
              ООО “Лига Героев Спорт Проджектс”<br />
              ООО “Лига путешествий”<br />
            </p>
          </div>
        </div>
      </footer>
    </Container>
  );
};

export default Footer;
