import Popup from '../Popup/Popup';


const UniversalPopup = ({outsideStyles, children}) => {
  return (
    <Popup outsideStyles={outsideStyles}>
      {children}
    </Popup>
  );
}

export default UniversalPopup;
